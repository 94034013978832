import React, { useContext, FC } from "react";
import { Link } from "react-router-dom";
import "../../../../styles/system.admin.scss";
import { SidebarContext } from "../../../auth/ProtectedRoute";
import { FeedsDataType } from "../types/DataTypes";
const ViewFeedBtn: FC<{ record: FeedsDataType }> = ({ record }) => {
  const { userName, sidebarData } = useContext(SidebarContext);
  return (
    <div style={{ width: "100%" }}>
      <Link
        to={`view-feed/${record.id}`}
        state={{ userName: userName, sidebar: sidebarData }}
      >
        <button className="__btn">View Feed</button>
      </Link>
    </div>
  );
};
export default ViewFeedBtn;
