import React, { Fragment, useContext, FC } from "react";
import { Link } from "react-router-dom";
import user from "../../../assets/user-img.png";
import { SidebarContext } from "../../auth/ProtectedRoute";
import GroupsTable from "./tables/GroupsTable";
// import AdminRoleTable from "./tables/AdminRoleTable";

const Groups: FC = () => {
  const { userName, sidebarData } = useContext(SidebarContext);
  return (
    <Fragment>
      <div className="roles">
        <div className="roles-wrapper">
          <div className="roles--heading">
            {/* <div className="roles--heading-img">
              <img src={user} alt="profile" />
            </div> */}
          </div>
          <div className="roles--tables">
            <div className="roles--tables-heading">
              <div className="roles--tables-heading-title">
                <h2>Groups</h2>
              </div>
              <div className="roles--tables-heading-box">
                <div className="formy">
                  <form>
                    <div className="formy--box">
                      <input type="text" placeholder="Search.." name="search" />
                      <div className="formy--box-icon">
                        <i className="fa fa-search"></i>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="clicks">
                  <div className="clicks--right">
                    <Link
                      to="add-group"
                      state={{ userName: userName, sidebar: sidebarData }}
                    >
                      <button>Create Group</button>
                    </Link>
                  </div>
                  <div className="clicks--left">
                    {/* <button>
                      <span>
                        <i className="fa fa-trash" aria-hidden="true"></i>
                      </span>
                      Delete
                    </button> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="roles--tables--content">
              <GroupsTable />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default Groups;
