import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../../../styles/group.scss";
import axios from "axios";
import { isAuth } from "../../../pages/auth/ProtectedRoute";
import swal from "sweetalert";
import { ViewGroupValue } from "./types/DataTypes";

const ViewGroup = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [groupData, setGroupData] = useState<ViewGroupValue>({
    name: "",
    privacy: "",
    admin: "",
    category: "",
    image: undefined,
    cover_image: undefined,
    type: "",
    description: "",
  });
  const [groupImage, setGroupImage] = useState("");
  const [groupCoverImage, setGroupCoverImage] = useState("");

  //api request to get group data
  useEffect(() => {
    axios({
      method: "GET",
      url: process.env.REACT_APP_HOST_API + `manage-groups/group/${params.id}`,
      headers: {
        Authorization: `Bearer ${isAuth()}`,
      },
    })
      .then((res) => {
        setGroupData(res.data.data);
        setGroupImage(res.data.data.image);
        setGroupCoverImage(res.data.data.cover_image);
      })
      .catch((err) => {
        console.error(err);
        swal({
          title: "Error!",
          text: "Something went wrong. Please try again later!",
          icon: "error",
        });
      });
  }, []);

  return (
    <Fragment>
      <div className="group-view">
        <div className="group-view-wrapper">
          <div className="group-view--heading">
            <div className="group-view--heading--title">
              <h1>Group Preview</h1>
            </div>
            <div className="group-view--heading-btn">
              <button onClick={() => navigate(-1)}>
                <span>
                  <svg
                    width="22"
                    height="20"
                    viewBox="0 0 22 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7 2L2 7L7 12"
                      stroke="white"
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M2 7H10C15.523 7 20 11.477 20 17V18"
                      stroke="white"
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
                Back
              </button>
            </div>
          </div>
        </div>
        <div className="group-view--body">
          <div className="group-view--body--header">
            <div className="cover">
              <img
                src={
                  `${process.env.REACT_APP_IMAGE_API_PREFIX}` +
                  `${groupCoverImage}`
                }
                alt="group-cover-image"
              />
            </div>
            <div className="avatar">
              <img
                src={
                  `${process.env.REACT_APP_IMAGE_API_PREFIX}` + `${groupImage}`
                }
                alt="group-avatar"
              />
            </div>
            <div className="profile">
              <h3>{groupData.name}</h3>
              <p>
                <span>
                  <svg
                    width="18"
                    height="21"
                    viewBox="0 0 18 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.27853 12.5149V19.8166H3.20041e-08C-9.73374e-05 18.7725 0.221984 17.7403 0.65149 16.7887C1.081 15.8371 1.70809 14.9877 2.49112 14.2971C3.27414 13.6065 4.19517 13.0904 5.193 12.7831C6.19084 12.4758 7.24264 12.3844 8.27853 12.5149ZM7.35869 11.538C4.30943 11.538 1.83967 9.06828 1.83967 6.01902C1.83967 2.96976 4.30943 0.5 7.35869 0.5C10.4079 0.5 12.8777 2.96976 12.8777 6.01902C12.8777 9.06828 10.4079 11.538 7.35869 11.538ZM12.6873 17.8978L15.9389 14.6462L17.2405 15.9468L12.6873 20.5L9.43476 17.2475L10.7363 15.9468L12.6864 17.8978H12.6873Z"
                      fill="#4895EF"
                    />
                  </svg>
                </span>
                234 members
              </p>
            </div>
          </div>
          <div className="group-view--body--tag">
            <div className="admin">
              <h3>Admins</h3>
            </div>
            <div className="adminName">
              <div className="adminTag">
                <p>{groupData.admin}</p>
              </div>
            </div>
          </div>
          <div className="group-view--body--about">
            <div className="about-header">
              <h3>About</h3>
            </div>
            <div className="about-body">
              <p>{groupData.description}</p>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ViewGroup;
