import React, { useState, Key, FC, useEffect } from "react";
import "antd/dist/antd.css";
import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import type { TableRowSelection } from "antd/es/table/interface";
import { MoreOutlined } from "@ant-design/icons";
import { Dropdown, Menu, Space } from "antd";
import { CategoryDataType } from "../types/DataType";
import axios from "axios";
import { isAuth } from "../../../auth/ProtectedRoute";
import SkeletonTable, {
  SkeletonTableColumnsType,
} from "../../../../components/SkeletonTable";
import "../../../../styles/modules.scss";
import swal from "sweetalert";
import ViewCategoryModal from "../components/ViewCategoryModal";
import EditCategoryModal from "../components/EditCategoryModal";
import DeleteCategoryModal from "../components/DeleteCategory";

const CategoryTable: FC = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [categoryTableData, setCategoryTableData] = useState<
    CategoryDataType[]
  >([]);
  const [showTotal, setShowTotal] = useState<number>();
  const [record, setRecord] = useState<{
    // eslint-disable-next-line
    id: number | null;
    name: string;
  }>({
    id: null,
    name: "",
  });

  const menu = (
    <Menu
      items={[
        {
          label: <ViewCategoryModal record={record} />,
          key: "1",
          icon: <i className="fa fa-eye" aria-hidden="true"></i>,
        },
        {
          label: <EditCategoryModal record={record} />,
          key: "2",
          icon: <i className="fa fa-pencil" aria-hidden="true"></i>,
        },
        {
          label: <DeleteCategoryModal record={record} />,
          key: "3",
          icon: <i className="fa fa-trash" aria-hidden="true"></i>,
        },
      ]}
    />
  );

  const columns: ColumnsType<CategoryDataType> = [
    {
      title: "Id",
      dataIndex: "id",
    },
    {
      title: "Category",
      dataIndex: "name",
      render: (text: string) => (
        <div className="status">
          <p>{text}</p>
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      render: () => (
        <div>
          <Space wrap>
            <Dropdown.Button
              overlay={menu}
              placement="bottom"
              icon={<MoreOutlined />}
              trigger={["click"]}
            ></Dropdown.Button>
          </Space>
        </div>
      ),
    },
  ];

  const showCategoryTable = (page: number) => {
    axios({
      method: "GET",
      url: process.env.REACT_APP_HOST_API + `category?page=${page}`,
      headers: {
        Authorization: `Bearer ${isAuth()}`,
      },
    })
      .then((res) => {
        setLoading(false);
        if (res.data.response) {
          setCategoryTableData(
            res.data.data.data.map((category: CategoryDataType) => ({
              key: category.id,
              id: category.id,
              name: category.name,
              action: "",
            }))
          );
          setShowTotal(res.data.data.total);
        } else if (res.data.response === false && res.data.status === 401) {
          swal({
            title: "Error!",
            text: res.data.message,
            icon: "error",
          });
        } else if (res.data.response === false && res.data.status === 403) {
          swal({
            title: "Error!",
            text: res.data.message,
            icon: "error",
          });
        } else if (res.data.response === false && res.data.status === 405) {
          swal({
            title: "Error!",
            text: res.data.message,
            icon: "error",
          });
        } else if (res.data.response === false && res.data.status === 406) {
          swal({
            title: "Error!",
            text: res.data.message,
            icon: "error",
          });
        } else if (res.data.response === false && res.data.status === 409) {
          swal({
            title: "Error!",
            text: res.data.message,
            icon: "error",
          });
        } else if (res.data.response === false && res.data.status === 500) {
          swal({
            title: "Error!",
            text: "Something went wrong. Please try again later!",
            icon: "error",
          });
        }
      })
      .catch((err) => {
        console.error(err);
        swal({
          title: "Error!",
          text: "Something went wrong. Please try again later!",
          icon: "error",
        });
      });
  };

  useEffect(() => {
    showCategoryTable(1);
  }, []);

  const onSelectChange = (newSelectedRowKeys: Key[]) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection: TableRowSelection<CategoryDataType> = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
      {
        key: "odd",
        text: "Select Odd Row",
        onSelect: (changableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys.filter((_, index) => {
            if (index % 2 !== 0) {
              return false;
            }
            return true;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
      {
        key: "even",
        text: "Select Even Row",
        onSelect: (changableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys.filter((_, index) => {
            if (index % 2 !== 0) {
              return true;
            }
            return false;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
    ],
  };

  return (
    <SkeletonTable
      loading={loading}
      columns={columns as SkeletonTableColumnsType[]}
    >
      <Table
        rowSelection={rowSelection}
        columns={columns}
        dataSource={categoryTableData}
        pagination={{
          showSizeChanger: false,
          pageSize: 20,
          total: showTotal,
          onChange: (page) => {
            showCategoryTable(page);
          },
          showTotal: (showTotal, range) =>
            `${range[0]}-${range[1]} of ${showTotal} items`,
        }}
        onRow={(record) => {
          return {
            onClick: () => {
              setRecord(record);
            },
          };
        }}
      />
    </SkeletonTable>
  );
};

export default CategoryTable;
