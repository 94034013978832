import React, { Fragment, FC } from "react";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../../assets/logo.png";
import "../../styles/reg.scss";
import { ForgotPasswordValues } from "./types/AuthValues";
import { Formik, Form, Field, FormikHelpers, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import swal from "sweetalert";

const ForgetPassword: FC = () => {
  const navigate = useNavigate();
  const initialValues: ForgotPasswordValues = {
    email: "",
  };

  return (
    <Fragment>
      <div>
        <div className="login">
          <div className="login__wrapper">
            <div className="login__wrapper-box" style={{ top: "25%" }}>
              <Formik
                initialValues={initialValues}
                validationSchema={Yup.object().shape({
                  email: Yup.string()
                    .email()
                    .required("This field is required"),
                })}
                onSubmit={(
                  values: ForgotPasswordValues,
                  { setSubmitting }: FormikHelpers<ForgotPasswordValues>
                ) => {
                  const formdetails: ForgotPasswordValues = {
                    email: values.email,
                  };
                  const encodedEmail = encodeURIComponent(values.email);
                  axios({
                    method: "POST",
                    url:
                      process.env.REACT_APP_HOST_API + `auth/password/forgot`,
                    data: formdetails,
                  })
                    .then((res) => {
                      setSubmitting(false);
                      if (res.data.response === true) {
                        swal({
                          title: "Success!",
                          text: "Email submitted successfully!",
                          icon: "success",
                        });
                        navigate(`/verification-code?email=${encodedEmail}`);
                      } else if (
                        res.data.response === false &&
                        res.data.status === 404
                      ) {
                        swal({
                          title: "Error!",
                          text: res.data.message,
                          icon: "error",
                        });
                      }
                    })
                    .catch((err) => {
                      console.error(err);
                      swal({
                        title: "Error!",
                        text: "Something went wrong. Please try again later!",
                        icon: "error",
                      });
                    });
                }}
              >
                {({ isSubmitting }) => (
                  <Form noValidate autoComplete="off">
                    <div className="login__wrapper-imgcontainer">
                      <img src={Logo} alt="logo" className="logo" />
                    </div>
                    <div className="login__wrapper--heading">
                      <h1>Forget password</h1>
                      <p>
                        Please enter your registered email to receive
                        verification code
                      </p>
                    </div>
                    <div className="login__wrapper-container">
                      <div className="top" style={{ marginTop: 0 }}>
                        <label htmlFor="email">Email</label>
                        <Field
                          id="email"
                          name="email"
                          type="text"
                          placeholder="Enter email"
                        />
                        <ErrorMessage name="email">
                          {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                        </ErrorMessage>
                      </div>
                      <div className="login__wrapper--bottom">
                        <Link to="/" className="btl">
                          Back to Login
                        </Link>
                      </div>
                      <button type="submit">
                        {isSubmitting ? "Submitting..." : "Submit"}
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
              <div className="login__wrapper--bottom">
                <div className="login__wrapper--bottom-footer">
                  <p>
                    <span>&copy;</span> Kominiti 2022 All Rights
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default ForgetPassword;
