import React, { useState, useContext, FC, useEffect, useCallback }from "react"
import { Link } from "react-router-dom"
import { Table,Input, Space, Button } from "antd"
import type { ColumnsType,TableProps } from "antd/es/table"
import { SearchOutlined } from "@ant-design/icons"
import { AllUserDataType } from "../types/DataType"
import { SidebarContext, isAuth } from "../../../auth/ProtectedRoute"
import axios from "axios"
import moment from "moment"
import SkeletonTable, { SkeletonTableColumnsType } from "../../../../components/SkeletonTable"
import { FilterDropdownProps } from "antd/es/table/interface"
const CompleteRegTable = () => {
 const { userName, sidebarData } = useContext(SidebarContext)
 const [loading, setLoading] = useState<boolean>(false)
 const [userTableData, setUserTableData] = useState<AllUserDataType[]>([])
 const [pagination, setPagination] = useState({
   current: 1,
   pageSize: 10,
   total: 0,
 })
 const [filters, setFilters] = useState<{ name?: string; email?: string }>({})
 const [sortField, setSortField] = useState<string | null>(null)
 const [sortOrder, setSortOrder] = useState<"ascend" | "descend" | null>(null)

  //api request for table data
 
  const fetchData = useCallback(async (params: any = {}) => {
    setLoading(true)
    try {
      const { current, pageSize, filters, sorter } = params
      let url = `${process.env.REACT_APP_HOST_API}manage-user/list?type=completed_registrations`
      url += `&page=${current}&per_page=${pageSize}`

      if (filters?.name) {
        url += `&fullname=${encodeURIComponent(filters.name[0])}`
      }
      if (filters?.email) {
        url += `&email=${encodeURIComponent(filters.email[0])}`
      }
      if (sorter?.field) {
        url += `&sort_by=${sorter.field}&sort_order=${sorter.order === "ascend" ? "asc" : "desc"}`
      }

      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${isAuth()}`,
        },
      })

      const { posts, pagination: paginationData } = response.data.data

      setUserTableData(
        posts.map((userData: any) => ({
          key: userData.id,
          project_id: userData.id,
          photo: process.env.REACT_APP_IMAGE_API_PREFIX + userData.photo,
          name: userData.name,
          email: userData.email,
          location: userData.location,
          date_registered: userData.date_registered,
          last_login_date: userData.last_login_date,
        }))
      )

      setPagination({
        ...params.pagination,
        total: paginationData.total,
      })
    } catch (error) {
      console.error("Error fetching data:", error)
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    fetchData({ current: 1, pageSize: 10 })
  }, [fetchData])

  const handleTableChange: TableProps<AllUserDataType>["onChange"] = (
    pagination,
    filters,
    sorter
  ) => {
    fetchData({
      current: pagination.current,
      pageSize: pagination.pageSize,
      filters,
      sorter,
    })
  }

  const getColumnSearchProps = (dataIndex: keyof AllUserDataType) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }: FilterDropdownProps) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => confirm()}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => confirm()}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => clearFilters?.()} size="small" style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value: string | number | boolean, record: AllUserDataType) =>
      record[dataIndex]?.toString().toLowerCase().includes(value.toString().toLowerCase()) ?? false,
  })
  const columns: ColumnsType<AllUserDataType> = [
    {
      title: "Photo",
      dataIndex: "photo",
      render: image => (
        <div style={{ width: "75px", height: "80px" }}>
          <img
            src={image}
            alt="logo"
            style={{ width: "100%", height: "100%", borderRadius: "50%" }}
          />
        </div>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      ...getColumnSearchProps("name"),
      sorter: true,
    },
    {
      title: "Email",
      dataIndex: "email",
      ...getColumnSearchProps("email"),
      sorter: true,
    },
    {
      title: "Location",
      dataIndex: "location",
    },
    {
      title: "Date Registered",
      dataIndex: "date_registered",
      render: date_registered => moment(date_registered).format("DD/MM/YYYY"),
      sorter: true,
    },
    {
      title: "Last Login Date",
      dataIndex: "last_login_date",
      render: last_login_date => moment(last_login_date).format("DD/MM/YYYY"),
      sorter: true,
    },
  ]

  return (
    <div>
      <SkeletonTable loading={loading} columns={columns as SkeletonTableColumnsType[]}>
        <Table
          columns={columns}
          dataSource={userTableData}
          pagination={pagination}
          onChange={handleTableChange}
        />
      </SkeletonTable>
    </div>
  )
  
}

export default CompleteRegTable
