import React, { useContext, FC } from "react";
import { Link } from "react-router-dom";
import "../../../../styles/system.admin.scss";
import { SidebarContext } from "../../../auth/ProtectedRoute";
const EditRole: FC<{ record: { id: number | null; name: string } }> = ({
  record,
}) => {
  const { userName, sidebarData } = useContext(SidebarContext);

  return (
    <div style={{ width: "100%" }}>
      <Link
        to={`edit-role/${record.id}`}
        state={{ userName: userName, sidebar: sidebarData }}
      >
        <button className="__btn">Edit Role</button>
      </Link>
    </div>
  );
};
export default EditRole;
