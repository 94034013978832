import React, { FC } from "react";
import { Tabs } from "antd";
import AllUserTable from "../tables/AllUserTable";
import BadregTable from "../tables/BadregTable";
import "../../../../styles/tab.scss";
import IncompleteRegTable from "../tables/IncompleteRegTable";
import CompleteRegTable from "../tables/CompleteRegTable";
const App: FC = () => (
  <Tabs defaultActiveKey="1">
    <Tabs.TabPane tab="All" key="1">
      <AllUserTable />
    </Tabs.TabPane>
       <Tabs.TabPane tab="Complete Registration" key="2">
      <CompleteRegTable />
    </Tabs.TabPane>
      <Tabs.TabPane tab="Incomplete Registration" key="3">
     <IncompleteRegTable/>
    </Tabs.TabPane>
    <Tabs.TabPane tab="Bad Registration" key="4">
     <BadregTable/>
    </Tabs.TabPane>
  
  </Tabs>
);

export default App;
