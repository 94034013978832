import React, { useContext, FC } from "react"
import { Link, useParams } from "react-router-dom"
import "../../../../../styles/system.admin.scss"
import { SidebarContext } from "../../../../auth/ProtectedRoute"
import { PendingApprovalDataType } from "../type/DataType"

const View: FC<{ record: PendingApprovalDataType }> = ({ record }) => {
  const { userName, sidebarData } = useContext(SidebarContext)
  return (
    <div style={{ width: "100%" }}>
      <Link
        to={`view-instructor/${record.id}`}
        state={{ userName: userName, sidebar: sidebarData }}
      >
        <button className="__btn"> View </button>
      </Link>
    </div>
  )
}
export default View
