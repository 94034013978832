import React, { FC } from "react";

const LanguageTab: FC = () => {
  return (
    <div>
      <div className="user__edit--tab">
        <div className="tab-one">
          <div className="right">
            <h1> English</h1>
            <p>Advanced</p>
          </div>
        </div>
        {/* <div className="tab-two">
          <div className="right">
            <h1>German</h1>
            <p>Intermediate</p>
          </div>
        </div> */}
        {/* <div className="tab-three">
          <div className="right">
            <h1>English</h1>
            <p>Proficient</p>
          </div>
        </div> */}
        {/* <div className="tab-four">
          <div className="right">
            <h1>Swahili</h1>
            <p>Proficient</p>
          </div>
        </div> */}
      </div>
    </div>
  );
};
export default LanguageTab;
