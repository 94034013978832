import React, { useState, Key, useEffect, FC } from "react";
import "antd/dist/antd.css";
import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import type { TableRowSelection } from "antd/es/table/interface";
import { MoreOutlined } from "@ant-design/icons";
import { Dropdown, Menu, Space } from "antd";
import { SubmoduleDataType } from "../types/DataType";
import axios from "axios";
import { isAuth } from "../../../auth/ProtectedRoute";
import SkeletonTable, {
  SkeletonTableColumnsType,
} from "../../../../components/SkeletonTable";
import "../../../../styles/modules.scss";
import EditSubmodule from "../components/EditSubmodule";
import DeleteSubmoduleModal from "../components/DeleteSubmoduleModal";
import swal from "sweetalert";

const AdminSubmoduleTable: FC = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [submoduleTableData, setSubmoduleTableData] = useState<
    SubmoduleDataType[]
  >([]);
  const [showTotal, setShowTotal] = useState<number>();
  // eslint-disable-next-line
  const [record, setRecord] = useState<{ id: number | null; name: string }>({
    id: null,
    name: "",
  });

  const menu = (
    <Menu
      items={[
        {
          label: <EditSubmodule record={record} />,
          key: "1",
          icon: <i className="fa fa-pencil" aria-hidden="true"></i>,
        },
        {
          label: <DeleteSubmoduleModal record={record} />,
          key: "2",
          icon: <i className="fa fa-trash" aria-hidden="true"></i>,
        },
      ]}
    />
  );

  const columns: ColumnsType<SubmoduleDataType> = [
    {
      title: "Id",
      dataIndex: "id",
    },
    {
      title: "Submodule",
      dataIndex: "name",
      render: (text) => (
        <div className="status">
          <p>{text}</p>
        </div>
      ),
    },
    {
      title: "Module",
      dataIndex: "module",
      render: (text) => (
        <div className="status">
          <p>{text}</p>
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      render: () => (
        <div>
          <Space wrap>
            <Dropdown.Button
              overlay={menu}
              placement="bottom"
              icon={<MoreOutlined />}
              trigger={["click"]}
            ></Dropdown.Button>
          </Space>
        </div>
      ),
    },
  ];

  const showSubmoduleTable = (page: number) => {
    axios({
      method: "GET",
      url: process.env.REACT_APP_HOST_API + `sub-module?page=${page}`,
      headers: {
        Authorization: `Bearer ${isAuth()}`,
      },
    })
      .then((res) => {
        setLoading(false);
        if (res.data[0].response) {
          setSubmoduleTableData(
            res.data[0].data.data.map((sub: SubmoduleDataType) => ({
              key: sub.id,
              id: sub.id,
              name: sub.name,
              module: sub.module_name,
              action: "",
            }))
          );
          setShowTotal(res.data[0].data.total);
        }
      })
      .catch((err) => {
        console.error(err);
        swal({
          title: "Error!",
          text: "Something went wrong. Please try again later!",
          icon: "error",
        });
      });
  };

  useEffect(() => {
    showSubmoduleTable(1);
  }, []);

  const onSelectChange = (newSelectedRowKeys: Key[]) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection: TableRowSelection<SubmoduleDataType> = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
      {
        key: "odd",
        text: "Select Odd Row",
        onSelect: (changableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys.filter((_, index) => {
            if (index % 2 !== 0) {
              return false;
            }
            return true;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
      {
        key: "even",
        text: "Select Even Row",
        onSelect: (changableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys.filter((_, index) => {
            if (index % 2 !== 0) {
              return true;
            }
            return false;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
    ],
  };

  return (
    <SkeletonTable
      loading={loading}
      columns={columns as SkeletonTableColumnsType[]}
    >
      <Table
        rowSelection={rowSelection}
        columns={columns}
        dataSource={submoduleTableData}
        pagination={{
          showSizeChanger: false,
          pageSize: 20,
          total: showTotal,
          onChange: (page) => {
            showSubmoduleTable(page);
          },
          showTotal: (showTotal, range) =>
            `${range[0]}-${range[1]} of ${showTotal} items`,
        }}
        onRow={(record) => {
          return {
            onClick: () => {
              setRecord(record);
            },
          };
        }}
      />
    </SkeletonTable>
  );
};

export default AdminSubmoduleTable;
