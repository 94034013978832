import React from "react";
import "../../styles/system.admin.scss";
import { ModalType } from "./ModalType";
import { Formik, Form, Field, FormikHelpers, ErrorMessage } from "formik";
import * as Yup from "yup";
import { ModuleValues } from "../../pages/admin/LeftPanel/types/DataType";
import axios from "axios";
import { isAuth } from "../../pages/auth/ProtectedRoute";

const Modal = (props: ModalType) => {
  const initialValues: ModuleValues = {
    moduleName: "",
    moduleDescription: "",
  };
  return (
    <>
      {props.isOpen && (
        <div className="modal-overlay">
          <div className="modal-box">
            <div className="modal-box--wrapper">
              <div className="heading">
                <h1>Add Module</h1>
                <div onClick={props.toggle} style={{ cursor: "pointer" }}>
                  <svg
                    width="35"
                    height="32"
                    viewBox="0 0 35 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18.5995 16.0001L23.2722 11.3273C23.5763 11.0233 23.5763 10.5319 23.2722 10.228C22.9682 9.92401 22.4768 9.92401 22.1729 10.228L17.5001 14.9008L12.8274 10.228C12.5233 9.92401 12.032 9.92401 11.7281 10.228C11.424 10.5319 11.424 11.0233 11.7281 11.3273L16.4007 16.0001L11.7281 20.6729C11.424 20.9768 11.424 21.4683 11.7281 21.7722C11.8795 21.9238 12.0787 22 12.2777 22C12.4768 22 12.6758 21.9238 12.8274 21.7722L17.5001 17.0994L22.1729 21.7722C22.3245 21.9238 22.5235 22 22.7225 22C22.9216 22 23.1206 21.9238 23.2722 21.7722C23.5763 21.4683 23.5763 20.9768 23.2722 20.6729L18.5995 16.0001Z"
                      fill="black"
                      stroke="black"
                    />
                  </svg>
                </div>
              </div>
              <div className="form-wrap">
                <Formik
                  initialValues={initialValues}
                  validationSchema={Yup.object().shape({
                    moduleName: Yup.string().required("This field is required"),
                    moduleDescription: Yup.string().required(
                      "This field is required"
                    ),
                  })}
                  onSubmit={(
                    values: ModuleValues,
                    { setSubmitting }: FormikHelpers<ModuleValues>
                  ) => {
                    console.log(values);
                    const FormDetails = {
                      name: values.moduleName,
                      moduleDescription: values.moduleDescription,
                      created_by: 1,
                      status: 1,
                    };
                    axios({
                      method: "POST",
                      url: process.env.REACT_APP_HOST_API + `module`,
                      headers: {
                        Authorization: `Bearer ${isAuth()}`,
                        Accept: "application/json",
                        "Content-Type": "application/json",
                      },
                      data: FormDetails,
                    })
                      .then((res) => {
                        console.log(res.data);
                      })
                      .catch((err) => {
                        console.log(err);
                      });
                    setSubmitting(false);
                  }}
                >
                  {() => (
                    <>
                      <Form noValidate autoComplete="off">
                        <div className="form-wrap--module">
                          <label htmlFor="moduleName">Module Name</label>
                          <Field
                            id="moduleName"
                            name="moduleName"
                            type="text"
                            placeholder="e.g User Management"
                          />
                          <ErrorMessage name="moduleName">
                            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                          </ErrorMessage>
                        </div>
                        <div className="form-wrap--desc">
                          <label htmlFor="moduleDescription">
                            Brief Description
                          </label>
                          <Field
                            as="textarea"
                            id="moduleDescription"
                            name="moduleDescription"
                            placeholder="Enter text here..."
                          />
                          <ErrorMessage name="moduleDescription">
                            {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                          </ErrorMessage>
                        </div>
                        <div className="form-wrap--bottom">
                          <div className="btns">
                            <div className="btns--wrap">
                              <button className="reset" type="reset">
                                Reset
                              </button>
                              <button className="save">Save</button>
                            </div>
                          </div>
                        </div>
                      </Form>
                      {/* <pre>{JSON.stringify({ values, errors }, null, 2)}</pre> */}
                    </>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
