import React, { useContext, FC } from "react";
import { Link } from "react-router-dom";
import "../../../../styles/system.admin.scss";
import { SidebarContext } from "../../../auth/ProtectedRoute";
const EditBtnSystemUser: FC<{
  record: { id: number | null; status: number | null; name: string };
}> = ({ record }) => {
  const { userName, sidebarData } = useContext(SidebarContext);
  return (
    <div style={{ width: "100%" }}>
      <Link
        to={`edit-user/${record.id}`}
        state={{ userName: userName, sidebar: sidebarData }}
      >
        <button className="__btn">Edit User</button>
      </Link>
    </div>
  );
};
export default EditBtnSystemUser;
