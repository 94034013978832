import React, { FC } from "react";
import { Routes } from "react-router-dom";
import { PrivateRoute } from "../../App";

const Auth: FC = () => {
  return (
    <PrivateRoute>
      <Routes></Routes>
    </PrivateRoute>
  );
};

export default Auth;
