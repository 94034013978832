import React, { Fragment, ChangeEvent, FC, useState } from "react";
import "../../../styles/group.scss";
import { useNavigate } from "react-router-dom";
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from "formik";
import { isAuth } from "../../auth/ProtectedRoute";
import { FeedValues } from "./types/DataTypes";
import * as Yup from "yup";
import axios from "axios";
import swal from "sweetalert";
import { ActivityLogValues } from "../ActivityLog/types/DataType";
import MUIRichTextEditor from "mui-rte";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { convertToRaw } from "draft-js";
import { CircularProgress } from "@mui/material";

const AddFeed: FC = () => {
  const navigate = useNavigate();
  const [mediaLoading, setMediaLoading] = useState<boolean>(false);
  const [mediaBinary, setMediaBinary] = useState<ReturnType<any>>([]);
  const [contentValue, setContentValue] = useState<string>("");

  //mui editor theme and styling
  const myTheme = createTheme();
  Object.assign(myTheme, {
    overrides: {
      MUIRichTextEditor: {
        root: {
          border: "0.3px solid #dfdfdf",
          borderBottomLeftRadius: "0px",
          borderBottomRightRadius: "0px",
          borderRadius: "5px",
          width: "100%",
          background: "#ffffff",
        },
        editor: {
          width: "100%",
          borderTop: "0.3px solid #dfdfdf",
          height: "200px",
          margin: "8px 0px",
          padding: "10px 18px",
          background: "#ffffff",
          overflow: "auto",
          color: "#181818",
          fontSize: "16px",
          lineHeight: "24px",
        },
        container: {
          display: "flex",
          flexDirection: "column",
          position: "relative",
        },
        placeHolder: {
          position: "absolute",
          top: "60px",
          left: "18px",
          width: "100%",
          height: "100%",
          color: "#ababab",
          fontSize: "16px",
          lineHeight: "24px",
          margin: "0",
          padding: "0",
        },
      },
    },
  });

  //formik form initial values
  const initialValues: FeedValues = {
    email: "",
    media: [],
    content: "",
  };

  //validation for media uploads in the formik form
  const fileCountValidation = (
    limit: number,
    imageSizeLimit: number,
    videoSizeLimit: number,
    fileTypes: string[],
    messages: {
      limitExceeded: string;
      invalidFileType: string;
      invalidFileSize: string;
    }
  ) =>
    Yup.mixed().test("fileCount", messages.limitExceeded, function (value) {
      if (value.length > limit) {
        return this.createError({ message: messages.limitExceeded });
      }

      for (const file of value) {
        const sizeLimit = file.type.startsWith("image/")
          ? imageSizeLimit
          : videoSizeLimit;
        if (file.size > sizeLimit)
          return this.createError({ message: messages.invalidFileSize });

        if (!fileTypes.includes(file.type))
          return this.createError({ message: messages.invalidFileType });
      }

      return true;
    });

  return (
    <Fragment>
      <div className="feed-edit">
        <div className="feed-edit-wrapper">
          <div className="feed-edit--heading">
            <div className="feed-edit--heading--title">
              <h1>Add Feed</h1>
            </div>
            <div className="feed-edit--heading-btn">
              <button onClick={() => navigate(-1)}>
                <span>
                  <svg
                    width="22"
                    height="20"
                    viewBox="0 0 22 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7 2L2 7L7 12"
                      stroke="white"
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M2 7H10C15.523 7 20 11.477 20 17V18"
                      stroke="white"
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
                Back
              </button>
            </div>
          </div>
        </div>
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={Yup.object().shape({
            email: Yup.string()
              .email("Invalid email address")
              .required("This field is required"),
            media: fileCountValidation(
              5,
              5242880,
              20971520,
              ["image/jpeg", "image/png", "image/jpg", "video/mp4"],
              {
                limitExceeded: "You can only select 5 files at a time!",
                invalidFileType:
                  "Invalid file type, only JPEG, JPG, PNG and MP4 files are allowed",
                invalidFileSize:
                  "Invalid file size, images cannot be larger than 5MB and videos cannot be larger than 20MB",
              }
            ),
            content: Yup.string().required("This field is required"),
          })}
          onSubmit={(
            values: FeedValues,
            { setSubmitting }: FormikHelpers<FeedValues>
          ) => {
            //payload for feed values
            const formDetails = {
              email: values.email,
              position: "Graphics Designer",
              media: mediaBinary,
              content: values.content,
              content_raw_data: contentValue,
            };
            //request to submit feed data to server
            axios({
              method: "POST",
              url: process.env.REACT_APP_HOST_API + `manage-feeds/feed`,
              headers: {
                Authorization: `Bearer ${isAuth()}`,
              },
              data: formDetails,
            })
              .then((res) => {
                setSubmitting(false);
                if (res.data.response === true) {
                  //activity log request submitted to server
                  let activityLogItem;
                  if (typeof window !== "undefined") {
                    if (sessionStorage.getItem("activityDetail")) {
                      activityLogItem = JSON.parse(
                        sessionStorage.getItem("activityDetail")!
                      );
                    }
                  }
                  const activityDetail: ActivityLogValues = {
                    full_name: activityLogItem.full_name,
                    role: activityLogItem.role,
                    ip_address: activityLogItem.ip_address,
                    browser: activityLogItem.browser,
                    events: `Added Feed`,
                    module: "Feeds",
                    location: activityLogItem.location,
                    email: activityLogItem.email,
                  };
                  axios({
                    method: "POST",
                    url:
                      process.env.REACT_APP_HOST_API + "manage-activity/user",
                    headers: {
                      Authorization: `Bearer ${isAuth()}`,
                    },
                    data: activityDetail,
                  });
                  swal({
                    title: "Success!",
                    text: "Feed created successfully!",
                    icon: "success",
                  });
                  navigate(-1);
                } else if (
                  // server error 401 handling
                  res.data.response === false &&
                  res.data.status === 401
                ) {
                  swal({
                    title: "Error!",
                    text: res.data.message,
                    icon: "error",
                  });
                } else if (
                  // server error 404 handling
                  res.data.response === false &&
                  res.data.status === 404
                ) {
                  swal({
                    title: "Error!",
                    text: res.data.message,
                    icon: "error",
                  });
                } else if (
                  // server error 406 handling
                  res.data.response === false &&
                  res.data.status === 406
                ) {
                  swal({
                    title: "Error!",
                    text: res.data.message,
                    icon: "error",
                  });
                } else if (
                  // server error 409 handling
                  res.data.response === false &&
                  res.data.status === 409
                ) {
                  swal({
                    title: "Error!",
                    text: res.data.message,
                    icon: "error",
                  });
                } else if (
                  // server error 500 handling
                  res.data.response === false &&
                  res.data.status === 500
                ) {
                  swal({
                    title: "Error!",
                    text: "Something went wrong. Please try again later!",
                    icon: "error",
                  });
                }
              })
              .catch((err) => {
                console.error(err);
                swal({
                  title: "Error!",
                  text: "Something went wrong. Please try again later!",
                  icon: "error",
                });
              });
          }}
        >
          {({ isSubmitting, setFieldValue }) => (
            <>
              <Form noValidate autoComplete="off">
                <div className="feed-edit--wrap">
                  <div className="feed-edit--user">
                    <label htmlFor="email">Select User(Email only)</label>
                    <Field
                      id="email"
                      name="email"
                      type="text"
                      placeholder="Enter user email"
                    />
                    <ErrorMessage name="email">
                      {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                    </ErrorMessage>
                  </div>
                  <div className="feed-edit--attachment">
                    <p>Other Attachments</p>
                    <div className="left">
                      <label htmlFor="media">
                        <Field
                          id="media"
                          name="media"
                          type="file"
                          multiple={true}
                          value={""}
                          accept="image/png, image/jpg, image/jpeg, video/mp4"
                          style={{ display: "none" }}
                          onChange={(event: ChangeEvent<HTMLInputElement>) => {
                            const selectedFiles = Array.from(
                              event.currentTarget.files!
                            );
                            //error handling for when user uploads more than 5 media
                            if (selectedFiles.length > 5) {
                              swal({
                                title: "Error!",
                                text: "You can only add 5 files!",
                                icon: "error",
                              });
                              return;
                            }
                            setFieldValue("media", selectedFiles);
                            const formdata = new FormData();
                            selectedFiles.forEach((file, index) => {
                              // error handling & validation for media uploads request to server
                              if (
                                file.type.startsWith("image/") &&
                                file.size > 5242880
                              ) {
                                swal({
                                  title: "Error!",
                                  text: "Invalid file size, images cannot be larger than 5MB and videos cannot be larger than 20MB",
                                  icon: "error",
                                });
                                return;
                              } else if (
                                file.type.startsWith("video/") &&
                                file.size > 20971520
                              ) {
                                swal({
                                  title: "Error!",
                                  text: "Invalid file size, images cannot be larger than 5MB and videos cannot be larger than 20MB",
                                  icon: "error",
                                });
                                return;
                              }
                              formdata.append(`files[${index}]`, file);
                            });
                            formdata.append(
                              "env",
                              `${process.env.REACT_APP_ENV}`
                            );
                            //media upload request to server
                            setMediaLoading(true);
                            axios({
                              method: "POST",
                              url:
                                process.env.REACT_APP_HOST_API + `uploads/file`,
                              data: formdata,
                            })
                              .then((res) => {
                                if (
                                  mediaBinary?.length +
                                    res?.data?.data?.length <=
                                  5
                                ) {
                                  res?.data?.data?.forEach((d: any) => {
                                    setMediaBinary((prev?: any) => {
                                      return [...prev, d.filepath];
                                    });
                                  });
                                } else {
                                  swal({
                                    title: "Error!",
                                    text: "You can only add 5 files!",
                                    icon: "error",
                                  });
                                  setMediaLoading(false);
                                  return mediaBinary;
                                }
                              })
                              .then(() => {
                                setMediaLoading(false);
                              })
                              .catch((error) => {
                                setMediaLoading(false);
                                console.log(error);
                                swal({
                                  title: "Error!",
                                  text: "Something went wrong. Please try again later!",
                                  icon: "error",
                                });
                              });
                          }}
                        />
                        <div className="svg-text">
                          <svg
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              width="32"
                              height="32"
                              rx="16"
                              fill="#FAFAFA"
                            />
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M10.173 15.1706C9.7149 15.1706 9.34356 15.5419 9.34356 16C9.34356 16.4581 9.7149 16.8294 10.173 16.8294L15.2416 16.8294L15.2416 21.8981C15.2416 22.3562 15.613 22.7275 16.0711 22.7275C16.5291 22.7275 16.9005 22.3562 16.9005 21.8981L16.9005 16.8294L21.9692 16.8294C22.4272 16.8294 22.7986 16.4581 22.7986 16C22.7986 15.5419 22.4272 15.1706 21.9692 15.1706L16.9005 15.1706L16.9005 10.1019C16.9005 9.64383 16.5291 9.27249 16.0711 9.27249C15.613 9.27249 15.2416 9.64383 15.2416 10.1019L15.2416 15.1706L10.173 15.1706Z"
                              fill="#929292"
                            />
                          </svg>
                        </div>
                      </label>
                      <div style={{ display: "flex" }}>
                        {mediaBinary
                          ? mediaBinary?.map((media: string, i: number) => {
                              //preview of uploaded media
                              if (
                                media?.substring(
                                  media?.lastIndexOf(".") + 1
                                ) !== "mp4"
                              ) {
                                //conditional statement to check for image preview area
                                return (
                                  <div key={i} className="imageOne">
                                    <img
                                      src={
                                        process.env.REACT_APP_IMAGE_API_PREFIX +
                                        media
                                      }
                                      alt="attachment"
                                    />
                                  </div>
                                );
                              } else if (
                                media?.substring(
                                  media?.lastIndexOf(".") + 1
                                ) === "mp4"
                              ) {
                                //conditional statement to check for video preview area
                                return (
                                  <div key={i} className="imageOne">
                                    <video controls>
                                      <source
                                        src={
                                          process.env
                                            .REACT_APP_IMAGE_API_PREFIX + media
                                        }
                                        type="video/mp4"
                                      />
                                      Your browser does not support HTML5 video.
                                    </video>
                                  </div>
                                );
                              }
                            })
                          : null}
                      </div>
                    </div>
                    <ErrorMessage name="media">
                      {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                    </ErrorMessage>
                  </div>
                  {mediaLoading && (
                    <CircularProgress sx={{ color: "gray", mt: 2 }} size={12} />
                  )}
                </div>
                <div className="feed-edit--desc">
                  <label htmlFor="content">Content</label>
                  <ThemeProvider theme={myTheme}>
                    <MUIRichTextEditor
                      label="write something here..."
                      id="content"
                      controls={[
                        "title",
                        "bold",
                        "italic",
                        "underline",
                        "strikethrough",
                        "highlight",
                        "list",
                        "numberList",
                        "bulletList",
                        "quote",
                        "code",
                        "undo",
                        "redo",
                      ]}
                      inlineToolbar={true}
                      onChange={(event: any) => {
                        //function to convert editor state to raw data format
                        const content = JSON.stringify(
                          convertToRaw(event.getCurrentContent())
                        );
                        //function to convert from editor state to plain text format
                        const plainText = event
                          .getCurrentContent()
                          .getPlainText("\u0001");
                        setFieldValue("content", plainText);
                        setContentValue(content);
                      }}
                    />
                  </ThemeProvider>
                  <ErrorMessage name="content">
                    {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                  </ErrorMessage>
                </div>
                <div className="feed-edit--btn">
                  <button type="submit">
                    {isSubmitting ? "Submitting..." : "Submit"}
                  </button>
                </div>
              </Form>
            </>
          )}
        </Formik>
      </div>
    </Fragment>
  );
};
export default AddFeed;
