import useModal from "../../../components/modal/useModal";
import React, { Fragment, FC } from "react";
import user from "../../../assets/user-img.png";
import "../../../styles/modals.scss";
import CategoryTable from "./tables/CategoryTable";
import AddCategoryModal from "./components/AddCategoryModal";

const Categories: FC = () => {
  const { isOpen, toggle } = useModal();
  return (
    <Fragment>
      <div className="roless">
        <div className="roless-wrapper">
          <div className="roless--heading">
            <div className="roless--heading-img">
              <img src={user} alt="profile" />
            </div>
          </div>
          <div className="roless--tables">
            <div className="roless--tables-heading">
              <div className="roless--tables-heading-title">
                <h2>Categories</h2>
              </div>
              <div className="roless--tables-heading-box">
                <div className="formy">
                  <form>
                    <div className="formy--box">
                      <input type="text" placeholder="Search.." name="search" />
                      <div className="formy--box-icon">
                        <i className="fa fa-search"></i>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="clicks">
                  <div className="clicks--right">
                    <button onClick={toggle}>
                      <span>
                        <i className="fa fa-plus-circle" aria-hidden="true"></i>
                      </span>
                      Add Category
                    </button>
                    <AddCategoryModal
                      isOpen={isOpen}
                      toggle={toggle}
                    ></AddCategoryModal>
                  </div>
                </div>
              </div>
            </div>
            <div className="roless--tables--content">
              <CategoryTable />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default Categories;
