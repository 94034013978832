import React, { Fragment, useEffect, useState, FC } from "react";
import axios from "axios";
import { ErrorMessage, Field, Formik, Form } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { isAuth } from "../../auth/ProtectedRoute";
import { EditUserType, RoleSelectType } from "./types/DataType";
import * as Yup from "yup";
import swal from "sweetalert";
import { ActivityLogValues } from "../ActivityLog/types/DataType";

const EditUser: FC = () => {
  const navigate = useNavigate();

  const { id } = useParams();

  const [userData, setUserData] = useState<EditUserType>({
    name: "",
    email: "",
    phone1: "",
    role_id: null,
  });
  const [userRole, setUserRole] = useState<RoleSelectType[]>([]);

  useEffect(() => {
    axios({
      method: "GET",
      url: process.env.REACT_APP_HOST_API + `manage-user/${id}`,
      headers: {
        Authorization: `Bearer ${isAuth()}`,
      },
    })
      .then((res) => {
        setUserData(res.data[0].data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    axios({
      method: "GET",
      url: process.env.REACT_APP_HOST_API + `role`,
      headers: {
        Authorization: `Bearer ${isAuth()}`,
      },
    })
      .then((res) => {
        setUserRole(res.data.data.data);
      })
      .catch((err) => {
        console.error(err);
        swal({
          title: "Error!",
          text: "Something went wrong. Please try again later!",
          icon: "error",
        });
      });
  }, []);
  const initialValues: EditUserType = {
    name: userData.name,
    email: userData.email,
    phone1: userData.phone1,
    role_id: userData.role_id,
  };
  return (
    <Fragment>
      <div className="admin-edit">
        <div className="admin-edit-wrapper">
          <div className="admin-edit--heading">
            <div className="admin-edit--heading--title">
              <h1>Edit User</h1>
            </div>
            <div className="admin-edit--heading-btn">
              <button onClick={() => navigate(-1)}>
                <span>
                  <svg
                    width="22"
                    height="20"
                    viewBox="0 0 22 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7 2L2 7L7 12"
                      stroke="white"
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M2 7H10C15.523 7 20 11.477 20 17V18"
                      stroke="white"
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
                Back
              </button>
            </div>
          </div>
        </div>
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={Yup.object().shape({
            name: Yup.string().required("This field is required"),
            email: Yup.string()
              .email("Incorrect Email format")
              .required("This field is required"),
            phone1: Yup.string().required("This field is required").nullable(),
            role_id: Yup.number().required("This field is required"),
          })}
          onSubmit={(values, { setSubmitting }) => {
            const formDetails = {
              role_id: values.role_id,
              fullname: values.name,
              email: values.email,
              phone: values.phone1,
            };
            axios({
              method: "PATCH",
              url: process.env.REACT_APP_HOST_API + `manage-user/${id}`,
              headers: {
                Authorization: `Bearer ${isAuth()}`,
              },
              data: formDetails,
            })
              .then((res) => {
                setSubmitting(false);
                if (res.data[0].response === true) {
                  let activityLogItem;
                  if (typeof window !== "undefined") {
                    if (sessionStorage.getItem("activityDetail")) {
                      activityLogItem = JSON.parse(
                        sessionStorage.getItem("activityDetail")!
                      );
                    }
                  }
                  const activityDetail: ActivityLogValues = {
                    full_name: activityLogItem.full_name,
                    role: activityLogItem.role,
                    ip_address: activityLogItem.ip_address,
                    browser: activityLogItem.browser,
                    events: `Edited User: ${values.name}`,
                    module: "Admin Users",
                    location: activityLogItem.location,
                    email: activityLogItem.email,
                  };
                  axios({
                    method: "POST",
                    url:
                      process.env.REACT_APP_HOST_API + "manage-activity/user",
                    headers: {
                      Authorization: `Bearer ${isAuth()}`,
                    },
                    data: activityDetail,
                  });
                  swal({
                    title: "Success!",
                    text: "User data updated successfully!",
                    icon: "success",
                  });
                  navigate(-1);
                } else if (
                  res.data.response === false &&
                  res.data.status === 404
                ) {
                  swal({
                    title: "Error!",
                    text: res.data.message,
                    icon: "error",
                  });
                }
              })
              .catch((err) => {
                console.error(err);
                swal({
                  title: "Error!",
                  text: "Something went wrong. Please try again later!",
                  icon: "error",
                });
              });
          }}
        >
          {({ isSubmitting }) => (
            <Form noValidate autoComplete="off">
              <div className="admin-edit--wrap">
                <div className="admin-edit--user">
                  <div className="right">
                    <label htmlFor="name">Full name</label>
                    <Field
                      id="name"
                      name="name"
                      type="text"
                      placeholder="Enter full name"
                    />
                    <ErrorMessage name="name">
                      {(msg: string) => (
                        <div style={{ color: "red" }}>{msg}</div>
                      )}
                    </ErrorMessage>
                  </div>
                  <div className="right">
                    <label htmlFor="email">Email</label>
                    <Field
                      id="email"
                      name="email"
                      type="text"
                      placeholder="Enter email"
                    />
                    <ErrorMessage name="email">
                      {(msg: string) => (
                        <div style={{ color: "red" }}>{msg}</div>
                      )}
                    </ErrorMessage>
                  </div>
                </div>
                <div className="admin-edit--user">
                  <div className="right">
                    <label htmlFor="phone1">Phone</label>
                    <Field
                      id="phone1"
                      name="phone1"
                      type="text"
                      placeholder="Enter Phone Number"
                    />
                    <ErrorMessage name="phone1">
                      {(msg: string) => (
                        <div style={{ color: "red" }}>{msg}</div>
                      )}
                    </ErrorMessage>
                  </div>
                  <div className="right">
                    <label htmlFor="role_id">Role</label>
                    <Field id="role_id" name="role_id" type="text" as="select">
                      {userRole ? (
                        userRole.map((role: RoleSelectType) => (
                          <>
                            <option value="" disabled selected hidden>
                              --Please choose an option--
                            </option>
                            <option key={role.id} value={role.id}>
                              {role.name}
                            </option>
                          </>
                        ))
                      ) : (
                        <option value="" disabled>
                          Options not available
                        </option>
                      )}
                    </Field>
                    <ErrorMessage name="role_id">
                      {(msg: string) => (
                        <div style={{ color: "red" }}>{msg}</div>
                      )}
                    </ErrorMessage>
                  </div>
                </div>
              </div>
              <div className="admin-edit--btn">
                <button type="submit">
                  {isSubmitting ? "Submitting..." : "Update"}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Fragment>
  );
};
export default EditUser;
