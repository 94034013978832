import React, { Fragment, FC } from "react";
import SystemAdmin from "./SystemAdmin";
import AdminModule from "./AdminModule";
import { useLocation } from "react-router-dom";

const Modules: FC = () => {
  const { state: { sidebar } = {} } = useLocation();

  return (
    <Fragment>
      <div className="modules">
        <div className="modules__header">
          {sidebar! < 1 ? (
            <SystemAdmin />
          ) : (
            <div>
              <AdminModule />
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
};
export default Modules;
