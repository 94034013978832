import React, { FC } from "react";

import user from "../../../../assets/user.png";
const KonnectionTab: FC = () => {
  return (
    <div>
      <div className="user__edit--tab">
        <div className="konnect">
          <div className="konnect-img">
            <img src={user} />
            <span></span>
          </div>
          <div className="konnect-detail">
            <h1>Fortunebolt</h1>
            <span>Job Seeker</span>
            <p>
              <svg
                width="12"
                height="15"
                viewBox="0 0 12 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.8322 15C4.77985 14.1024 3.80442 13.1183 2.9161 12.0581C1.58303 10.4659 7.32269e-07 8.09468 7.32269e-07 5.83512C-0.000577404 4.68115 0.341191 3.55294 0.982054 2.59327C1.62292 1.63361 2.53407 0.885634 3.60019 0.444003C4.66631 0.0023718 5.83949 -0.113061 6.97124 0.112313C8.10299 0.337687 9.14245 0.893737 9.95806 1.71009C10.5011 2.2507 10.9315 2.89364 11.2244 3.6017C11.5173 4.30976 11.6668 5.06888 11.6644 5.83512C11.6644 8.09468 10.0814 10.4659 8.74829 12.0581C7.85997 13.1183 6.88454 14.1024 5.8322 15ZM5.8322 3.33561C5.16928 3.33561 4.53352 3.59895 4.06477 4.0677C3.59602 4.53645 3.33268 5.17221 3.33268 5.83512C3.33268 6.49803 3.59602 7.13379 4.06477 7.60254C4.53352 8.07129 5.16928 8.33463 5.8322 8.33463C6.49511 8.33463 7.13087 8.07129 7.59962 7.60254C8.06837 7.13379 8.33171 6.49803 8.33171 5.83512C8.33171 5.17221 8.06837 4.53645 7.59962 4.0677C7.13087 3.59895 6.49511 3.33561 5.8322 3.33561Z"
                  fill="#666666"
                />
              </svg>
              Florida, USA
            </p>
          </div>
          <div className="konnect-num">
            <p>K-0231</p>
          </div>
        </div>
        <div className="konnect-one">
          <div className="konnect-img">
            <img src={user} />
            <span></span>
          </div>
          <div className="konnect-detail">
            <h1>Fortunebolt</h1>
            <span>Job Seeker</span>
            <p>
              <svg
                width="12"
                height="15"
                viewBox="0 0 12 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.8322 15C4.77985 14.1024 3.80442 13.1183 2.9161 12.0581C1.58303 10.4659 7.32269e-07 8.09468 7.32269e-07 5.83512C-0.000577404 4.68115 0.341191 3.55294 0.982054 2.59327C1.62292 1.63361 2.53407 0.885634 3.60019 0.444003C4.66631 0.0023718 5.83949 -0.113061 6.97124 0.112313C8.10299 0.337687 9.14245 0.893737 9.95806 1.71009C10.5011 2.2507 10.9315 2.89364 11.2244 3.6017C11.5173 4.30976 11.6668 5.06888 11.6644 5.83512C11.6644 8.09468 10.0814 10.4659 8.74829 12.0581C7.85997 13.1183 6.88454 14.1024 5.8322 15ZM5.8322 3.33561C5.16928 3.33561 4.53352 3.59895 4.06477 4.0677C3.59602 4.53645 3.33268 5.17221 3.33268 5.83512C3.33268 6.49803 3.59602 7.13379 4.06477 7.60254C4.53352 8.07129 5.16928 8.33463 5.8322 8.33463C6.49511 8.33463 7.13087 8.07129 7.59962 7.60254C8.06837 7.13379 8.33171 6.49803 8.33171 5.83512C8.33171 5.17221 8.06837 4.53645 7.59962 4.0677C7.13087 3.59895 6.49511 3.33561 5.8322 3.33561Z"
                  fill="#666666"
                />
              </svg>
              Florida, USA
            </p>
          </div>
          <div className="konnect-num">
            <p>K-0231</p>
          </div>
        </div>
        <div className="konnect-two">
          <div className="konnect-img">
            <img src={user} />
            <span></span>
          </div>
          <div className="konnect-detail">
            <h1>Fortunebolt</h1>
            <span>Job Seeker</span>
            <p>
              <svg
                width="12"
                height="15"
                viewBox="0 0 12 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.8322 15C4.77985 14.1024 3.80442 13.1183 2.9161 12.0581C1.58303 10.4659 7.32269e-07 8.09468 7.32269e-07 5.83512C-0.000577404 4.68115 0.341191 3.55294 0.982054 2.59327C1.62292 1.63361 2.53407 0.885634 3.60019 0.444003C4.66631 0.0023718 5.83949 -0.113061 6.97124 0.112313C8.10299 0.337687 9.14245 0.893737 9.95806 1.71009C10.5011 2.2507 10.9315 2.89364 11.2244 3.6017C11.5173 4.30976 11.6668 5.06888 11.6644 5.83512C11.6644 8.09468 10.0814 10.4659 8.74829 12.0581C7.85997 13.1183 6.88454 14.1024 5.8322 15ZM5.8322 3.33561C5.16928 3.33561 4.53352 3.59895 4.06477 4.0677C3.59602 4.53645 3.33268 5.17221 3.33268 5.83512C3.33268 6.49803 3.59602 7.13379 4.06477 7.60254C4.53352 8.07129 5.16928 8.33463 5.8322 8.33463C6.49511 8.33463 7.13087 8.07129 7.59962 7.60254C8.06837 7.13379 8.33171 6.49803 8.33171 5.83512C8.33171 5.17221 8.06837 4.53645 7.59962 4.0677C7.13087 3.59895 6.49511 3.33561 5.8322 3.33561Z"
                  fill="#666666"
                />
              </svg>
              Florida, USA
            </p>
          </div>
          <div className="konnect-num">
            <p>K-0231</p>
          </div>
        </div>
        <div className="konnect-three">
          <div className="konnect-img">
            <img src={user} />
            <span></span>
          </div>
          <div className="konnect-detail">
            <h1>Fortunebolt</h1>
            <span>Job Seeker</span>
            <p>
              <svg
                width="12"
                height="15"
                viewBox="0 0 12 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.8322 15C4.77985 14.1024 3.80442 13.1183 2.9161 12.0581C1.58303 10.4659 7.32269e-07 8.09468 7.32269e-07 5.83512C-0.000577404 4.68115 0.341191 3.55294 0.982054 2.59327C1.62292 1.63361 2.53407 0.885634 3.60019 0.444003C4.66631 0.0023718 5.83949 -0.113061 6.97124 0.112313C8.10299 0.337687 9.14245 0.893737 9.95806 1.71009C10.5011 2.2507 10.9315 2.89364 11.2244 3.6017C11.5173 4.30976 11.6668 5.06888 11.6644 5.83512C11.6644 8.09468 10.0814 10.4659 8.74829 12.0581C7.85997 13.1183 6.88454 14.1024 5.8322 15ZM5.8322 3.33561C5.16928 3.33561 4.53352 3.59895 4.06477 4.0677C3.59602 4.53645 3.33268 5.17221 3.33268 5.83512C3.33268 6.49803 3.59602 7.13379 4.06477 7.60254C4.53352 8.07129 5.16928 8.33463 5.8322 8.33463C6.49511 8.33463 7.13087 8.07129 7.59962 7.60254C8.06837 7.13379 8.33171 6.49803 8.33171 5.83512C8.33171 5.17221 8.06837 4.53645 7.59962 4.0677C7.13087 3.59895 6.49511 3.33561 5.8322 3.33561Z"
                  fill="#666666"
                />
              </svg>
              Florida, USA
            </p>
          </div>
          <div className="konnect-num">
            <p>K-0231</p>
          </div>
        </div>
        <div className="konnect-four">
          <div className="konnect-img">
            <img src={user} />
            <span></span>
          </div>
          <div className="konnect-detail">
            <h1>Fortunebolt</h1>
            <span>Job Seeker</span>
            <p>
              <svg
                width="12"
                height="15"
                viewBox="0 0 12 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.8322 15C4.77985 14.1024 3.80442 13.1183 2.9161 12.0581C1.58303 10.4659 7.32269e-07 8.09468 7.32269e-07 5.83512C-0.000577404 4.68115 0.341191 3.55294 0.982054 2.59327C1.62292 1.63361 2.53407 0.885634 3.60019 0.444003C4.66631 0.0023718 5.83949 -0.113061 6.97124 0.112313C8.10299 0.337687 9.14245 0.893737 9.95806 1.71009C10.5011 2.2507 10.9315 2.89364 11.2244 3.6017C11.5173 4.30976 11.6668 5.06888 11.6644 5.83512C11.6644 8.09468 10.0814 10.4659 8.74829 12.0581C7.85997 13.1183 6.88454 14.1024 5.8322 15ZM5.8322 3.33561C5.16928 3.33561 4.53352 3.59895 4.06477 4.0677C3.59602 4.53645 3.33268 5.17221 3.33268 5.83512C3.33268 6.49803 3.59602 7.13379 4.06477 7.60254C4.53352 8.07129 5.16928 8.33463 5.8322 8.33463C6.49511 8.33463 7.13087 8.07129 7.59962 7.60254C8.06837 7.13379 8.33171 6.49803 8.33171 5.83512C8.33171 5.17221 8.06837 4.53645 7.59962 4.0677C7.13087 3.59895 6.49511 3.33561 5.8322 3.33561Z"
                  fill="#666666"
                />
              </svg>
              Florida, USA
            </p>
          </div>
          <div className="konnect-num">
            <p>K-0231</p>
          </div>
        </div>
        <div className="konnect-five">
          <div className="konnect-img">
            <img src={user} />
            <span></span>
          </div>
          <div className="konnect-detail">
            <h1>Fortunebolt</h1>
            <span>Job Seeker</span>
            <p>
              <svg
                width="12"
                height="15"
                viewBox="0 0 12 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.8322 15C4.77985 14.1024 3.80442 13.1183 2.9161 12.0581C1.58303 10.4659 7.32269e-07 8.09468 7.32269e-07 5.83512C-0.000577404 4.68115 0.341191 3.55294 0.982054 2.59327C1.62292 1.63361 2.53407 0.885634 3.60019 0.444003C4.66631 0.0023718 5.83949 -0.113061 6.97124 0.112313C8.10299 0.337687 9.14245 0.893737 9.95806 1.71009C10.5011 2.2507 10.9315 2.89364 11.2244 3.6017C11.5173 4.30976 11.6668 5.06888 11.6644 5.83512C11.6644 8.09468 10.0814 10.4659 8.74829 12.0581C7.85997 13.1183 6.88454 14.1024 5.8322 15ZM5.8322 3.33561C5.16928 3.33561 4.53352 3.59895 4.06477 4.0677C3.59602 4.53645 3.33268 5.17221 3.33268 5.83512C3.33268 6.49803 3.59602 7.13379 4.06477 7.60254C4.53352 8.07129 5.16928 8.33463 5.8322 8.33463C6.49511 8.33463 7.13087 8.07129 7.59962 7.60254C8.06837 7.13379 8.33171 6.49803 8.33171 5.83512C8.33171 5.17221 8.06837 4.53645 7.59962 4.0677C7.13087 3.59895 6.49511 3.33561 5.8322 3.33561Z"
                  fill="#666666"
                />
              </svg>
              Florida, USA
            </p>
          </div>
          <div className="konnect-num">
            <p>K-0231</p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default KonnectionTab;
