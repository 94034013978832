import React, { Fragment } from "react";
import "../../../../styles/headerbar.scss";
import user from "../../../../assets/user.png";
import styled from "styled-components";

function HeaderBar() {
  return (
    <Fragment>
      <div className="header-bar">
        <div></div>
        <div className="user-wrapper">
          <ImageContainer>
            <img src={user} alt="user" />
          </ImageContainer>
        </div>
      </div>
    </Fragment>
  );
}

export default HeaderBar;

const ImageContainer = styled.div`
  height: 70px;
  position: sticky;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  background-color: #ffffff;
  z-index: 100;
`;
