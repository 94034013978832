import { useState } from "react";

export default function useModal() {
  const [isOpenCSV, setisOpenCSV] = useState<boolean>(false);
  const toggleCSV = () => {
    setisOpenCSV(!isOpenCSV);
  };
  return {
    isOpenCSV,
    toggleCSV,
    setisOpenCSV,
  };
}
