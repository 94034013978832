// import React from "react";

// interface FileNameProps {
//   mediaString: string;
// }

// const FileName: React.FC<FileNameProps> = ({ mediaString }) => {
//   const fileNameString = (mediaString: any) => {
//     let ext = mediaString?.split(".").pop();
//     let tag = mediaString?.slice(0, 11);
//     return `${tag}.${ext}`;
//   };

//   return <span>{fileNameString(mediaString)}</span>;
// };

// export default FileName;

import React from "react";

interface FileNameProps {
  mediaString: string;
}

const FileName: React.FC<FileNameProps> = ({ mediaString }) => {
  const fileNameString = (mediaString: string) => {
    const ext = mediaString?.split(".").pop() || "";
    const tag = mediaString?.slice(0, 11) || "";
    return `${tag}.${ext}`;
  };

  return <span>{fileNameString(mediaString)}</span>;
};

export default FileName;