import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "../../../styles/subscription.scss";
import axios from "axios";
import swal from "sweetalert";
import { ViewMarketPlaceDataType } from "./type/DataType";
import { isAuth } from "../../auth/ProtectedRoute";
const ViewMarketplaceOrder = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [viewProject, setViewProject] = useState<ViewMarketPlaceDataType>({} as ViewMarketPlaceDataType);
  useEffect(() => {
    axios({
      method: "GET",
      url: process.env.REACT_APP_HOST_API + `order-management/show/${params.id}?status=ongoing`,
      headers: {
        Authorization: `Bearer ${isAuth()}`,
      },
    })
      .then((res) => {
        console.log(res);
        if (res?.data?.response === true) {
          setViewProject(res?.data?.data);
        }
      })
      .catch((err) => {
        console.error(err);
        swal({
          icon: "error",
          text: "Something went wrong. Please try again later!",
        });
      });
  }, []);

  const getAttachFilename = (mediaString: any) => {
    const ext = mediaString?.split(".").pop();
    const tag = mediaString?.split("_");
    const name = tag?.slice(2, tag.length).join("_");
    return name?.slice(0, 10) + "." + ext;
  };
  return (
    <div className="order">
      <div className="subscription__header">
        <div className="subscription__header-children">
          <h1>View</h1>
          <button onClick={() => navigate(-1)}>
            <svg
              width="22"
              height="20"
              viewBox="0 0 22 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7 2L2 7L7 12"
                stroke="white"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M2 7H10C15.523 7 20 11.477 20 17V18"
                stroke="white"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            Back
          </button>
        </div>
      </div>
      <div className="order__wrapper">
        <div className="right">
          <div className="right__header">
            <svg
              width="27"
              height="25"
              viewBox="0 0 27 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19.999 24.4984H1.99832C1.91148 24.4983 1.82615 24.4757 1.7507 24.4327C1.67525 24.3898 1.61229 24.3279 1.568 24.2532C1.52372 24.1785 1.49963 24.0935 1.49812 24.0067C1.49661 23.9199 1.51772 23.8342 1.55938 23.758L10.5595 7.25784C10.6065 7.18408 10.6714 7.12336 10.7481 7.0813C10.8248 7.03924 10.9109 7.01719 10.9984 7.01719C11.0859 7.01719 11.172 7.03924 11.2487 7.0813C11.3254 7.12336 11.3903 7.18408 11.4373 7.25784L20.4379 23.758C20.4795 23.8342 20.5005 23.9198 20.4989 24.0066C20.4973 24.0933 20.4732 24.1782 20.429 24.2528C20.3847 24.3274 20.3218 24.3892 20.2464 24.4322C20.171 24.4752 20.0858 24.4983 19.999 24.4984ZM2.84068 23.4992H19.1576L10.9989 8.54168L2.84068 23.4992Z"
                fill="#4895EF"
              />
              <path
                d="M25.0012 24.5H20.0008C19.8682 24.5 19.741 24.4473 19.6472 24.3535C19.5534 24.2597 19.5007 24.1325 19.5007 23.9999C19.5007 23.8673 19.5534 23.7401 19.6472 23.6463C19.741 23.5525 19.8682 23.4998 20.0008 23.4998H24.1547L19.5289 15.0894L17.6229 19.0333C17.5943 19.0924 17.5543 19.1453 17.5052 19.1889C17.4562 19.2326 17.399 19.2662 17.3369 19.2877C17.2749 19.3093 17.2092 19.3184 17.1437 19.3146C17.0781 19.3107 17.0139 19.2941 16.9548 19.2654C16.8957 19.2368 16.8428 19.1968 16.7992 19.1478C16.7555 19.0987 16.722 19.0415 16.7004 18.9795C16.6788 18.9174 16.6697 18.8518 16.6735 18.7862C16.6774 18.7206 16.6941 18.6565 16.7227 18.5974L19.0508 13.7812C19.0911 13.6987 19.1532 13.6287 19.2304 13.5791C19.3076 13.5294 19.397 13.5019 19.4888 13.4996C19.5799 13.4999 19.6695 13.524 19.7485 13.5693C19.8276 13.6147 19.8936 13.6798 19.9399 13.7584L25.4401 23.7586C25.4819 23.8348 25.5031 23.9206 25.5016 24.0075C25.5002 24.0944 25.4761 24.1794 25.4318 24.2542C25.3875 24.329 25.3245 24.3909 25.249 24.4339C25.1735 24.4769 25.0881 24.5 25.0012 24.5Z"
                fill="#4895EF"
              />
              <path
                d="M10.9982 8.00007C10.8657 7.99994 10.7386 7.94723 10.6449 7.85353C10.5512 7.75982 10.4985 7.63276 10.4984 7.50024V0.999837C10.4985 0.867314 10.5512 0.740257 10.6449 0.646549C10.7386 0.55284 10.8657 0.500136 10.9982 0.500002H16.9988C17.0978 0.499742 17.1947 0.528891 17.2772 0.583756C17.3597 0.638621 17.424 0.716734 17.462 0.808196C17.5001 0.899658 17.5101 1.00035 17.4909 1.09753C17.4716 1.1947 17.424 1.28397 17.354 1.35404L15.7053 3.00019L17.3514 4.64635C17.4215 4.71642 17.4691 4.80569 17.4883 4.90286C17.5076 5.00004 17.4976 5.10073 17.4595 5.19219C17.4215 5.28366 17.3571 5.36177 17.2747 5.41663C17.1922 5.4715 17.0953 5.50065 16.9962 5.50039H11.4985V7.50024C11.4984 7.63276 11.4457 7.75982 11.352 7.85353C11.2583 7.94723 11.1307 7.99994 10.9982 8.00007ZM11.498 4.49869H15.791L14.6457 3.35388C14.5521 3.26008 14.4995 3.13297 14.4995 3.00045C14.4995 2.86792 14.5521 2.74081 14.6457 2.64701L15.7915 1.50018H11.4985L11.498 4.49869Z"
                fill="#4895EF"
              />
              <path
                d="M12.4967 15.4968C12.4845 15.4968 12.4738 15.4968 12.4606 15.4968C12.3892 15.4918 12.3198 15.4714 12.257 15.4371C12.1942 15.4027 12.1395 15.3553 12.0968 15.2979L10.9966 13.8319L9.89446 15.2974C9.85147 15.3545 9.79677 15.4018 9.73402 15.4361C9.67128 15.4704 9.60193 15.4909 9.53062 15.4963C9.45904 15.5025 9.38697 15.4927 9.31964 15.4676C9.25232 15.4425 9.19143 15.4027 9.14141 15.3512L7.46328 13.67C7.3788 13.5746 7.3339 13.4506 7.33777 13.3233C7.34163 13.196 7.39396 13.075 7.48406 12.9849C7.57417 12.8949 7.69525 12.8426 7.82258 12.8389C7.9499 12.8351 8.07387 12.8801 8.16914 12.9646L9.44131 14.2368L10.5952 12.6977C10.6438 12.6385 10.7049 12.5909 10.7741 12.5582C10.8432 12.5254 10.9188 12.5085 10.9954 12.5085C11.0719 12.5085 11.1475 12.5254 11.2167 12.5582C11.2859 12.5909 11.3469 12.6385 11.3955 12.6977L12.5494 14.2368L13.8216 12.9646C13.916 12.8749 14.0417 12.8255 14.1719 12.827C14.3021 12.8285 14.4266 12.8808 14.5189 12.9727C14.6112 13.0646 14.6639 13.189 14.6659 13.3192C14.6679 13.4494 14.6189 13.5753 14.5295 13.67L12.8493 15.3501C12.8027 15.3968 12.7473 15.4337 12.6864 15.4588C12.6254 15.4839 12.56 15.4966 12.4941 15.4963L12.4967 15.4968Z"
                fill="#4895EF"
              />
              <path
                d="M19.999 24.4984H1.99832C1.91148 24.4983 1.82615 24.4757 1.7507 24.4327C1.67525 24.3898 1.61229 24.3279 1.568 24.2532C1.52372 24.1785 1.49963 24.0935 1.49812 24.0067C1.49661 23.9199 1.51772 23.8342 1.55938 23.758L10.5595 7.25784C10.6065 7.18408 10.6714 7.12336 10.7481 7.0813C10.8248 7.03924 10.9109 7.01719 10.9984 7.01719C11.0859 7.01719 11.172 7.03924 11.2487 7.0813C11.3254 7.12336 11.3903 7.18408 11.4373 7.25784L20.4379 23.758C20.4795 23.8342 20.5005 23.9198 20.4989 24.0066C20.4973 24.0933 20.4732 24.1782 20.429 24.2528C20.3847 24.3274 20.3218 24.3892 20.2464 24.4322C20.171 24.4752 20.0858 24.4983 19.999 24.4984ZM2.84068 23.4992H19.1576L10.9989 8.54168L2.84068 23.4992Z"
                stroke="#4895EF"
              />
              <path
                d="M25.0012 24.5H20.0008C19.8682 24.5 19.741 24.4473 19.6472 24.3535C19.5534 24.2597 19.5007 24.1325 19.5007 23.9999C19.5007 23.8673 19.5534 23.7401 19.6472 23.6463C19.741 23.5525 19.8682 23.4998 20.0008 23.4998H24.1547L19.5289 15.0894L17.6229 19.0333C17.5943 19.0924 17.5543 19.1453 17.5052 19.1889C17.4562 19.2326 17.399 19.2662 17.3369 19.2877C17.2749 19.3093 17.2092 19.3184 17.1437 19.3146C17.0781 19.3107 17.0139 19.2941 16.9548 19.2654C16.8957 19.2368 16.8428 19.1968 16.7992 19.1478C16.7555 19.0987 16.722 19.0415 16.7004 18.9795C16.6788 18.9174 16.6697 18.8518 16.6735 18.7862C16.6774 18.7206 16.6941 18.6565 16.7227 18.5974L19.0508 13.7812C19.0911 13.6987 19.1532 13.6287 19.2304 13.5791C19.3076 13.5294 19.397 13.5019 19.4888 13.4996C19.5799 13.4999 19.6695 13.524 19.7485 13.5693C19.8276 13.6147 19.8936 13.6798 19.9399 13.7584L25.4401 23.7586C25.4819 23.8348 25.5031 23.9206 25.5016 24.0075C25.5002 24.0944 25.4761 24.1794 25.4318 24.2542C25.3875 24.329 25.3245 24.3909 25.249 24.4339C25.1735 24.4769 25.0881 24.5 25.0012 24.5Z"
                stroke="#4895EF"
              />
              <path
                d="M10.9982 8.00007C10.8657 7.99994 10.7386 7.94723 10.6449 7.85353C10.5512 7.75982 10.4985 7.63276 10.4984 7.50024V0.999837C10.4985 0.867314 10.5512 0.740257 10.6449 0.646549C10.7386 0.55284 10.8657 0.500136 10.9982 0.500002H16.9988C17.0978 0.499742 17.1947 0.528891 17.2772 0.583756C17.3597 0.638621 17.424 0.716734 17.462 0.808196C17.5001 0.899658 17.5101 1.00035 17.4909 1.09753C17.4716 1.1947 17.424 1.28397 17.354 1.35404L15.7053 3.00019L17.3514 4.64635C17.4215 4.71642 17.4691 4.80569 17.4883 4.90286C17.5076 5.00004 17.4976 5.10073 17.4595 5.19219C17.4215 5.28366 17.3571 5.36177 17.2747 5.41663C17.1922 5.4715 17.0953 5.50065 16.9962 5.50039H11.4985V7.50024C11.4984 7.63276 11.4457 7.75982 11.352 7.85353C11.2583 7.94723 11.1307 7.99994 10.9982 8.00007ZM11.498 4.49869H15.791L14.6457 3.35388C14.5521 3.26008 14.4995 3.13297 14.4995 3.00045C14.4995 2.86792 14.5521 2.74081 14.6457 2.64701L15.7915 1.50018H11.4985L11.498 4.49869Z"
                stroke="#4895EF"
              />
              <path
                d="M12.4967 15.4968C12.4845 15.4968 12.4738 15.4968 12.4606 15.4968C12.3892 15.4918 12.3198 15.4714 12.257 15.4371C12.1942 15.4027 12.1395 15.3553 12.0968 15.2979L10.9966 13.8319L9.89446 15.2974C9.85147 15.3545 9.79677 15.4018 9.73402 15.4361C9.67128 15.4704 9.60193 15.4909 9.53062 15.4963C9.45904 15.5025 9.38697 15.4927 9.31964 15.4676C9.25232 15.4425 9.19143 15.4027 9.14141 15.3512L7.46328 13.67C7.3788 13.5746 7.3339 13.4506 7.33777 13.3233C7.34163 13.196 7.39396 13.075 7.48406 12.9849C7.57417 12.8949 7.69525 12.8426 7.82258 12.8389C7.9499 12.8351 8.07387 12.8801 8.16914 12.9646L9.44131 14.2368L10.5952 12.6977C10.6438 12.6385 10.7049 12.5909 10.7741 12.5582C10.8432 12.5254 10.9188 12.5085 10.9954 12.5085C11.0719 12.5085 11.1475 12.5254 11.2167 12.5582C11.2859 12.5909 11.3469 12.6385 11.3955 12.6977L12.5494 14.2368L13.8216 12.9646C13.916 12.8749 14.0417 12.8255 14.1719 12.827C14.3021 12.8285 14.4266 12.8808 14.5189 12.9727C14.6112 13.0646 14.6639 13.189 14.6659 13.3192C14.6679 13.4494 14.6189 13.5753 14.5295 13.67L12.8493 15.3501C12.8027 15.3968 12.7473 15.4337 12.6864 15.4588C12.6254 15.4839 12.56 15.4966 12.4941 15.4963L12.4967 15.4968Z"
                stroke="#4895EF"
              />
            </svg>

            <h1>One-time Order details</h1>
          </div>
          <div className="right__subheader">
            <h1>{viewProject?.project_details ?.title}</h1>
            <p>ongoing</p>
          </div>
          <div className="right__top">
            <div className="right__top-right">
              <h1>{viewProject?.project_details ?.duration}</h1>
              <p>Order Duration</p>
            </div>
            <div className="right__top-left">
              <h1>{viewProject?.project_details ?.budget}</h1>
              <p>Order Amount</p>
            </div>
          </div>
          <div className="right__middle">
            <h1>Description</h1>
            <p>{ viewProject?.project_details?.description}</p>
          </div>
          <div className="right__bottom">
            <h1>Attached Files</h1>
            {
              viewProject?.project_details?.project_attachments?.length > 0 ? (
                <div className="attachments">
                  <h5>Attached Files</h5>
                  <div className="attachments-area">
                    {viewProject?.project_details?.project_attachments.length > 0 &&
                      viewProject?.project_details?.project_attachments.map((attach) => {
                        return (
                          <div
                            className="attachments-area--item"
                            key={attach.id}
                          >
                            <svg
                              width="28"
                              height="28"
                              viewBox="0 0 28 28"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clipPath="url(#clip0_10110_48707)">
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M3.33594 0C2.78365 0 2.33594 0.447715 2.33594 1V27C2.33594 27.5523 2.78365 28 3.33594 28H24.6693C25.2216 28 25.6693 27.5523 25.6693 27V4.66667L21.0026 0H3.33594Z"
                                  fill="#E9F0FA"
                                />
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M21.0039 0V4.66667H25.6706L21.0039 0Z"
                                  fill="#C2CDDE"
                                />
                              </g>
                              <rect
                                x="1.16797"
                                y="11.6641"
                                width="25.6667"
                                height="11.6667"
                                rx="1"
                                fill="#3E76CD"
                              />
                              <path
                                d="M6.43714 20.5V14.6818H10.1644V15.5653H7.49112V17.1449H9.90874V18.0284H7.49112V20.5H6.43714ZM12.1786 14.6818V20.5H11.1246V14.6818H12.1786ZM13.32 20.5V14.6818H14.3739V19.6165H16.9364V20.5H13.32ZM17.8512 20.5V14.6818H21.6353V15.5653H18.9052V17.1449H21.4393V18.0284H18.9052V19.6165H21.658V20.5H17.8512Z"
                                fill="white"
                              />
                              <defs>
                                <clipPath id="clip0_10110_48707">
                                  <rect
                                    width="23.3333"
                                    height="28"
                                    fill="white"
                                    transform="translate(2.33594)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                            <div className="details">
                              <h6>{getAttachFilename(attach.filename)}</h6>
                            </div>
                            <button type="button">
                              <a
                                target="_blank"
                                href={attach.filename}
                                rel="noreferrer"
                              >
                                <svg
                                  width="17"
                                  height="17"
                                  viewBox="0 0 17 17"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M2.4987 10.1348C2.86689 10.1348 3.16536 10.4332 3.16536 10.8014V13.4681C3.16536 13.6449 3.2356 13.8145 3.36063 13.9395C3.48565 14.0645 3.65522 14.1348 3.83203 14.1348H13.1654C13.3422 14.1348 13.5117 14.0645 13.6368 13.9395C13.7618 13.8145 13.832 13.6449 13.832 13.4681V10.8014C13.832 10.4332 14.1305 10.1348 14.4987 10.1348C14.8669 10.1348 15.1654 10.4332 15.1654 10.8014V13.4681C15.1654 13.9985 14.9546 14.5072 14.5796 14.8823C14.2045 15.2574 13.6958 15.4681 13.1654 15.4681H3.83203C3.3016 15.4681 2.79289 15.2574 2.41782 14.8823C2.04274 14.5072 1.83203 13.9985 1.83203 13.4681V10.8014C1.83203 10.4332 2.13051 10.1348 2.4987 10.1348Z"
                                    fill="#565656"
                                  />
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M4.69526 6.99604C4.95561 6.73569 5.37772 6.73569 5.63807 6.99604L8.5 9.85797L11.3619 6.99604C11.6223 6.73569 12.0444 6.73569 12.3047 6.99604C12.5651 7.25639 12.5651 7.6785 12.3047 7.93885L8.9714 11.2722C8.71106 11.5325 8.28894 11.5325 8.0286 11.2722L4.69526 7.93885C4.43491 7.6785 4.43491 7.25639 4.69526 6.99604Z"
                                    fill="#565656"
                                  />
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M8.4987 2.13477C8.86689 2.13477 9.16536 2.43324 9.16536 2.80143V10.8014C9.16536 11.1696 8.86689 11.4681 8.4987 11.4681C8.13051 11.4681 7.83203 11.1696 7.83203 10.8014V2.80143C7.83203 2.43324 8.13051 2.13477 8.4987 2.13477Z"
                                    fill="#565656"
                                  />
                                </svg>
                              </a>
                            </button>
                          </div>
                        );
                      })}
                  </div>
                </div>
              ) : null
            }
          </div>
        </div>
      </div>
    </div>
  );
};
export default ViewMarketplaceOrder;
