import React, { useState, Key, useEffect } from "react"
import styled from "styled-components"
import { Menu, Table } from "antd"
import type { ColumnsType } from "antd/es/table"
import { MoreOutlined } from "@ant-design/icons"
import { Dropdown, Space } from "antd"
import { RejectedDataType } from "../type/DataType"
import { isAuth } from "../../../../auth/ProtectedRoute"
import SkeletonTable, { SkeletonTableColumnsType } from "../../../../../components/SkeletonTable"
import axios from "axios"
import View from "../component/ViewProjectBtn"
import { CloseCircleOutlined } from "@ant-design/icons"
import { getUserProfileUrl } from "../../../../../util/helper"
import moment from "moment"
// import ViewProjectBtn from "../components/ViewProjectBtn"
//import DeleteProjectModal from "../components/DeleteProjectModal"

const RejectedTable = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [projectTableData, setProjectTableData] = useState<RejectedDataType[]>([])
  const [showTotal, setShowTotal] = useState<number>()
  const [record, setRecord] = useState<RejectedDataType>()

  const menu = (
    <Menu
      items={[
        {
          label: <View record={record!} />,
          key: "1",
          icon: <i className="fa fa-eye" aria-hidden="true"></i>,
        },
        // {
        //   //label: <DeleteProjectModal record={record!} />,
        //   key: "2",
        //   icon: <CloseCircleOutlined />,
        // },
        // {
        //   //label: <DeleteProjectModal record={record!} />,
        //   key: "2",
        //   icon: <i className="fa fa-trash" aria-hidden="true"></i>,
        // },
      ]}
    />
  )

  //api request for table data
  const showProjectTable = (page: number) => {
    axios({
      method: "GET",
      url: process.env.REACT_APP_HOST_API + `learning/instructors/rejected`,
      headers: {
        Authorization: `Bearer ${isAuth()}`,
      },
    })
      .then(res => {
        setLoading(false)
        if (res.data.response === true && res.status === 200) {
          setProjectTableData(
            res.data.data.data.map((projectData: RejectedDataType) => ({
              key: projectData.id,
              id: projectData.id,
              fullname: projectData.fullname,
              email: projectData.email,
              profession: projectData.profession,
              specialization: projectData.specialization,
              created_at: projectData.created_at,
              member_profile_code: getUserProfileUrl(
                projectData.fullname,
                projectData.member_profile_code
              ) as string,
              action: "",
            }))
          )
          setShowTotal(res.data.data.data.total)
        } else {
          //console.log(res.data)
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  useEffect(() => {
    showProjectTable(1)
  }, [])

  const columns: ColumnsType<RejectedDataType> = [
    {
      title: "Id",
      dataIndex: "id",

      render: text => (
        <div>
          <p className="user_name">{text}</p>
        </div>
      ),
    },
    {
      title: "Fullname",
      dataIndex: "fullname",

      render: text => (
        <div>
          <p className="user_name">{text}</p>
        </div>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",

      render: text => (
        <div>
          <p className="user_name">{text}</p>
        </div>
      ),
    },

    // {
    //   title: "Profile Url",
    //   dataIndex: "member_profile_code",

    //   render: text => (
    //     <div>
    //       <a
    //         href={`${process.env.REACT_APP_KOMINITI_URL}${text}`}
    //         target="_blank"
    //         className="user_name"
    //         rel="noreferrer"
    //       >
    //         {`${process.env.REACT_APP_KOMINITI_URL}${text
    //           .split("/")
    //           .slice(0, -1)
    //           .join("/")}/`.trim()}
    //       </a>
    //     </div>
    //   ),
    // },
    {
      title: "Profession",
      dataIndex: "profession",

      render: text => (
        <div>
          <p className="user_name">{text}</p>
        </div>
      ),
    },
    {
      title: "Request Date",
      dataIndex: "created_at",
      render: text => (
        <div>
          <p className="user_name">{moment(text).format("DD/MM/YYYY")}</p>
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      render: () => (
        <div>
          <Space wrap>
            <Dropdown.Button
              overlay={menu}
              placement="bottom"
              icon={<MoreOutlined />}
              trigger={["click"]}
            ></Dropdown.Button>
          </Space>
        </div>
      ),
    },
  ]

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys: Key[], selectedRows: RejectedDataType[]) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, "selectedRows: ", selectedRows)
    },
    // getCheckboxProps: (record: ProjectApprovedDataType) => ({
    //   disabled: record === "Disabled User", // Column configuration not to be checked
    //   name: record.buyer,
    // }),
  }
  const [selectionType, setSelectionType] = useState<"checkbox" | "radio">("checkbox")

  return (
    <SkeletonTable loading={loading} columns={columns as SkeletonTableColumnsType[]}>
      <Table
        rowSelection={{
          type: selectionType,
          ...rowSelection,
        }}
        columns={columns}
        dataSource={projectTableData}
        pagination={{
          showSizeChanger: false,
          pageSize: 20,
          total: showTotal,
          onChange: page => {
            showProjectTable
          },
          showTotal: (showTotal, range) => `${range[0]}-${range[1]} of ${showTotal} items`,
        }}
        onRow={record => {
          return {
            onClick: () => {
              setRecord(record)
            },
          }
        }}
      />
    </SkeletonTable>
  )
}
export default RejectedTable
