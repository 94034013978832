import React from "react";
import "../../../../styles/modals.scss";
import {
  Formik,
  Form,
  Field,
  FieldArray,
  FormikHelpers,
  ErrorMessage,
} from "formik";
import * as Yup from "yup";
import axios from "axios";
import { isAuth } from "../../../auth/ProtectedRoute";
import { InterestValues } from "../types/DataType";
import { ModalType } from "../../../../components/modal/ModalType";
import swal from "sweetalert";
import { ActivityLogValues } from "../../ActivityLog/types/DataType";
import { Input } from "antd";

const AddInterestModal = (props: ModalType) => {
  const initialValues: InterestValues = {
    interests: [""],
  };

  return (
    <>
      {props.isOpen && (
        <div className="modal-overlay">
          <div className="modal-box">
            <div className="modal-box--wrapper">
              <div className="heading">
                <h1>Add Interest</h1>
                <div onClick={props.toggle} style={{ cursor: "pointer" }}>
                  <svg
                    width="35"
                    height="32"
                    viewBox="0 0 35 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18.5995 16.0001L23.2722 11.3273C23.5763 11.0233 23.5763 10.5319 23.2722 10.228C22.9682 9.92401 22.4768 9.92401 22.1729 10.228L17.5001 14.9008L12.8274 10.228C12.5233 9.92401 12.032 9.92401 11.7281 10.228C11.424 10.5319 11.424 11.0233 11.7281 11.3273L16.4007 16.0001L11.7281 20.6729C11.424 20.9768 11.424 21.4683 11.7281 21.7722C11.8795 21.9238 12.0787 22 12.2777 22C12.4768 22 12.6758 21.9238 12.8274 21.7722L17.5001 17.0994L22.1729 21.7722C22.3245 21.9238 22.5235 22 22.7225 22C22.9216 22 23.1206 21.9238 23.2722 21.7722C23.5763 21.4683 23.5763 20.9768 23.2722 20.6729L18.5995 16.0001Z"
                      fill="black"
                      stroke="black"
                    />
                  </svg>
                </div>
              </div>
              <div className="form-wrap">
                <Formik
                  initialValues={initialValues}
                  validationSchema={Yup.object().shape({
                    interests: Yup.array().of(
                      Yup.string().required("This field is required")
                    ),
                  })}
                  onSubmit={(
                    values: InterestValues,
                    { setSubmitting }: FormikHelpers<InterestValues>
                  ) => {
                    const formData = new FormData();
                    values.interests.forEach((interest, index) => {
                      formData.append(`interest[${index}]`, interest);
                    });
                    axios({
                      method: "POST",
                      url: process.env.REACT_APP_HOST_API + `interest`,
                      headers: {
                        Authorization: `Bearer ${isAuth()}`,
                        Accept: "application/json",
                        "Content-Type": "application/json",
                      },
                      data: formData,
                    })
                      .then((res) => {
                        setSubmitting(false);
                        if (res.data.response === true) {
                          let activityLogItem;
                          if (typeof window !== "undefined") {
                            if (sessionStorage.getItem("activityDetail")) {
                              activityLogItem = JSON.parse(
                                sessionStorage.getItem("activityDetail")!
                              );
                            }
                          }

                          const activityDetail: ActivityLogValues = {
                            full_name: activityLogItem.full_name,
                            role: activityLogItem.role,
                            ip_address: activityLogItem.ip_address,
                            browser: activityLogItem.browser,
                            events: `Added Interest: ${values.interests}`,
                            module: "Other Modules",
                            location: activityLogItem.location,
                            email: activityLogItem.email,
                          };
                          axios({
                            method: "POST",
                            url:
                              process.env.REACT_APP_HOST_API +
                              "manage-activity/user",
                            headers: {
                              Authorization: `Bearer ${isAuth()}`,
                            },
                            data: activityDetail,
                          }).then(() => {
                            history.go(0);
                          });
                          swal({
                            title: "Success!",
                            text: "Interest created successfully!",
                            icon: "success",
                          });
                        } else if (
                          res.data.response === false &&
                          res.data.status === 401
                        ) {
                          swal({
                            title: "Error!",
                            text: res.data.message,
                            icon: "error",
                          });
                        } else if (
                          res.data.response === false &&
                          res.data.status === 403
                        ) {
                          swal({
                            title: "Error!",
                            text: res.data.message,
                            icon: "error",
                          });
                        } else if (
                          res.data.response === false &&
                          res.data.status === 405
                        ) {
                          swal({
                            title: "Error!",
                            text: res.data.message,
                            icon: "error",
                          });
                        } else if (
                          res.data.response === false &&
                          res.data.status === 406
                        ) {
                          swal({
                            title: "Error!",
                            text: res.data.message,
                            icon: "error",
                          });
                        } else if (
                          res.data.response === false &&
                          res.data.status === 409
                        ) {
                          swal({
                            title: "Error!",
                            text: res.data.message,
                            icon: "error",
                          });
                        } else if (
                          res.data.response === false &&
                          res.data.status === 500
                        ) {
                          swal({
                            title: "Error!",
                            text: "Something went wrong. Please try again later!",
                            icon: "error",
                          });
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                        swal({
                          title: "Error!",
                          text: "Something went wrong. Please try again later!",
                          icon: "error",
                        });
                      });
                  }}
                >
                  {({ values, isSubmitting }) => (
                    <>
                      <Form noValidate autoComplete="off">
                        <div className="form-wrap--submodule">
                          <div className="form-wrap--submodule_cell_i">
                            <label htmlFor="interests">
                              Interests (5 interests at a time only. Use
                              &quot;Import CSV&quot; button for more.)
                            </label>
                            <FieldArray name="interests">
                              {({ remove, push }) => (
                                <>
                                  {values.interests.map((interest, index) => {
                                    return (
                                      <>
                                        <div
                                          key={index}
                                          style={{
                                            display: "flex",
                                            gap: "12px",
                                            margin: "8px 0px",
                                          }}
                                        >
                                          <Field
                                            name={`interests[${index}]`}
                                            as={Input}
                                          />
                                          <button
                                            type="button"
                                            onClick={() => remove(index)}
                                            style={{
                                              padding: "0px",
                                              background: "none",
                                            }}
                                          >
                                            <svg
                                              width="35"
                                              height="32"
                                              viewBox="0 0 35 32"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M18.5995 16.0001L23.2722 11.3273C23.5763 11.0233 23.5763 10.5319 23.2722 10.228C22.9682 9.92401 22.4768 9.92401 22.1729 10.228L17.5001 14.9008L12.8274 10.228C12.5233 9.92401 12.032 9.92401 11.7281 10.228C11.424 10.5319 11.424 11.0233 11.7281 11.3273L16.4007 16.0001L11.7281 20.6729C11.424 20.9768 11.424 21.4683 11.7281 21.7722C11.8795 21.9238 12.0787 22 12.2777 22C12.4768 22 12.6758 21.9238 12.8274 21.7722L17.5001 17.0994L22.1729 21.7722C22.3245 21.9238 22.5235 22 22.7225 22C22.9216 22 23.1206 21.9238 23.2722 21.7722C23.5763 21.4683 23.5763 20.9768 23.2722 20.6729L18.5995 16.0001Z"
                                                fill="black"
                                                stroke="black"
                                              />
                                            </svg>
                                          </button>
                                        </div>
                                        <ErrorMessage
                                          name={`interests[${index}]`}
                                        >
                                          {(msg) => (
                                            <div style={{ color: "red" }}>
                                              {msg}
                                            </div>
                                          )}
                                        </ErrorMessage>
                                      </>
                                    );
                                  })}
                                  {values.interests.length < 5 && (
                                    <button
                                      type="button"
                                      onClick={() => push("")}
                                      style={{ marginTop: "8px" }}
                                    >
                                      Add Interest
                                    </button>
                                  )}
                                </>
                              )}
                            </FieldArray>
                          </div>
                        </div>
                        <div className="form-wrap--bottom">
                          <div className="btns">
                            <div className="btns--wrap">
                              <button className="reset" type="reset">
                                Reset
                              </button>
                              <button type="submit" className="save">
                                {isSubmitting ? "Submitting" : "Save"}
                              </button>
                            </div>
                          </div>
                        </div>
                      </Form>
                    </>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AddInterestModal;
