import React from "react"
import {
  Card,
  CardContent,
  Typography,
  Tabs,
  Tab,
  Box,
  LinearProgress,
  styled,
  Theme,
} from "@mui/material"
import { LinearProgressProps } from "@mui/material/LinearProgress"
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward"

// Custom type for the styled progress bar
interface StyledLinearProgressProps extends LinearProgressProps {
  customcolor?: string
}

// Styled components with proper typing
const StyledLinearProgress = styled(LinearProgress, {
  shouldForwardProp: prop => prop !== "customcolor",
})<StyledLinearProgressProps>(({ theme, customcolor }) => ({
  height: 8,
  borderRadius: 4,
  backgroundColor: theme.palette.grey[100],
  "& .MuiLinearProgress-bar": {
    borderRadius: 4,
    backgroundColor: customcolor,
  },
}))

// Interface for data items
interface DataItem {
  country: string
  count: number
  flag: string
  color: string
}

const GeoDistribution: React.FC = () => {
  const [tabValue, setTabValue] = React.useState<number>(0)

  const data: DataItem[] = [
    {
      country: "United States",
      count: 1651000,
      flag: "🇺🇸",
      color: "#ff6b6b",
    },
    {
      country: "Australia",
      count: 1221498,
      flag: "🇦🇺",
      color: "#4dabf7",
    },
    {
      country: "India",
      count: 1119209,
      flag: "🇮🇳",
      color: "#212529",
    },
  ]

  const totalCount = 4532234

  const handleTabChange = (_event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue)
  }

  return (
    <Card sx={{ maxWidth: 800, p: 3 }}>
      <Box
        sx={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start", mb: 3 }}
      >
        <Box>
          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 1 }}>
            Geo
          </Typography>
          <Box sx={{ display: "flex", alignItems: "baseline", gap: 1 }}>
            <Typography variant="h4" sx={{ fontWeight: "bold" }}>
              {totalCount.toLocaleString()}
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center", color: "success.main" }}>
              <ArrowUpwardIcon sx={{ fontSize: 16 }} />
              <Typography variant="body2">12%</Typography>
            </Box>
            <Typography variant="body2" color="text.secondary">
              compared to last year
            </Typography>
          </Box>
        </Box>

        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          sx={{
            minHeight: 36,
            "& .MuiTab-root": {
              minHeight: 36,
              textTransform: "none",
              px: 2,
            },
          }}
        >
          <Tab label="Instructors" />
          <Tab label="Students" />
        </Tabs>
      </Box>

      <CardContent sx={{ pt: 0 }}>
        {data.map(item => (
          <Box key={item.country} sx={{ mb: 2 }}>
            <Box sx={{ display: "flex", alignItems: "center", gap: 2, mb: 1 }}>
              <Typography sx={{ fontSize: 24, width: 32 }}>{item.flag}</Typography>
              <Box sx={{ flex: 1 }}>
                <Box sx={{ display: "flex", justifyContent: "space-between", mb: 0.5 }}>
                  <Typography variant="body1" sx={{ fontWeight: 500 }}>
                    {item.country}
                  </Typography>
                  <Typography variant="body1">{item.count.toLocaleString()}</Typography>
                </Box>
                <StyledLinearProgress
                  variant="determinate"
                  value={(item.count / totalCount) * 100}
                  customcolor={item.color}
                />
              </Box>
            </Box>
          </Box>
        ))}
      </CardContent>
    </Card>
  )
}

export default GeoDistribution
