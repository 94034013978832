import React, { Fragment, FC } from "react";
import { Link } from "react-router-dom";
const AddUser: FC = () => {
  return (
    <Fragment>
      <div className="user">
        <div className="user__edit">
          <div className="user__edit--heading">
            <h1>Add User</h1>
            <Link to="/">
              <button>
                <svg
                  width="22"
                  height="20"
                  viewBox="0 0 22 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7 2L2 7L7 12"
                    stroke="white"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M2 7H10C15.523 7 20 11.477 20 17V18"
                    stroke="white"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                Back
              </button>
            </Link>
          </div>
          <div className="user__edit--adduser">
            <form id="myForm">
              <div className="container">
                <div className="left">
                  <label htmlFor="firstName">Full Name*</label>
                  <input
                    type="text"
                    placeholder="Enter your first name"
                    name="firstName"
                  />
                </div>
              </div>
              <div className="container">
                <div className="left">
                  <label htmlFor="email">Email*</label>
                  <input
                    type="email"
                    placeholder="Enter your email"
                    name="email"
                  />
                </div>
                <div className="right">
                  <label htmlFor="number">Phone*</label>
                  <input
                    type="number"
                    placeholder="Enter your Password"
                    name="password"
                  />
                </div>
              </div>
              <div className="container">
                <div className="left">
                  <label htmlFor="address">Address*</label>
                  <input
                    type="text"
                    placeholder="Enter your Address"
                    name="address"
                  />
                </div>
                <div className="right">
                  <label htmlFor="address">Address2*</label>
                  <input
                    type="text"
                    placeholder="Enter your Address"
                    name="address"
                  />
                </div>
              </div>
              <div className="container">
                <div className="left">
                  <label htmlFor="country">Country*</label>
                  <input type="text" name="country" />
                </div>

                <div className="right">
                  <label htmlFor="state">State</label>
                  <input type="text" name="state" />
                </div>
              </div>
              <div className="container">
                <div className="left">
                  <label htmlFor="city">City</label>
                  <input type="text" name="city" />
                </div>
                <div className="right">
                  <label htmlFor="zipcode">Zip Code</label>
                  <input type="number" name="zipcode" />
                </div>
              </div>
              <div className="containers">
                <div className="containers_btn">
                  <button className="reset" type="reset">
                    Reset
                  </button>
                  <button className="save">Save</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default AddUser;
