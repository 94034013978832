import React, { Fragment, FC, useEffect, useState } from "react";
import { Button, Input, Modal, Space } from "antd";
import "../../../../styles/modals.scss";
import axios from "axios";
import { isAuth } from "../../../auth/ProtectedRoute";
import {
  Formik,
  Form,
  Field,
  ErrorMessage,
  FormikHelpers,
  FieldArray,
} from "formik";
import { CategoryViewValues, CategoryEditValues } from "../types/DataType";
import * as Yup from "yup";
import swal from "sweetalert";
import { ActivityLogValues } from "../../ActivityLog/types/DataType";
import { CircularProgress } from "@mui/material";

const EditCategoryModal: FC<{
  record: { id: number | null; name: string };
}> = ({ record }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [categoryImage, setCategoryImage] = useState<string>("");
  const [category, setCategory] = useState<CategoryViewValues>({
    id: null,
    name: "",
    cat_image: "",
    sub_categories: [
      {
        id: null,
        other_category_id: null,
        name: "",
        image: "",
      },
    ],
  });

  const showModal = () => {
    setOpen(true);
  };

  const hideModal = () => {
    setOpen(false);
  };

  useEffect(() => {
    axios({
      method: "GET",
      url: process.env.REACT_APP_HOST_API + `category/${record.id}`,
      headers: {
        Authorization: `Bearer ${isAuth()}`,
      },
    })
      .then((res) => {
        if (res.data.response === true) {
          setCategory(res.data.data);
        } else if (res.data.response === false && res.data.status === 401) {
          swal({
            title: "Error!",
            text: res.data.message,
            icon: "error",
          });
        } else if (res.data.response === false && res.data.status === 403) {
          swal({
            title: "Error!",
            text: res.data.message,
            icon: "error",
          });
        } else if (res.data.response === false && res.data.status === 405) {
          swal({
            title: "Error!",
            text: res.data.message,
            icon: "error",
          });
        } else if (res.data.response === false && res.data.status === 406) {
          swal({
            title: "Error!",
            text: res.data.message,
            icon: "error",
          });
        } else if (res.data.response === false && res.data.status === 409) {
          swal({
            title: "Error!",
            text: res.data.message,
            icon: "error",
          });
        } else if (res.data.response === false && res.data.status === 500) {
          swal({
            title: "Error!",
            text: "Something went wrong. Please try again later!",
            icon: "error",
          });
        }
      })
      .catch((err) => {
        console.error(err);
        swal({
          title: "Error!",
          text: "Something went wrong. Please try again later!",
          icon: "error",
        });
      });
  }, []);

  const initialValues: CategoryEditValues = {
    name: category?.name,
    cat_image: category?.cat_image,
    sub_categories: category?.sub_categories,
  };

  // const fileRef: any = useRef(null);

  return (
    <Fragment>
      <Space>
        <Button className="__btn" onClick={showModal}>
          Edit Category
        </Button>
      </Space>
      <Modal closable={false} keyboard={true} open={open} footer={null}>
        <div className="clicks-mod">
          <div className="clicks-mod--right">
            <div className="modal-overlay-mod">
              <div className="modal-box-mod-cat">
                <div className="modal-box-mod--wrapper">
                  <Formik
                    initialValues={initialValues}
                    enableReinitialize={true}
                    validationSchema={Yup.object().shape({
                      name: Yup.string().required("This field is required"),
                      cat_image: Yup.string().required(
                        "This field is required"
                      ),
                      sub_categories: Yup.array().of(
                        Yup.object().shape({
                          name: Yup.string().required("This field is required"),
                        })
                      ),
                    })}
                    onSubmit={(
                      values: CategoryEditValues,
                      { setSubmitting }: FormikHelpers<CategoryEditValues>
                    ) => {
                      const formData = new FormData();
                      formData.append("name", values.name);
                      formData.append("cat_image", values.cat_image);
                      formData.append("_method", "patch");
                      values.sub_categories.forEach((sub, index) => {
                        formData.append(`sub_category[${index}]`, sub.name);
                        formData.append(`sub_cat_image[${index}]`, "index");
                      });
                      axios({
                        method: "POST",
                        url:
                          process.env.REACT_APP_HOST_API +
                          `category/${record.id}`,
                        headers: {
                          Authorization: `Bearer ${isAuth()}`,
                        },
                        data: formData,
                      })
                        .then((res) => {
                          setSubmitting(false);
                          if (res.data.response === true) {
                            let activityLogItem;
                            if (typeof window !== "undefined") {
                              if (sessionStorage.getItem("activityDetail")) {
                                activityLogItem = JSON.parse(
                                  sessionStorage.getItem("activityDetail")!
                                );
                              }
                            }
                            const activityDetail: ActivityLogValues = {
                              full_name: activityLogItem.full_name,
                              role: activityLogItem.role,
                              ip_address: activityLogItem.ip_address,
                              browser: activityLogItem.browser,
                              events: `Edited Category: ${values.name}`,
                              module: "Other Modules",
                              location: activityLogItem.location,
                              email: activityLogItem.email,
                            };
                            axios({
                              method: "POST",
                              url:
                                process.env.REACT_APP_HOST_API +
                                "manage-activity/user",
                              headers: {
                                Authorization: `Bearer ${isAuth()}`,
                              },
                              data: activityDetail,
                            }).then(() => {
                              history.go(0);
                            });
                            swal({
                              title: "Success!",
                              text: "Category edited successfully!",
                              icon: "success",
                            });
                          } else if (
                            res.data.response === false &&
                            res.data.status === 401
                          ) {
                            swal({
                              title: "Error!",
                              text: res.data.message,
                              icon: "error",
                            });
                          } else if (
                            res.data.response === false &&
                            res.data.status === 403
                          ) {
                            swal({
                              title: "Error!",
                              text: res.data.message,
                              icon: "error",
                            });
                          } else if (
                            res.data.response === false &&
                            res.data.status === 405
                          ) {
                            swal({
                              title: "Error!",
                              text: res.data.message,
                              icon: "error",
                            });
                          } else if (
                            res.data.response === false &&
                            res.data.status === 406
                          ) {
                            swal({
                              title: "Error!",
                              text: res.data.message,
                              icon: "error",
                            });
                          } else if (
                            res.data.response === false &&
                            res.data.status === 409
                          ) {
                            swal({
                              title: "Error!",
                              text: res.data.message,
                              icon: "error",
                            });
                          } else if (
                            res.data.response === false &&
                            res.data.status === 500
                          ) {
                            swal({
                              title: "Error!",
                              text: "Something went wrong. Please try again later!",
                              icon: "error",
                            });
                          }
                        })
                        .catch((err) => {
                          console.error(err);
                          swal({
                            title: "Error!",
                            text: "Something went wrong. Please try again later!",
                            icon: "error",
                          });
                        });
                    }}
                  >
                    {({ values, setFieldValue, isSubmitting }) => (
                      <>
                        <div className="heading">
                          <h1>Edit Category</h1>
                          <div
                            onClick={() => {
                              setCategoryImage("");
                              setFieldValue("subCategories", []);
                              hideModal();
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <svg
                              width="35"
                              height="32"
                              viewBox="0 0 35 32"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M18.5995 16.0001L23.2722 11.3273C23.5763 11.0233 23.5763 10.5319 23.2722 10.228C22.9682 9.92401 22.4768 9.92401 22.1729 10.228L17.5001 14.9008L12.8274 10.228C12.5233 9.92401 12.032 9.92401 11.7281 10.228C11.424 10.5319 11.424 11.0233 11.7281 11.3273L16.4007 16.0001L11.7281 20.6729C11.424 20.9768 11.424 21.4683 11.7281 21.7722C11.8795 21.9238 12.0787 22 12.2777 22C12.4768 22 12.6758 21.9238 12.8274 21.7722L17.5001 17.0994L22.1729 21.7722C22.3245 21.9238 22.5235 22 22.7225 22C22.9216 22 23.1206 21.9238 23.2722 21.7722C23.5763 21.4683 23.5763 20.9768 23.2722 20.6729L18.5995 16.0001Z"
                                fill="black"
                                stroke="black"
                              />
                            </svg>
                          </div>
                        </div>
                        <div className="form-wrap">
                          <Form noValidate autoComplete="off">
                            <div className="form-wrap--submodule">
                              <div className="form-wrap--submodule_cell">
                                <label htmlFor="name">Category</label>
                                <Field id="name" name="name" type="text" />
                                <ErrorMessage name="name">
                                  {(msg) => (
                                    <div style={{ color: "red" }}>{msg}</div>
                                  )}
                                </ErrorMessage>
                              </div>
                              <div className="form-wrap--submodule_cell">
                                <label htmlFor="cat_image">
                                  Upload Category Image
                                  <Field
                                    id="cat_image"
                                    name="cat_image"
                                    type="file"
                                    accept="image/png, image/jpg, image/jpeg"
                                    value={""}
                                    style={{ display: "none" }}
                                    onChange={(e: any) => {
                                      //error handling & validation for media uploads request to server
                                      const file = e.currentTarget.files[0];
                                      const fileSize = Math.round(
                                        file.size / 1024
                                      );
                                      if (fileSize > 5120) {
                                        swal({
                                          title: "Error!",
                                          icon: "error",
                                          text: "Selected image must be less than 5MB",
                                        });
                                        return;
                                      } else {
                                        //media upload request to server
                                        const formdata = new FormData();
                                        formdata.append(`files[0]`, file);
                                        formdata.append(
                                          "env",
                                          `${process.env.REACT_APP_ENV}`
                                        );
                                        setLoading(true);
                                        axios({
                                          method: "POST",
                                          url:
                                            process.env.REACT_APP_HOST_API +
                                            `uploads/file`,
                                          data: formdata,
                                        })
                                          .then((res) => {
                                            console.log(res);
                                            setFieldValue(
                                              "cat_image",
                                              res.data?.data[0]?.filepath
                                            );
                                            setCategoryImage(
                                              res.data?.data[0]?.original_name
                                            );
                                          })
                                          .then(() => {
                                            setLoading(false);
                                          })
                                          .catch((error) => {
                                            setLoading(false);
                                            console.error(error);
                                            swal({
                                              title: "Error!",
                                              text: "Something went wrong. Please try again later!",
                                              icon: "error",
                                            });
                                          });
                                      }
                                    }}
                                  />
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <input
                                      type="text"
                                      id="imageInput"
                                      value={
                                        !categoryImage
                                          ? values.cat_image
                                          : categoryImage
                                      }
                                      style={{ width: "84%" }}
                                    />
                                    <span id="imageButton">Select</span>
                                  </div>
                                </label>
                                <ErrorMessage name="cat_image">
                                  {(msg) => (
                                    <div style={{ color: "red" }}>{msg}</div>
                                  )}
                                </ErrorMessage>
                                {loading && (
                                  <CircularProgress
                                    sx={{ color: "gray", mt: 2 }}
                                    size={12}
                                  />
                                )}
                              </div>
                            </div>
                            <div className="form-wrap--submodule">
                              <div className="form-wrap--submodule_cell_i">
                                <FieldArray name="sub_categories">
                                  {({ remove, push }) => (
                                    <>
                                      {values.sub_categories.map(
                                        (sub, index) => {
                                          return (
                                            <div
                                              key={index}
                                              style={{
                                                display: "flex",
                                                gap: "20px",
                                              }}
                                            >
                                              <div
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "column",
                                                  width: "100%",
                                                }}
                                              >
                                                <label htmlFor="name">
                                                  Sub-Category {index + 1}
                                                </label>
                                                <Field
                                                  id={`sub_ategories[${index}].name`}
                                                  name={`sub_categories[${index}].name`}
                                                  value={sub.name}
                                                  as={Input}
                                                  onChange={(e: any) => {
                                                    setFieldValue(
                                                      `sub_categories[${index}].name`,
                                                      e.target.value
                                                    );
                                                  }}
                                                />
                                                <ErrorMessage
                                                  name={`sub_categories[${index}].name`}
                                                >
                                                  {(msg) => (
                                                    <div
                                                      style={{ color: "red" }}
                                                    >
                                                      {msg}
                                                    </div>
                                                  )}
                                                </ErrorMessage>
                                              </div>
                                              <button
                                                type="button"
                                                onClick={() => remove(index)}
                                                style={{
                                                  padding: "0px",
                                                  background: "none",
                                                  marginTop: "15px",
                                                }}
                                              >
                                                <svg
                                                  width="35"
                                                  height="32"
                                                  viewBox="0 0 35 32"
                                                  fill="none"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                >
                                                  <path
                                                    d="M18.5995 16.0001L23.2722 11.3273C23.5763 11.0233 23.5763 10.5319 23.2722 10.228C22.9682 9.92401 22.4768 9.92401 22.1729 10.228L17.5001 14.9008L12.8274 10.228C12.5233 9.92401 12.032 9.92401 11.7281 10.228C11.424 10.5319 11.424 11.0233 11.7281 11.3273L16.4007 16.0001L11.7281 20.6729C11.424 20.9768 11.424 21.4683 11.7281 21.7722C11.8795 21.9238 12.0787 22 12.2777 22C12.4768 22 12.6758 21.9238 12.8274 21.7722L17.5001 17.0994L22.1729 21.7722C22.3245 21.9238 22.5235 22 22.7225 22C22.9216 22 23.1206 21.9238 23.2722 21.7722C23.5763 21.4683 23.5763 20.9768 23.2722 20.6729L18.5995 16.0001Z"
                                                    fill="black"
                                                    stroke="black"
                                                  />
                                                </svg>
                                              </button>
                                            </div>
                                          );
                                        }
                                      )}
                                      {/* {values.interests.length < 5 && ( */}
                                      <button
                                        type="button"
                                        onClick={() => push("")}
                                        style={{ marginTop: "8px" }}
                                      >
                                        Add Sub-category
                                      </button>
                                      {/* )} */}
                                    </>
                                  )}
                                </FieldArray>
                              </div>
                            </div>
                            <div className="form-wrap--bottom">
                              <div className="btns">
                                <div className="btns--wrap">
                                  <button
                                    className="reset"
                                    type="reset"
                                    onClick={() => {
                                      setCategoryImage("");
                                      setFieldValue("subCategories", []);
                                    }}
                                  >
                                    Reset
                                  </button>
                                  <button type="submit" className="save">
                                    {isSubmitting ? "Submitting" : "Save"}
                                  </button>
                                </div>
                              </div>
                            </div>
                            {/* <pre>
                              {JSON.stringify({ values, errors }, null, 4)}
                            </pre> */}
                          </Form>
                        </div>
                      </>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </Fragment>
  );
};

export default EditCategoryModal;
