import React, { FC, useEffect, useState } from "react"
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom"
import reply from "../../../../assets/img-reply.png"
import "../../../../styles/chat.scss"
import { File, SupportMessageDataType } from "../types/DataType"
import axios from "axios"
import swal from "sweetalert"
import { isAuth } from "../../../auth/ProtectedRoute"
import moment from "moment"
import parse from "html-react-parser"
import { TextField } from "@mui/material"

const UserReply = () => {
  const [searchParams] = useSearchParams()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const ref = searchParams.get("ref") as string

  const [viewReply, setViewReply] = useState<SupportMessageDataType[]>([])
  const [sentMessage, setSentMessage] = useState<string>("")
  const [attachment, setAttachment] = useState({})
  const [revalidate, setRevalidate] = useState<boolean>(false)

  const [uploading, setUploading] = useState<boolean>(false)
  const [uploadedFiles, setUploadedFiles] = useState<string>("")
  const [uploadDisplay, setUploadDisplay] = useState<string>("")
  const [loader, setLoader] = useState<boolean>(false)
  const navigate = useNavigate()

  useEffect(() => {
    const getConversation = async () => {
      await axios({
        method: "GET",
        url:
          process.env.REACT_APP_HOST_API + `manage-support-messages/conversations/messages/${ref}`,
        headers: {
          Authorization: `Bearer ${isAuth()}`,
        },
      })
        .then(res => {
          console.log(res)
          setViewReply(res?.data?.data)
          setIsLoading(false)
          setRevalidate(false)
        })
        .catch(err => {
          console.error(err)
          swal({
            icon: "error",
            text: "Something went wrong. Please try again later!",
          })
          setIsLoading(false)
          setRevalidate(false)
        })
    }

    getConversation()
  }, [ref, revalidate])

  const handleChangeBody = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSentMessage(event.target.value)
  }

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    const formdata = new FormData()
    formdata.append("body", sentMessage)
    formdata.append("conversation_id", ref)

    console.log("att", attachment)

    // let attachmentUrl = "";
    // if (attachment) {
    //   const formData = new FormData();
    //   formData.append("attachment", attachmentUrl);

    //   const uploadResponse = await axios({
    //     method: "POST",
    //     url: process.env.REACT_APP_HOST_API + `uploads/file`,
    //     headers: {
    //       "Content-Type": "multipart/form-data",
    //       Authorization: `Bearer ${isAuth()}`,
    //     },
    //   });
    //   if (uploadResponse.data.success) {
    //     attachmentUrl = uploadResponse.data.fileUrl;
    //   } else {
    //     swal({
    //       icon: "error",
    //       text: "Failed to upload attachment. Please try again later.",
    //     });
    //     return;
    //   }
    // }

    await axios({
      method: "POST",
      url: process.env.REACT_APP_HOST_API + `manage-support-messages/reply/admin`,
      headers: {
        Authorization: `Bearer ${isAuth()}`,
      },
      data: formdata,
    })
      .then(res => {
        if (res && res.data.response === true) {
          setRevalidate(true)
          setSentMessage("")
        }
      })
      .catch(err => {
        console.log(err)
        setSentMessage("")
      })
  }

  if (isLoading) return <p>Loading ....</p>

  return (
    <div className="user">
      <div className="user__edit">
        <div className="user__edit--heading">
          <h1>Response</h1>
          <button onClick={() => navigate(-1)}>
            <svg
              width="22"
              height="20"
              viewBox="0 0 22 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7 2L2 7L7 12"
                stroke="white"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M2 7H10C15.523 7 20 11.477 20 17V18"
                stroke="white"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            Back
          </button>
        </div>
      </div>

      <div className="chat_body">
        {/*This shows messages sent from you*/}
        <div className="chat_body_container">
          <div className="chat_body_wrapper">
            <div className="sender">
              <h1>{viewReply && viewReply?.length > 0 ? viewReply[0].title : ""}</h1>
              <div className="sender_message">
                {viewReply
                  ?.sort((a, b) => {
                    const dateA = moment(a.created_at)
                    const dateB = moment(b.created_at)
                    return dateA.diff(dateB)
                  })
                  .map((message, index) => (
                    <div
                      key={index}
                      className={`sender_message_item ${
                        message.from_admin ? "sender_message_item_admin" : ""
                      }`}
                    >
                      <div className="sender_wrap">
                        <h5 className="sender_sender">{message.full_name}</h5>
                      </div>
                      <div className="user__addpurpose">
                        <p>{parse(message.body)}</p>
                      </div>
                      {message.attachment && (
                        <div className="user__addpurpose">
                          <h1>Attachment</h1>
                          <p>{/* {message.attachment} */}</p>
                        </div>
                      )}
                      <div>
                        <span>{new Date(message.created_at).toLocaleString()}</span>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="user__addpurpose" style={{ marginTop: "20px" }}>
        <form className="search-wrapper" onSubmit={handleSubmit}>
          <TextField
            label="Enter your message"
            fullWidth
            multiline
            rows={1}
            value={sentMessage}
            onChange={e => setSentMessage(e.target.value)}
          />
          {/* <input
            type="file"
            accept="image/*,application/pdf"
            // onChange={handleAttachmentChange}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const file = event.currentTarget.files![0];

              const formdata = new FormData();
              formdata.append(`files[0]`, file);
              setUploading(true);
              axios({
                method: "POST",
                url: process.env.REACT_APP_HOST_API + `uploads/file`,
                data: formdata,
              })
                .then((res) => {
                  console.log("upd", res);
                  setUploadedFiles(res?.data?.data[0]?.filepath);
                  setUploadDisplay(res?.data?.data[0]?.original_name);
                })
                .then(() => {
                  setUploading(false);
                })
                .catch((error) => {
                  setUploading(false);
                  console.log(error);
                  swal({
                    title: "Error!",
                    text: "Something went wrong. Please try again later!",
                    icon: "error",
                  });
                });
            }}
          /> */}
          <div>
            <button type="submit">Send </button>
          </div>
          {/* <div  style={{width: "100px", height:  "10px", border: "1px"}}>{uploadDisplay}</div> */}
        </form>
      </div>
    </div>
  )
}
export default UserReply
