import React, { Fragment, useEffect, useState, FC } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import Logo from "../../assets/logo.png";
import "../../styles/reg.scss";
import axios from "axios";
import { Formik, Form, Field, FormikHelpers, ErrorMessage } from "formik";
import * as Yup from "yup";
import { RegistrationValues } from "./types/AuthValues";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import swal from "sweetalert";

const Registration: FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const code = searchParams.get("code");
  const ref = searchParams.get("ref");
  const [showHidePassword, setShowHidePassword] = useState<boolean>(false);
  const [showHidePassword2, setShowHidePassword2] = useState<boolean>(false);
  const [userEmail, setUserEmail] = useState<string>("");

  useEffect(() => {
    axios({
      method: "GET",
      url:
        process.env.REACT_APP_HOST_API +
        `verify-invite?code=${code}&ref=${ref}`,
    })
      .then((res) => {
        setUserEmail(res.data.email);
      })
      .catch((err) => {
        console.error(err);
        swal({
          title: "Error!",
          text: "Something went wrong. Please try again later!",
          icon: "error",
        });
      });
  }, []);

  const initialValues: RegistrationValues = {
    fullName: "",
    email: userEmail,
    phone: "",
    password: "",
    confirmPassword: "",
    country: "",
  };
  return (
    <Fragment>
      <div>
        <div className="login">
          <div className="login__wrapper">
            <div className="login__wrapper-boxs">
              <Formik
                initialValues={initialValues}
                validationSchema={Yup.object().shape({
                  fullName: Yup.string().required("This field is required"),
                  email: Yup.string().email(),
                  phone: Yup.string()
                    .min(10, "Phone number is too short")
                    .required("This field is required"),
                  password: Yup.string()
                    .required("This field is required")
                    .matches(
                      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/,
                      "Must contain at least one uppercase, one lowercase, one number and one special character"
                    )
                    .min(
                      12,
                      "Password is too short - should be 12 characters minimum"
                    ),
                  confirmPassword: Yup.string()
                    .required("This field is required")
                    .matches(
                      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/,
                      "Must contain at least one uppercase, one lowercase, one number and one special character"
                    )
                    .min(
                      12,
                      "Password is too short - should be 12 characters minimum"
                    )
                    .oneOf([Yup.ref("password"), null], "Passwords must match"),
                  country: Yup.string().required("This field is required"),
                })}
                onSubmit={(
                  values: RegistrationValues,
                  { setSubmitting }: FormikHelpers<RegistrationValues>
                ) => {
                  const formDetails = {
                    fullName: values.fullName,
                    email: userEmail,
                    phone1: values.phone,
                    password: values.password,
                    password_confirmation: values.confirmPassword,
                    country: values.country,
                  };
                  axios
                    .post(
                      process.env.REACT_APP_HOST_API +
                        `register/email/verify_email?code=${code}&ref=${ref}`,
                      formDetails
                    )
                    .then((res) => {
                      setSubmitting(false);
                      if (res.data.response === true) {
                        swal({
                          title: "Success!",
                          text: res.data.message,
                          icon: "success",
                        });
                        navigate("/");
                      } else if (
                        res.data.response === false &&
                        res.data.status === 401
                      ) {
                        swal({
                          title: "Error!",
                          text: res.data.message,
                          icon: "error",
                        });
                      } else if (
                        res.data.response === false &&
                        res.data.status === 404
                      ) {
                        swal({
                          title: "Error!",
                          text: res.data.message,
                          icon: "error",
                        });
                      } else if (
                        res.data.response === false &&
                        res.data.status === 409
                      ) {
                        swal({
                          title: "Error!",
                          text: res.data.message,
                          icon: "error",
                        });
                      } else if (
                        res.data.response === false &&
                        res.data.status === 500
                      ) {
                        swal({
                          title: "Error!",
                          text: "Something went wrong. Please try again later!",
                          icon: "error",
                        });
                      }
                    })
                    .catch((err) => {
                      console.error(err);
                      swal({
                        title: "Error!",
                        text: "Something went wrong. Please try again later!",
                        icon: "error",
                      });
                    });
                }}
              >
                {({ values, setFieldValue, isSubmitting }) => (
                  <Form noValidate autoComplete="off">
                    <div className="login__wrapper-imgcontainer">
                      <img src={Logo} alt="logo" className="logo" />
                    </div>

                    <div className="login__wrapper-container">
                      <div className="top">
                        <label htmlFor="fullName">Fullname</label>
                        <Field
                          id="fullName"
                          name="fullName"
                          placeholder="Enter your fullname"
                          type="text"
                        />
                        <ErrorMessage name="fullName">
                          {(msg: string) => (
                            <div
                              data-testid="fullNameError"
                              style={{ color: "red", fontSize: "12px" }}
                            >
                              {msg}
                            </div>
                          )}
                        </ErrorMessage>
                      </div>
                      <div className="top">
                        <label htmlFor="email">Email</label>
                        <Field
                          id="email"
                          name="email"
                          placeholder="Enter email"
                          type="email"
                          value={userEmail}
                        />
                        <ErrorMessage name="email">
                          {(msg: string) => (
                            <div style={{ color: "red" }}>{msg}</div>
                          )}
                        </ErrorMessage>
                      </div>
                      <div className="bottom">
                        <label htmlFor="phone">Phone</label>
                        <Field
                          inputProps={{ "aria-label": "Phone" }}
                          component={PhoneInput}
                          id="phone"
                          name="phone"
                          placeholder="Add your phone number with country code"
                          type="text"
                          country={"ng"}
                          enableAreaCodes={true}
                          countryCodeEditable={true}
                          enableSearch={true}
                          containerStyle={{
                            width: "100%",
                          }}
                          inputStyle={{
                            width: "86%",
                            marginLeft: "50px",
                            marginRight: "0px",
                            display: "inline-block",
                            boxSizing: "border-box",
                            background: "#fafafa",
                            borderRadius: "5px",
                            border: "1px solid #fafafa",
                            height: "53px",
                            padding: "12px",
                          }}
                          buttonStyle={{
                            border: "none",
                          }}
                          value={values.phone}
                          onChange={(newValue: any) =>
                            setFieldValue("phone", newValue)
                          }
                        />
                        <ErrorMessage name="phone">
                          {(msg: string) => (
                            <div
                              data-testid="phoneError"
                              style={{ color: "red" }}
                            >
                              {msg}
                            </div>
                          )}
                        </ErrorMessage>
                      </div>
                      <div className="bottom">
                        <label htmlFor="password">Password</label>
                        <div
                          style={{
                            position: "relative",
                          }}
                        >
                          <Field
                            id="password"
                            name="password"
                            placeholder="Enter Password"
                            type={showHidePassword ? "text" : "password"}
                          />
                          <span
                            onClick={() =>
                              setShowHidePassword(!showHidePassword)
                            }
                            style={{
                              position: "absolute",
                              top: "20px",
                              right: "10px",
                              cursor: "pointer",
                            }}
                          >
                            {showHidePassword ? (
                              <svg
                                id="Layer_1"
                                enableBackground="new 0 0 519.643 519.643"
                                height="24"
                                viewBox="0 0 519.643 519.643"
                                width="24"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="#666"
                              >
                                <circle cx="259.823" cy="259.866" r="80" />
                                <path d="m511.673 237.706c-61.494-74.31-154.579-145.84-251.85-145.84-97.29 0-190.397 71.58-251.85 145.84-10.63 12.84-10.63 31.48 0 44.32 15.45 18.67 47.84 54.71 91.1 86.2 108.949 79.312 212.311 79.487 321.5 0 43.26-31.49 75.65-67.53 91.1-86.2 10.599-12.815 10.654-31.438 0-44.32zm-251.85-89.84c61.76 0 112 50.24 112 112s-50.24 112-112 112-112-50.24-112-112 50.24-112 112-112z" />
                              </svg>
                            ) : (
                              <svg
                                id="Capa_1"
                                enableBackground="new 0 0 512 512"
                                height="24"
                                viewBox="0 0 512 512"
                                width="24"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="#666"
                              >
                                <g>
                                  <path d="m436.193 169.626c-5.507-4.735-11.04-9.258-16.589-13.59l-64.701 64.701c3.942 11.027 6.097 22.898 6.097 35.263 0 57.897-47.103 105-105 105-12.365 0-24.236-2.155-35.263-6.098l-48.558 48.558c28.356 11.632 56.481 17.54 83.821 17.54 32.657 0 66.432-8.396 100.384-24.955 26.662-13.005 53.514-31.063 79.809-53.671 44.455-38.226 71.841-76.024 72.984-77.615 3.765-5.232 3.765-12.285 0-17.518-1.144-1.591-28.529-39.389-72.984-77.615z" />
                                  <path d="m256 331c41.355 0 75-33.645 75-75 0-3.598-.27-7.134-.763-10.598l-84.835 84.835c3.465.493 7 .763 10.598.763z" />
                                  <path d="m507.607 4.394c-5.857-5.857-15.355-5.857-21.213 0l-117.848 117.848c-4.056-2.208-8.111-4.311-12.162-6.286-33.952-16.56-67.727-24.956-100.384-24.956s-66.432 8.396-100.384 24.955c-26.662 13.005-53.514 31.063-79.809 53.671-44.454 38.226-71.84 76.024-72.984 77.615-3.765 5.232-3.765 12.285 0 17.518 1.144 1.591 28.529 39.39 72.984 77.615 13.623 11.713 27.396 22.192 41.214 31.391l-112.627 112.629c-5.858 5.857-5.858 15.355 0 21.213 2.929 2.928 6.767 4.393 10.606 4.393s7.678-1.465 10.606-4.394l482-482c5.859-5.857 5.859-15.355.001-21.212zm-356.607 251.606c0-57.897 47.103-105 105-105 23.551 0 45.315 7.794 62.85 20.938l-21.52 21.52c-11.859-7.864-26.065-12.458-41.33-12.458-41.355 0-75 33.645-75 75 0 15.264 4.594 29.47 12.458 41.33l-21.52 21.52c-13.144-17.535-20.938-39.299-20.938-62.85z" />
                                </g>
                              </svg>
                            )}
                          </span>
                          <ErrorMessage name="password">
                            {(msg: string) => (
                              <div
                                data-testid="passwordError"
                                style={{ color: "red" }}
                              >
                                {msg}
                              </div>
                            )}
                          </ErrorMessage>
                        </div>
                      </div>
                      <div className="bottom">
                        <label htmlFor="confirmPassword">
                          Confirm Password
                        </label>
                        <div
                          style={{
                            position: "relative",
                          }}
                        >
                          <Field
                            id="confirmPassword"
                            name="confirmPassword"
                            placeholder="Confirm Password"
                            type={showHidePassword2 ? "text" : "password"}
                          />
                          <span
                            onClick={() =>
                              setShowHidePassword2(!showHidePassword2)
                            }
                            style={{
                              position: "absolute",
                              top: "20px",
                              right: "10px",
                              cursor: "pointer",
                            }}
                          >
                            {showHidePassword2 ? (
                              <svg
                                id="Layer_1"
                                enableBackground="new 0 0 519.643 519.643"
                                height="24"
                                viewBox="0 0 519.643 519.643"
                                width="24"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="#666"
                              >
                                <circle cx="259.823" cy="259.866" r="80" />
                                <path d="m511.673 237.706c-61.494-74.31-154.579-145.84-251.85-145.84-97.29 0-190.397 71.58-251.85 145.84-10.63 12.84-10.63 31.48 0 44.32 15.45 18.67 47.84 54.71 91.1 86.2 108.949 79.312 212.311 79.487 321.5 0 43.26-31.49 75.65-67.53 91.1-86.2 10.599-12.815 10.654-31.438 0-44.32zm-251.85-89.84c61.76 0 112 50.24 112 112s-50.24 112-112 112-112-50.24-112-112 50.24-112 112-112z" />
                              </svg>
                            ) : (
                              <svg
                                id="Capa_1"
                                enableBackground="new 0 0 512 512"
                                height="24"
                                viewBox="0 0 512 512"
                                width="24"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="#666"
                              >
                                <g>
                                  <path d="m436.193 169.626c-5.507-4.735-11.04-9.258-16.589-13.59l-64.701 64.701c3.942 11.027 6.097 22.898 6.097 35.263 0 57.897-47.103 105-105 105-12.365 0-24.236-2.155-35.263-6.098l-48.558 48.558c28.356 11.632 56.481 17.54 83.821 17.54 32.657 0 66.432-8.396 100.384-24.955 26.662-13.005 53.514-31.063 79.809-53.671 44.455-38.226 71.841-76.024 72.984-77.615 3.765-5.232 3.765-12.285 0-17.518-1.144-1.591-28.529-39.389-72.984-77.615z" />
                                  <path d="m256 331c41.355 0 75-33.645 75-75 0-3.598-.27-7.134-.763-10.598l-84.835 84.835c3.465.493 7 .763 10.598.763z" />
                                  <path d="m507.607 4.394c-5.857-5.857-15.355-5.857-21.213 0l-117.848 117.848c-4.056-2.208-8.111-4.311-12.162-6.286-33.952-16.56-67.727-24.956-100.384-24.956s-66.432 8.396-100.384 24.955c-26.662 13.005-53.514 31.063-79.809 53.671-44.454 38.226-71.84 76.024-72.984 77.615-3.765 5.232-3.765 12.285 0 17.518 1.144 1.591 28.529 39.39 72.984 77.615 13.623 11.713 27.396 22.192 41.214 31.391l-112.627 112.629c-5.858 5.857-5.858 15.355 0 21.213 2.929 2.928 6.767 4.393 10.606 4.393s7.678-1.465 10.606-4.394l482-482c5.859-5.857 5.859-15.355.001-21.212zm-356.607 251.606c0-57.897 47.103-105 105-105 23.551 0 45.315 7.794 62.85 20.938l-21.52 21.52c-11.859-7.864-26.065-12.458-41.33-12.458-41.355 0-75 33.645-75 75 0 15.264 4.594 29.47 12.458 41.33l-21.52 21.52c-13.144-17.535-20.938-39.299-20.938-62.85z" />
                                </g>
                              </svg>
                            )}
                          </span>
                          <ErrorMessage name="confirmPassword">
                            {(msg: string) => (
                              <div
                                data-testid="confirmPasswordError"
                                style={{ color: "red" }}
                              >
                                {msg}
                              </div>
                            )}
                          </ErrorMessage>
                        </div>
                      </div>
                      <div className="bottom">
                        <label htmlFor="country">Country</label>
                        <Field
                          id="country"
                          name="country"
                          placeholder="Your country"
                          type="text"
                        />
                        <ErrorMessage name="country">
                          {(msg: string) => (
                            <div
                              data-testid="countryError"
                              style={{ color: "red" }}
                            >
                              {msg}
                            </div>
                          )}
                        </ErrorMessage>
                      </div>
                      <button type="submit">
                        {isSubmitting ? "Submitting..." : "Sign up"}
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
              <div className="login__wrapper--bottom">
                <div className="login__wrapper--bottom-footer">
                  <p>
                    <span>&copy;</span> Kominiti 2022 All Rights
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default Registration;
