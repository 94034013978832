/* eslint-disable */
import React, { useEffect, useState, FC } from "react";
import { useParams } from "react-router-dom";
import "../../../styles/system.admin.scss";
import { Switch } from "antd";
import { useNavigate } from "react-router-dom";
import { isAuth } from "../../auth/ProtectedRoute";
import axios from "axios";
import swal from "sweetalert";

const ViewRoles: FC = () => {
  const navigate = useNavigate();
  const [roles, setRoles] = useState<{
    name: string;
    description: string;
    modules: {}[];
  }>({
    name: "",
    description: "",
    modules: [],
  });

  const { id } = useParams();

  useEffect(() => {
    axios({
      method: "GET",
      url: process.env.REACT_APP_HOST_API + `role/${id}`,
      headers: {
        Authorization: `Bearer ${isAuth()}`,
      },
    })
      .then((res) => {
        if (res.data.response === true) {
          setRoles(res.data.data);
        } else if (res.data.response === false && res.data.status === 404) {
          swal({
            title: "Error!",
            text: res.data.message,
            icon: "error",
          });
        }
      })
      .catch((err) => {
        swal({
          title: "Error!",
          text: "Something went wrong. Please try again later!",
          icon: "error",
        });
      });
  }, []);

  return (
    <>
      <div className="admin-edit">
        <div className="admin-edit-wrapper">
          <div className="admin-edit--heading">
            <div className="admin-edit--heading--title">
              <h1>View Role</h1>
            </div>
            <div className="admin-edit--heading-btn">
              <button onClick={() => navigate(-1)}>
                <span>
                  <svg
                    width="22"
                    height="20"
                    viewBox="0 0 22 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7 2L2 7L7 12"
                      stroke="white"
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M2 7H10C15.523 7 20 11.477 20 17V18"
                      stroke="white"
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
                Back
              </button>
            </div>
          </div>
        </div>
        <div className="admin-edit--wrap">
          <h1>Role</h1>
          <div className="admin-edit--flex">
            <div className="right">
              <p>{roles.name}</p>
            </div>
          </div>
        </div>
        <div className="admin-edit--desc">
          <h1>Description</h1>
          <textarea
            name="description"
            form="usrform"
            value={roles.description}
          ></textarea>
        </div>
        <div className="admin-edit--switch">
          <div className="admin-edit--switch--heading">
            <div className="right">
              <h1>Modules</h1>
            </div>
            <div className="left">
              <Switch checked />
            </div>
          </div>
          <div className="admin-edit--switch--list">
            {roles.modules.map((mod: any) => {
              return (
                <div className="list" key={mod.id}>
                  <div className="list--wrap">
                    <h1>{mod.name}</h1>
                    <Switch checked />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};
export default ViewRoles;
