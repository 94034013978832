import React, { Fragment, FC } from "react";
import Modal from "../../../components/modal/Modal";
import useModal from "../../../components/modal/useModal";
import user from "../../../assets/user-img.png";
import "../../../styles/system.admin.scss";

const SystemAdmin: FC = () => {
  const { isOpen, toggle } = useModal();
  return (
    <Fragment>
      <div className="system-admin">
        <div className="system-admin--wrapper">
          <div className="system-admin--img">
            <img src={user} alt="admin" />
          </div>
          <div className="system-admin--content">
            <p>
              Amet minim mollit non deserunt ullamco est sit aliqua dolor do
              amet sint. Amet minim mollit non deserunt ullamco est sit aliqua
              dolor do amet sint. Amet minim mollit non deserunt ullamco est sit
              aliqua dolor do amet sint.
            </p>
          </div>
          <div className="system-admin-btn">
            <button onClick={toggle}>Create System Modules</button>
            <Modal isOpen={isOpen} toggle={toggle}></Modal>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default SystemAdmin;
