/* eslint-disable */
import React, { useEffect, useState, FC } from "react";
import "../../../styles/system.admin.scss";
import { useNavigate, useParams } from "react-router-dom";
import { Switch } from "antd";
import { Form, Formik } from "formik";
import { EditRoleType } from "./types/DataType";
import axios from "axios";
import { isAuth } from "../../auth/ProtectedRoute";
import * as Yup from "yup";
import swal from "sweetalert";
import { ActivityLogValues } from "../ActivityLog/types/DataType";

const AssignRole: FC = () => {
  const navigate = useNavigate();

  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [modules, setModules] = useState<
    {
      id: number;
    }[]
  >([]);
  const [allModules, setAllModules] = useState<
    {
      id: number;
    }[]
  >([]);

  const { id } = useParams();

  useEffect(() => {
    axios({
      method: "GET",
      url: process.env.REACT_APP_HOST_API + `module`,
      headers: {
        Authorization: `Bearer ${isAuth()}`,
      },
    })
      .then((res) => {
        setAllModules(res.data.data.data);
      })
      .catch((err) => {
        swal({
          title: "Error!",
          text: "Something went wrong. Please try again later!",
          icon: "error",
        });
      });
  }, []);

  useEffect(() => {
    axios({
      method: "GET",
      url: process.env.REACT_APP_HOST_API + `role/${id}`,
      headers: {
        Authorization: `Bearer ${isAuth()}`,
      },
    })
      .then((res) => {
        setName(res.data.data.name);
        setDescription(res.data.data.description);
        setModules(res.data.data.modules);
      })
      .catch((err) => {
        swal({
          title: "Error!",
          text: "Something went wrong. Please try again later!",
          icon: "error",
        });
      });
  }, []);

  modules.map((b) => {
    allModules.map((a, i) => {
      if (a.id === b.id) {
        allModules.splice(i, 1);
      }
    });
  });
  let res: number[] = [];

  const initialValues: EditRoleType = {
    roleName: name,
    roleDescription: description,
    roleModules: modules,
  };

  return (
    <>
      <div className="admin-edit">
        <div className="admin-edit-wrapper">
          <div className="admin-edit--heading">
            <div className="admin-edit--heading--title">
              <h1>Assign Role</h1>
            </div>
            <div className="admin-edit--heading-btn">
              <button onClick={() => navigate(-1)}>
                <span>
                  <svg
                    width="22"
                    height="20"
                    viewBox="0 0 22 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7 2L2 7L7 12"
                      stroke="white"
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M2 7H10C15.523 7 20 11.477 20 17V18"
                      stroke="white"
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
                Back
              </button>
            </div>
          </div>
        </div>
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={Yup.object().shape({
            roleName: Yup.string().required("This field is required"),
            roleDescription: Yup.string().required("This field is required"),
          })}
          onSubmit={(values: EditRoleType, { setSubmitting }) => {
            let formDetails = {
              role_id: id,
              modules: res,
            };
            axios({
              method: "POST",
              url: process.env.REACT_APP_HOST_API + `addmodulesrole`,
              headers: {
                Authorization: `Bearer ${isAuth()}`,
              },
              data: formDetails,
            })
              .then((res) => {
                setSubmitting(false);
                if (res.data.response === true) {
                  let activityLogItem;
                  if (typeof window !== "undefined") {
                    if (sessionStorage.getItem("activityDetail")) {
                      activityLogItem = JSON.parse(
                        sessionStorage.getItem("activityDetail")!
                      );
                    }
                  }
                  let activityDetail: ActivityLogValues = {
                    full_name: activityLogItem.full_name,
                    role: activityLogItem.role,
                    ip_address: activityLogItem.ip_address,
                    browser: activityLogItem.browser,
                    events: `Assigned module(s) to role: ${name} `,
                    module: "Admin Roles",
                    location: activityLogItem.location,
                    email: activityLogItem.email,
                  };
                  axios({
                    method: "POST",
                    url:
                      process.env.REACT_APP_HOST_API + "manage-activity/user",
                    headers: {
                      Authorization: `Bearer ${isAuth()}`,
                    },
                    data: activityDetail,
                  });
                  swal({
                    title: "Success!",
                    text: "Module(s) assigned to Role successfully!",
                    icon: "success",
                  });
                  navigate(-1);
                } else if (
                  res.data.response === false &&
                  res.data.status === 404
                ) {
                  swal({
                    title: "Error!",
                    text: res.data.message,
                    icon: "error",
                  });
                } else if (
                  res.data.response === false &&
                  res.data.status === 409
                ) {
                  swal({
                    title: "Error!",
                    text: res.data.message,
                    icon: "error",
                  });
                }
              })
              .catch((err) => {
                swal({
                  title: "Error!",
                  text: "Something went wrong. Please try again later!",
                  icon: "error",
                });
              });
          }}
        >
          {({ values, isSubmitting }) => (
            <Form noValidate autoComplete="off">
              <div className="admin-edit--wrap">
                <div className="admin-edit--user">
                  <div className="right">
                    <label htmlFor="roleName">Role</label>
                    <input type="text" name="roleName" value={name} />
                  </div>
                </div>
              </div>
              <div className="admin-edit--desc">
                <label htmlFor="roleDescription">Description</label>
                <textarea name="roleDescription" value={description}></textarea>
              </div>
              <div className="admin-edit--switch">
                <div className="admin-edit--switch--heading">
                  <div className="right">
                    <h1> Role Modules</h1>
                  </div>
                </div>
                <div className="admin-edit--switch--list">
                  {values.roleModules.map((mod: any, i) => {
                    return (
                      <div className="list" key={i}>
                        <div className="list--wrap">
                          <h1>{mod.name}</h1>
                          <Switch checked />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="admin-edit--switch">
                <div className="admin-edit--switch--heading">
                  <div className="right">
                    <h1>Modules</h1>
                  </div>
                </div>
                <div className="admin-edit--switch--list">
                  {allModules.map((mod: any, i) => {
                    return (
                      <div className="list" key={i}>
                        <div className="list--wrap">
                          <h1>{mod.name}</h1>
                          <Switch
                            onChange={(checked: boolean) => {
                              if (checked) {
                                res.push(mod.id);
                              }
                            }}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="admin-edit--btn">
                <button disabled={isSubmitting} type="submit">
                  {isSubmitting ? "Submitting..." : "Update"}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  );
};
export default AssignRole;
