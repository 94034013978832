import React, { useContext, FC } from "react";
import { Link } from "react-router-dom";
import "../../../../styles/system.admin.scss";
import { SidebarContext } from "../../../auth/ProtectedRoute";

const AssignBtn: FC<{ record: { id: number | null; name: string } }> = ({
  record,
}) => {
  const { userName, sidebarData } = useContext(SidebarContext);
  return (
    <div style={{ width: "100%" }}>
      <Link
        to={`assign-role/${record.id}`}
        state={{ userName: userName, sidebar: sidebarData }}
      >
        <button className="__btn">Assign Module</button>
      </Link>
    </div>
  );
};
export default AssignBtn;
