import React, { useState, Key, useEffect } from "react";
import styled from "styled-components";
import { Menu, Table, TableColumnsType } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import { Dropdown, Space } from "antd";
import { CompletedProjectDataType } from "../type/DataType";
import { isAuth } from "../../../auth/ProtectedRoute";
import SkeletonTable, {
  SkeletonTableColumnsType,
} from "../../../../components/SkeletonTable";
import axios from "axios";
import ProjectCompletedViewButton from "../component/projectCompletedViewButton";
// import ProjectEditButton from "../component/projectEditButton";

const CompletedProjectOrderTable = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [projectTableData, setProjectTableData] = useState<
    CompletedProjectDataType[]
  >([]);
  const [showTotal, setShowTotal] = useState<number>();
  const [record, setRecord] = useState<CompletedProjectDataType>();

  const menu = (
    <Menu>
      <Menu.Item
        key="1"
        icon={<i className="fa fa-eye" aria-hidden="true"></i>}
      >
        <ProjectCompletedViewButton record={record!} />
      </Menu.Item>
    </Menu>
  );

  //api request for table data
  const showProjectTable = (page: number) => {
    axios({
      method: "GET",
      url:
        process.env.REACT_APP_HOST_API +
        `order-management/list?status=completed&page=${page}`, //change status to ongoing to see data, completed order data is currently empty
      headers: {
        Authorization: `Bearer ${isAuth()}`,
      },
    })
      .then((res) => {
        setLoading(false);
        if (res.data.response === true && res.data.status === 200) {
          setProjectTableData(
            res?.data?.data?.map((projectData: any) => ({
              key: projectData.order_detail?.order_id,
              order_id: projectData.order_detail?.order_id,
              buyer: {
                name: projectData?.buyer?.name,
                profile_image:
                  process.env.REACT_APP_IMAGE_API_PREFIX +
                  projectData?.buyer?.profile_image,
              },
              seller: {
                name: projectData?.seller?.name,
                profile_image:
                  process.env.REACT_APP_IMAGE_API_PREFIX +
                  projectData?.seller?.profile_image,
              },
              projectTitle:
                projectData?.order_detail?.title.length > 20
                  ? `${projectData?.order_detail?.title.slice(0, 20)}...`
                  : projectData?.order_detail?.title,
              startDate: projectData?.order_detail?.started_date,
              completionDate: projectData?.order_detail?.completion_date,
              amount: projectData?.order_detail?.amount,
              status: projectData?.order_detail?.status,
              action: "",
            }))
          );
          setShowTotal(res?.data?.data?.total);
        } else {
          // console.log(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    showProjectTable(1);
  }, []);

  const ImageContainer = styled.div`
    height: 45px;
    width: 45px;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
  `;

  const columns: TableColumnsType<CompletedProjectDataType> = [
    {
      title: "Buyer",
      dataIndex: "buyer",
      render: ({ name, profile_image }) => (
        <div className="user-area" style={{ alignItems: "center" }}>
          <ImageContainer>
            <img
              src={profile_image}
              alt="user"
              // className="user_avatar"
              style={{ width: "100%", borderRadius: "999px", height: "100%" }}
            />
          </ImageContainer>
          <p className="user_name" style={{ fontSize: "14px" }}>
            {name}
          </p>
        </div>
      ),
    },
    {
      title: "Seller",
      dataIndex: "seller",
      render: ({ name, profile_image }) => (
        <div className="user-area" style={{ alignItems: "center" }}>
          <ImageContainer>
            <img
              src={profile_image}
              alt="user"
              // className="user_avatar"
              style={{ width: "100%", borderRadius: "999px", height: "100%" }}
            />
          </ImageContainer>
          <p className="user_name" style={{ fontSize: "14px" }}>
            {name}
          </p>
        </div>
      ),
    },
    {
      title: "Project Title",
      dataIndex: "projectTitle",
      render: (text) => (
        <div>
          <p className="user_name" style={{ fontSize: "14px" }}>
            {text}
          </p>
        </div>
      ),
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      render: (text) => (
        <div>
          <p className="user_name" style={{ fontSize: "14px" }}>
            {text}
          </p>
        </div>
      ),
    },
    {
      title: "Completion Date",
      dataIndex: "completionDate",
      render: (text) => (
        <div>
          <p className="user_name" style={{ fontSize: "14px" }}>
            {text}
          </p>
        </div>
      ),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      render: (text) => (
        <div className="amount-area">
          <p>{text}</p>
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text) => (
        <div className="status-area">
          <p
            style={{
              background: "#4895EF",
              textAlign: "center",
            }}
          >
            {text}
          </p>
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      render: () => (
        <div>
          <Space wrap>
            <Dropdown.Button
              overlay={menu}
              placement="bottomCenter"
              icon={<MoreOutlined />}
              trigger={["click"]}
            ></Dropdown.Button>
          </Space>
        </div>
      ),
    },
  ];

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (
      selectedRowKeys: Key[],
      selectedRows: CompletedProjectDataType[]
    ) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    // getCheckboxProps: (record: ProjectApprovedDataType) => ({
    //   disabled: record === "Disabled User", // Column configuration not to be checked
    //   name: record.buyer,
    // }),
  };
  const [selectionType, setSelectionType] = useState<"checkbox" | "radio">(
    "checkbox"
  );

  return (
    <SkeletonTable
      loading={loading}
      columns={columns as SkeletonTableColumnsType[]}
    >
      <Table
        rowSelection={{
          type: selectionType,
          ...rowSelection,
        }}
        columns={columns}
        dataSource={projectTableData}
        pagination={{
          showSizeChanger: false,
          pageSize: 20,
          total: showTotal,
          onChange: (page) => {
            showProjectTable(page);
          },
          showTotal: (showTotal, range) =>
            `${range[0]}-${range[1]} of ${showTotal} items`,
        }}
        onRow={(record) => {
          return {
            onClick: () => {
              setRecord(record);
            },
          };
        }}
      />
    </SkeletonTable>
  );
};

export default CompletedProjectOrderTable;
