/* eslint-disable */
import React, { useState, createContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { UserToken } from "./types/AuthValues";

const contextDefaultValue: UserToken = {
  userName: "",
  sidebarData: { id: null, name: "", description: "", modules: [] },
  setSidebarData: () => {},
};

export const SidebarContext = createContext<UserToken>(contextDefaultValue);

const SidebarProvider = ({ children }: any) => {
  const location = useLocation();
  const { state: { sidebar, userName } = {} } = useLocation();
  const [adminName, setAdminName] = useState<string>(
    contextDefaultValue.userName
  );
  const [sidebarData, setSidebarData] = useState<{
    id: number | null;
    name: string;
    description: string;
    modules: {
      id: number;
      name: string;
      sub_module: { id: number; name: string; route: string }[];
    }[];
  }>(contextDefaultValue.sidebarData);

  useEffect(() => {
    if (userName) {
      setAdminName(userName);
    }
    if (sidebar) {
      setSidebarData(sidebar);
    }
  }, []);

  return (
    <SidebarContext.Provider value={{ userName, sidebarData, setSidebarData }}>
      {children}
    </SidebarContext.Provider>
  );
};

export const isAuth = () => {
  if (typeof window !== "undefined") {
    if (sessionStorage.getItem("user")) {
      return JSON.parse(sessionStorage.getItem("user")!);
    } else {
      return null;
    }
  } else if (typeof window === "undefined") {
    if (sessionStorage.getItem("user")) {
      sessionStorage.removeItem("user");
      return null;
    }
  }
};
export default SidebarProvider;
