import React, { Fragment } from "react";
import { Outlet } from "react-router-dom";
import AdminSidebar from "../components/AdminSidebar";
import SidebarProvider from "../pages/auth/ProtectedRoute";
import "../styles/sidebar.scss";

const AdminLayout = () => {
  return (
    <Fragment>
      <SidebarProvider>
        <div className="layout">
          <AdminSidebar />
          <div className="containers">
            <Outlet />
          </div>
        </div>
      </SidebarProvider>
    </Fragment>
  );
};
export default AdminLayout;

//sidebarProvider is called here to cover all children of the admin layout with the /admin route prefix
//all route navigation must carry the sidebardata state as part of its property so as to provide
//the context api value to its element.
