/* eslint-disable */
import React, { useState, Key, FC, useEffect, Fragment } from "react";
import "antd/dist/antd.css";
import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import type { TableRowSelection } from "antd/es/table/interface";
import { MoreOutlined } from "@ant-design/icons";
import { Dropdown, Menu, Space } from "antd";
import { GroupDataType } from "../types/DataTypes";
import "../../../../styles/system.admin.scss";
import SkeletonTable, {
  SkeletonTableColumnsType,
} from "../../../../components/SkeletonTable";
import axios from "axios";
import { isAuth } from "../../../auth/ProtectedRoute";
import swal from "sweetalert";
import EditGroupBtn from "../Components/EditGroupBtn";
import ViewGroupBtn from "../Components/ViewGroupBtn";
import GroupActivateModal from "../Components/GroupActivateModal";
import GroupDeleteModal from "../Components/GroupDeleteModal";

const Groups: FC = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [groupsTableData, setGroupsTableData] = useState<GroupDataType[]>([]);
  const [showTotal, setShowTotal] = useState<number>();

  const [record, setRecord] = useState<GroupDataType>();

  //switch and case function to dynamically change the status column
  const statusText = (status: string) => {
    switch (status) {
      case "1":
        return (
          <Fragment>
            <div className="group-active-status">Active</div>
          </Fragment>
        );
      case "0":
        return (
          <Fragment>
            <div className="group-inactive-status">Inactive</div>
          </Fragment>
        );
    }
  };

  //antd feed table menu dropdown
  const menu = (
    <Menu
      items={[
        {
          label: <EditGroupBtn record={record!} />,
          key: "1",
          icon: <i className="fa fa-pencil" aria-hidden="true"></i>,
        },
        {
          label: <ViewGroupBtn record={record!} />,
          key: "2",
          icon: (
            <i
              className="fa fa-eye"
              aria-hidden="true"
              style={{ marginRight: "5px" }}
            ></i>
          ),
        },
        {
          label: <GroupActivateModal record={record!} />,
          key: "3",
          icon: <i className="fa fa-toggle-off" aria-hidden="true"></i>,
        },
        {
          label: <GroupDeleteModal record={record!} />,
          key: "4",
          icon: <i className="fa fa-trash" aria-hidden="true"></i>,
        },
      ]}
    />
  );

  //column definition for table
  const columns: ColumnsType<GroupDataType> = [
    {
      title: "ID",
      dataIndex: "id",
      render: (text) => (
        <div className="status">
          <p>{text}</p>
        </div>
      ),
    },
    {
      title: "Created By",
      dataIndex: "created_by",
      render: (text) => (
        <div className="status">
          <p>{text}</p>
        </div>
      ),
    },
    {
      title: "Admin",
      dataIndex: "admin",
      render: (text) => (
        <div className="status">
          <p>{text}</p>
        </div>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      render: (text) => (
        <div className="status">
          <p>{text}</p>
        </div>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      render: (text) => (
        <div className="status">
          <p>{text}</p>
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (status: string) => <Fragment>{statusText(status)}</Fragment>,
    },
    {
      title: "Privacy",
      dataIndex: "privacy",
      render: (text) => (
        <div className="status">
          <p>{text}</p>
        </div>
      ),
    },
    {
      title: "Category",
      dataIndex: "category",
      render: (text) => (
        <div className="status">
          <p>{text}</p>
        </div>
      ),
    },
    // {
    //   title: "members",
    //   dataIndex: "members",
    //   render: (text) => (
    //     <div className="status">
    //       <p>{text}</p>
    //     </div>
    //   ),
    // },
    {
      title: "Action",
      dataIndex: "action",
      render: (text) => (
        <div>
          <Space wrap>
            <Dropdown.Button
              overlay={menu}
              placement="bottom"
              trigger={["click"]}
              icon={<MoreOutlined />}
            ></Dropdown.Button>
          </Space>
        </div>
      ),
    },
  ];

  //api request for table data
  const showGroupTable = (page: number) => {
    axios({
      method: "GET",
      url: process.env.REACT_APP_HOST_API + `manage-groups/group?page=${page}`,
      headers: {
        Authorization: `Bearer ${isAuth()}`,
      },
    })
      .then((res) => {
        setLoading(false);
        setGroupsTableData(
          res.data.data.data.map((groupData: GroupDataType) => ({
            key: groupData.id,
            id: groupData.id,
            created_by: groupData.name,
            admin: groupData.admin,
            name: groupData.name,
            description: groupData.description,
            status: groupData.status,
            privacy: groupData.privacy,
            category: groupData.category,
            // members: groupData.members,
            action: "",
          }))
        );
        setShowTotal(res.data.data.total);
      })
      .catch((err) => {
        swal({
          title: "Error!",
          text: "Something went wrong. Please try again later!",
          icon: "error",
        });
      });
  };

  useEffect(() => {
    showGroupTable(1);
  }, []);

  // rowSelection object indicates the need for row selection
  const onSelectChange = (newSelectedRowKeys: Key[]) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection: TableRowSelection<GroupDataType> = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
      {
        key: "odd",
        text: "Select Odd Row",
        onSelect: (changableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys.filter((_, index) => {
            if (index % 2 !== 0) {
              return false;
            }
            return true;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
      {
        key: "even",
        text: "Select Even Row",
        onSelect: (changableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys.filter((_, index) => {
            if (index % 2 !== 0) {
              return true;
            }
            return false;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
    ],
  };

  return (
    <SkeletonTable
      loading={loading}
      columns={columns as SkeletonTableColumnsType[]}
    >
      <Table
        rowSelection={rowSelection}
        columns={columns}
        dataSource={groupsTableData}
        pagination={{
          showSizeChanger: false,
          pageSize: 20,
          total: showTotal,
          onChange: (page) => {
            showGroupTable(page);
          },
          showTotal: (showTotal, range) =>
            `${range[0]}-${range[1]} of ${showTotal} items`,
        }}
        onRow={(record) => {
          return {
            onClick: () => {
              setRecord(record);
            },
          };
        }}
      />
    </SkeletonTable>
  );
};

export default Groups;
