import React, { useState, useContext, FC, Key } from "react";
import { Link } from "react-router-dom";
import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import DocumentDropdown from "../component/DocumentDropdown";
import { UserDocumentType } from "../types/UserDocumenttype";
import user from "../../../../assets/user-img.png";
import { SidebarContext } from "../../../auth/ProtectedRoute";

const UserDocumentTable: FC = () => {
  const { userName, sidebarData } = useContext(SidebarContext);

  // const handleMenuClick: MenuProps["onClick"] = (e) => {
  //   message.info("Click on menu item.");
  //   console.log("click", e);
  // };

  // const items: MenuProps["items"] = [
  //   {
  //     label: "Edit Profile",
  //     key: "1",
  //     icon: <UserOutlined />,
  //   },
  //   {
  //     label: (
  //       <div>
  //         <Link
  //           to="view-profile"
  //           state={{ userName: userName, sidebar: sidebarData }}
  //         >
  //           View Profile
  //         </Link>
  //       </div>
  //     ),
  //     key: "2",
  //     icon: <UserOutlined />,
  //   },
  //   {
  //     label: "Delete",
  //     key: "3",
  //     icon: <UserOutlined />,
  //   },
  // ];

  // const menuProps = {
  //   items,
  // };

  const columns: ColumnsType<UserDocumentType> = [
    {
      title: "Id",
      dataIndex: "id",
      render: (num) => (
        <div>
          <p className="user_name">{num}</p>
        </div>
      ),
    },
    {
      title: "Photo",
      dataIndex: "photo",
      render: () => (
        <div
          style={{
            width: "75px",
            height: "80px",
          }}
        >
          <img
            src={user}
            alt="logo"
            style={{ width: "100%", height: "100%", borderRadius: "50%" }}
          />
        </div>
      ),
    },
    {
      title: "User",
      dataIndex: "name",
      render: (text) => (
        <div>
          <p className="user_name">{text}</p>
        </div>
      ),
    },
    {
      title: "Country",
      dataIndex: "country",
      render: (text) => (
        <div>
          <p className="user_name">{text}</p>
        </div>
      ),
    },
    {
      title: "Contact User",
      dataIndex: "contact",
      render: () => (
        <div>
          <DocumentDropdown />
        </div>
      ),
    },

    {
      title: "Status",
      dataIndex: "status",
      render: (text) => (
        <div>
          <p className="user_name">{text}</p>
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      render: () => (
        <div>
          <Link
            to="user-document-details"
            state={{ userName: userName, sidebar: sidebarData }}
          >
            <button>Details</button>
          </Link>
        </div>
      ),
    },
  ];

  const data: UserDocumentType[] = [
    {
      key: "1",
      id: 1,
      photo: "",
      name: "John Brown",
      contact: "",
      status: "processing",
      country: "USA",
      action: "",
    },
  ];

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys: Key[], selectedRows: UserDocumentType[]) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record: UserDocumentType) => ({
      disabled: record.name === "Disabled User", // Column configuration not to be checked
      name: record.name,
    }),
  };
  const [selectionType, setSelectionType] = useState<"checkbox" | "radio">(
    "checkbox"
  );

  return (
    <div>
      <Table
        rowSelection={{
          type: selectionType,
          ...rowSelection,
        }}
        columns={columns}
        dataSource={data}
      />
    </div>
  );
};

export default UserDocumentTable;
