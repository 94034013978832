import React, { Fragment, FC } from "react";
import "../../../styles/system.admin.scss";
import { useNavigate } from "react-router-dom";
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from "formik";
import { AddRoleType } from "./types/DataType";
import * as Yup from "yup";
import axios from "axios";
import { isAuth } from "../../auth/ProtectedRoute";
import swal from "sweetalert";
import { ActivityLogValues } from "../ActivityLog/types/DataType";

const AddRoles: FC = () => {
  const navigate = useNavigate();

  const initialValues: AddRoleType = {
    role: "",
    description: "",
  };
  return (
    <Fragment>
      <div className="admin-edit">
        <div className="admin-edit-wrapper">
          <div className="admin-edit--heading">
            <div className="admin-edit--heading--title">
              <h1>Add Role</h1>
            </div>
            <div className="admin-edit--heading-btn">
              <button onClick={() => navigate(-1)}>
                <span>
                  <svg
                    width="22"
                    height="20"
                    viewBox="0 0 22 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7 2L2 7L7 12"
                      stroke="white"
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M2 7H10C15.523 7 20 11.477 20 17V18"
                      stroke="white"
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
                Back
              </button>
            </div>
          </div>
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={Yup.object().shape({
            role: Yup.string().required("This field is required"),
            description: Yup.string().required("This field is required"),
          })}
          onSubmit={(
            values: AddRoleType,
            { setSubmitting }: FormikHelpers<AddRoleType>
          ) => {
            const formDetails = {
              name: values.role,
              description: values.description,
            };
            axios({
              method: "POST",
              url: process.env.REACT_APP_HOST_API + `role`,
              headers: {
                Authorization: `Bearer ${isAuth()}`,
              },
              data: formDetails,
            })
              .then((res) => {
                setSubmitting(false);
                if (res.data.response === true) {
                  let activityLogItem;
                  if (typeof window !== "undefined") {
                    if (sessionStorage.getItem("activityDetail")) {
                      activityLogItem = JSON.parse(
                        sessionStorage.getItem("activityDetail")!
                      );
                    }
                  }
                  const activityDetail: ActivityLogValues = {
                    full_name: activityLogItem.full_name,
                    role: activityLogItem.role,
                    ip_address: activityLogItem.ip_address,
                    browser: activityLogItem.browser,
                    events: `Added new role: ${values.role}`,
                    module: "Admin Roles",
                    location: activityLogItem.location,
                    email: activityLogItem.email,
                  };
                  axios({
                    method: "POST",
                    url:
                      process.env.REACT_APP_HOST_API + "manage-activity/user",
                    headers: {
                      Authorization: `Bearer ${isAuth()}`,
                    },
                    data: activityDetail,
                  });
                  swal({
                    title: "Success!",
                    text: "Role added successfully!",
                    icon: "success",
                  });
                  navigate(-1);
                } else if (
                  res.data.response === false &&
                  res.data.status === 401
                ) {
                  swal({
                    title: "Error!",
                    text: res.data.message,
                    icon: "error",
                  });
                }
              })
              .catch((err) => {
                console.error(err);
                swal({
                  title: "Error!",
                  text: "Something went wrong. Please try again later!",
                  icon: "error",
                });
              });
          }}
        >
          {({ isSubmitting }) => (
            <Form noValidate autoComplete="off">
              <div className="admin-edit--wrap">
                <div className="admin-edit--user">
                  <div className="right">
                    <label htmlFor="role">Role</label>
                    <Field
                      id="role"
                      name="role"
                      type="text"
                      placeholder="Enter role"
                    />
                    <ErrorMessage name="role">
                      {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                    </ErrorMessage>
                  </div>
                </div>
              </div>
              <div className="admin-edit--desc">
                <label htmlFor="description">Description</label>
                <Field
                  as="textarea"
                  name="description"
                  placeholder="Enter text here..."
                />
                <ErrorMessage name="description">
                  {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                </ErrorMessage>
              </div>
              <div className="admin-edit--btn">
                <button type="submit">
                  {isSubmitting ? "Submitting..." : "Submit"}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Fragment>
  );
};
export default AddRoles;
