import React, { FC } from "react";

const ExperienceTab: FC = () => {
  return (
    <div>
      <div className="user__edit--tab">
        <div className="tab-one">
          <div className="right">
            <h1>Example Group of Companies</h1>
            <p>34B Example Street, Ikeja</p>
            <span>Nigeria</span>
          </div>
          <div className="left">
            <span>August 2021 - Date</span>
          </div>
        </div>
        <div className="tab-two">
          <div className="right">
            <h1>Example Group of Companies</h1>
            <p>34B Example Street, Ikeja</p>
            <span>Nigeria</span>
          </div>
          <div className="left">
            <span>August 2021 - Date</span>
          </div>
        </div>
        <div className="tab-three">
          <div className="right">
            <h1>Example Group of Companies</h1>
            <p>34B Example Street, Ikeja</p>
            <span>Nigeria</span>
          </div>
          <div className="left">
            <span>August 2021 - Date</span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ExperienceTab;
