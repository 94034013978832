import React, { useState, useContext, FC, Key } from "react";
import { Link } from "react-router-dom";
import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { UserOutlined, MoreOutlined } from "@ant-design/icons";

import type { MenuProps } from "antd";
import { Dropdown, Space } from "antd";
import { SupportPurposeType } from "../types/SupportPurpose";
import { SidebarContext } from "../../../auth/ProtectedRoute";

const SupportPurposeTable: FC = () => {
  const { userName, sidebarData } = useContext(SidebarContext);

  // const handleMenuClick: MenuProps["onClick"] = (e) => {
  //   message.info("Click on menu item.");
  //   console.log("click", e);
  // };

  const items: MenuProps["items"] = [
    {
      label: (
        <div>
          <Link to="" state={{ userName: userName, sidebar: sidebarData }}>
            Edit
          </Link>
        </div>
      ),
      key: "1",
      icon: <UserOutlined />,
    },
    {
      label: "Delete",
      key: "2",
      icon: <UserOutlined />,
    },
  ];

  const menuProps = {
    items,
  };

  const columns: ColumnsType<SupportPurposeType> = [
    {
      title: "Id",
      dataIndex: "id",
      render: (num) => (
        <div>
          <p className="user_name">{num}</p>
        </div>
      ),
    },
    {
      title: "Purpose Name",
      dataIndex: "purpose",
      render: (text) => (
        <div>
          <p className="user_name">{text}</p>
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      render: () => (
        <div>
          <Space wrap>
            <Dropdown.Button
              menu={menuProps}
              placement="bottomCenter"
              icon={<MoreOutlined />}
            ></Dropdown.Button>
          </Space>
        </div>
      ),
    },
  ];

  const data: SupportPurposeType[] = [
    {
      key: "1",
      id: 1,
      purpose: "John Brown",
      action: "",
    },
  ];

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys: Key[], selectedRows: SupportPurposeType[]) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record: SupportPurposeType) => ({
      disabled: record.purpose === "Disabled User", // Column configuration not to be checked
      name: record.purpose,
    }),
  };
  const [selectionType, setSelectionType] = useState<"checkbox" | "radio">(
    "checkbox"
  );

  return (
    <div>
      <Table
        rowSelection={{
          type: selectionType,
          ...rowSelection,
        }}
        columns={columns}
        dataSource={data}
      />
    </div>
  );
};

export default SupportPurposeTable;
