import React, { FC } from "react"
import { Routes, Route } from "react-router-dom"
import WelcomePage from "./WelcomePage"
import User from "./UserManagement/index"
import AddUser from "./UserManagement/component/AddUser"
import EditUsers from "./UserManagement/component/EditUser"
import ViewProfile from "./UserManagement/component/ViewProfile"
import AdminRole from "./system-admin/AdminRoles"
import EditRoles from "./system-admin/EditRoles"
import ViewRoles from "./system-admin/ViewRoles"
import AssignRoles from "./system-admin/AssignRole"
import AddRole from "./system-admin/AddRole"
import { PrivateRoute } from "../../App"
import Modules from "./LeftPanel/Modules"
import Submodules from "./LeftPanel/Submodules"
import Actions from "./LeftPanel/Actions"
import SystemUser from "./system-admin/SystemUsers"
import EditUser from "./system-admin/EditUser"
import UserDocumentValidation from "./UserManagement/UserDocumentValidation"
import UserValidation from "./UserManagement/UserValidation"
import UserDetails from "./UserManagement/component/UserIdentityDetails"
import DocumentDetails from "./UserManagement/component/DocumentDetails"
import UserSupportPurpose from "./UserManagement/UserSupportPurpose"
import AddPurpose from "./UserManagement/component/AddPurpose"
import UserPurposeMessage from "./UserManagement/UserPurposeMessage"
import UserReply from "./UserManagement/component/UserReply"
import InviteNewUsers from "./UserManagement/InviteUser"
import AddNewInvite from "./UserManagement/component/AddInviteUser"
import ActivityLog from "./ActivityLog/ActivityLog"
import Categories from "./OtherModules/Categories"
import Analytics from "./Analytics/Analytics"
import ViewLog from "./ActivityLog/ViewLog"
import Groups from "./ContentManagement/Groups"
import AddGroup from "./ContentManagement/AddGroup"
import EditGroup from "./ContentManagement/EditGroup"
import ViewGroup from "./ContentManagement/ViewGroup"
import Feeds from "./ContentManagement/Feeds"
import AddFeed from "./ContentManagement/AddFeed"
import EditFeed from "./ContentManagement/EditFeed"
import ViewFeed from "./ContentManagement/ViewFeed"
import Interests from "./OtherModules/Interests"
import Skills from "./OtherModules/Skills"
import LocationCountries from "./OtherModules/LocationCountries"
import Project from "./TaskManagement/Project"
import ViewProject from "./TaskManagement/ViewProject"
import AddProject from "./TaskManagement/AddProject"
import MarketplaceOrder from "./OrderManagement/MarketplaceOrder"
import ViewMarketplaceOrder from "./OrderManagement/ViewMarketplaceOrders"
import Analytic from "./Jobs/analytics/analytics"
import CandidateManagement from "./Jobs/candidateManagement/candidatemanagement"
import Employee from "./Jobs/employees/employee"
import JobPost from "./Jobs/jobPosting/jobpost"
import Course from "./Learning/course/course"
import ContentManagement from "./Learning/content/contentManagement"
import Instructor from "./Learning/instructor/instructor"
import Student from "./Learning/student/student"
import ViewInstructor from "./Learning/instructor/ViewInstructor"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import ViewJobDetails from "./Jobs/jobPosting/ViewJobDetails"
import AnalyticLearning from "./Learning/analytics/AnalyticLearning"
import UserAnalytic from "./UserManagement/UserAnalytics"

const queryClient = new QueryClient()
const Admin: FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <PrivateRoute>
        <Routes>
          <Route path="welcome" element={<WelcomePage />} />
          <Route path="user-management/users" element={<User />} />
          <Route path="user-management/users/add-user" element={<AddUser />} />
          <Route path="user-management/users/edit-user" element={<EditUsers />} />
          <Route path="user-management/users/view-profile" element={<ViewProfile />} />
          <Route
            path="user-management/users/user-document-validation"
            element={<UserDocumentValidation />}
          />
          <Route path="user-management/user-identity-validation" element={<UserValidation />} />
          <Route
            path="user-management/user-identity-validation/user-details"
            element={<UserDetails />}
          />
          <Route
            path="user-management/user-document-validation"
            element={<UserDocumentValidation />}
          />
          <Route path="user-management/user-analytics" element={<UserAnalytic/>}/>
          <Route
            path="user-management/user-document-validation/user-document-details"
            element={<DocumentDetails />}
          />
          <Route path="user-management/user-support-purpose" element={<UserSupportPurpose />} />
          <Route path="user-management/user-support-purpose/add-purpose" element={<AddPurpose />} />
          <Route path="user-management/user-support-message" element={<UserPurposeMessage />} />
          <Route path="user-management/user-support-message/user-reply" element={<UserReply />} />
          <Route path="user-management/invite-user" element={<InviteNewUsers />} />
          <Route path="user-management/invite-user/add-new-invite" element={<AddNewInvite />} />
          <Route path="other-modules/categories" element={<Categories />} />
          <Route path="other-modules/interests" element={<Interests />} />
          <Route path="other-modules/skills" element={<Skills />} />
          <Route path="other-modules/location" element={<LocationCountries />} />
          {/* <Route path="analytics/analytics" element={<Analytics />} /> */}
          <Route path="activity-log/activity-log" element={<ActivityLog />} />
          <Route path="activity-log/activity-log/view-log/:id" element={<ViewLog />} />
          <Route path="content-management/groups" element={<Groups />} />
          <Route path="content-management/groups/add-group" element={<AddGroup />} />
          <Route path="content-management/groups/edit-group/:id" element={<EditGroup />} />
          <Route path="content-management/groups/view-group/:id" element={<ViewGroup />} />
          <Route path="content-management/feeds" element={<Feeds />} />
          <Route path="content-management/feeds/add-feed" element={<AddFeed />} />
          <Route path="content-management/feeds/edit-feed/:id" element={<EditFeed />} />
          <Route path="content-management/feeds/view-feed/:id" element={<ViewFeed />} />
          <Route path="order-management/marketplace" element={<MarketplaceOrder />} />
          <Route
            path="order-management/marketplace/view-order/:id"
            element={<ViewMarketplaceOrder />}
          />
          <Route path="task-management/projects" element={<Project />} />
          <Route path="task-management/projects/view-project/:id" element={<ViewProject />} />
          <Route path="task-management/projects/add-project" element={<AddProject />} />
          <Route path="jobs/analytics" element={<Analytic />} />
          <Route path="jobs/candidate" element={<CandidateManagement />} />
          <Route path="jobs/employees" element={<Employee />} />
          <Route path="jobs/job-post" element={<JobPost />} />
          <Route path="jobs/job-post/view-job/:id" element={<ViewJobDetails />} />

          <Route path="learning/analytics" element={<AnalyticLearning />} />
          <Route path="learning/course" element={<Course />} />
          <Route path="learning/content" element={<ContentManagement />} />
          <Route path="learning/instructor" element={<Instructor />} />
          <Route path="learning/instructor/view-instructor/:id" element={<ViewInstructor />} />
          <Route path="learning/student" element={<Student />} />

          <Route path="system-admin/modules" element={<Modules />} />
          <Route path="system-admin/submodules" element={<Submodules />} />
          <Route path="system-admin/actions" element={<Actions />} />
          <Route path="system-admin/roles" element={<AdminRole />} />
          <Route path="system-admin/roles/edit-role/:id" element={<EditRoles />} />
          <Route path="system-admin/roles/assign-role/:id" element={<AssignRoles />} />
          <Route path="system-admin/roles/view-role/:id" element={<ViewRoles />} />
          <Route path="system-admin/roles/add-role" element={<AddRole />} />
          <Route path="system-admin/admin-user" element={<SystemUser />} />
          <Route path="system-admin/admin-user/edit-user/:id" element={<EditUser />} />
        </Routes>
      </PrivateRoute>
    </QueryClientProvider>
  )
}
export default Admin
