import React, { FC } from "react";
import { Link } from "react-router-dom";

const AddNewInvite: FC = () => {
  return (
    <div className="user">
      <div className="user__edit">
        <div className="user__edit--heading">
          <h1>Invite User</h1>
          <Link to="/invite-new-users">
            <button>
              <svg
                width="22"
                height="20"
                viewBox="0 0 22 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7 2L2 7L7 12"
                  stroke="white"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M2 7H10C15.523 7 20 11.477 20 17V18"
                  stroke="white"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              Back
            </button>
          </Link>
        </div>
      </div>
      <form>
        <div className="user__addpurpose">
          <div className="user__addpurpose--detail">
            <div>
              <h1>Full Name</h1>
              <input placeholder="Agbo Williams" name="fullName" type="text" />
            </div>
            <div>
              <h1>Email</h1>
              <input placeholder="email@email.com" name="email" type="email" />
            </div>
          </div>
        </div>
        <div className="user__addpurpose">
          <h1>Email Content</h1>
          <textarea
            placeholder="Input text here..."
            name="emailContent"
          ></textarea>
        </div>
        <div className="user__edit---btn">
          <div className="btn-wrap">
            <button className="reject">Reset</button>
            <button className="approve">Save</button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddNewInvite;
