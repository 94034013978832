import React, { Fragment, FC } from "react";
import user from "../../../assets/user-img.png";
import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import ActivityLogTable from "./tables/ActivityLogTable";

const ActivityLog: FC = () => {
  return (
    <Fragment>
      <div className="log">
        <div className="log_heading">
          <div className="log_heading-icon">
            <div>
              <svg
                width="28"
                height="26"
                viewBox="0 0 28 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20.25 21.0372H15.25L9.68748 24.7371C8.86248 25.2871 7.75 24.6997 7.75 23.6997L7.75 21.0372C4 21.0372 1.5 18.5372 1.5 14.7872L1.5 7.28711C1.5 3.53711 4 1.03711 7.75 1.03711L20.25 1.03711C24 1.03711 26.5 3.53711 26.5 7.28711L26.5 14.7872C26.5 18.5372 24 21.0372 20.25 21.0372Z"
                  stroke="#858585"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <div>
              <svg
                width="30"
                height="30"
                viewBox="0 0 30 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.0262 3.63672C10.8887 3.63672 7.52621 6.99922 7.52621 11.1367L7.52621 14.7492C7.52621 15.5117 7.20121 16.6742 6.81371 17.3242L5.37621 19.7117C4.48871 21.1867 5.10121 22.8242 6.72621 23.3742C12.1137 25.1742 17.9262 25.1742 23.3137 23.3742C24.8262 22.8742 25.4887 21.0867 24.6637 19.7117L23.2262 17.3242C22.8512 16.6742 22.5262 15.5117 22.5262 14.7492V11.1367C22.5262 7.01172 19.1512 3.63672 15.0262 3.63672Z"
                  stroke="#858585"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                />
                <path
                  d="M17.3359 4.00078C16.9484 3.88828 16.5484 3.80078 16.1359 3.75078C14.9359 3.60078 13.7859 3.68828 12.7109 4.00078C13.0734 3.07578 13.9734 2.42578 15.0234 2.42578C16.0734 2.42578 16.9734 3.07578 17.3359 4.00078Z"
                  stroke="#858585"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M18.7734 23.8242C18.7734 25.8867 17.0859 27.5742 15.0234 27.5742C13.9984 27.5742 13.0484 27.1492 12.3734 26.4742C11.6984 25.7992 11.2734 24.8492 11.2734 23.8242"
                  stroke="#858585"
                  strokeWidth="1.5"
                  strokeMiterlimit="10"
                />
              </svg>
            </div>
            <div>
              <img src={user} alt="profile" />
            </div>
          </div>
        </div>
        <div className="log-title">
          <h1>Activity Log</h1>
          <button className="btn">Export to Excel</button>
        </div>
        <div className="log_header">
          <div className="log_header-wrap">
            <div className="log_header-wrap-search">
              <div>
                <Input
                  size="large"
                  placeholder="Search all.."
                  prefix={<SearchOutlined />}
                  className="inp"
                />
              </div>
            </div>
          </div>
          <div className="log_header-tab">
            <ActivityLogTable />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ActivityLog;
