import React, { useContext, FC } from "react";
import { Link } from "react-router-dom";
import "../../../../styles/system.admin.scss";
import { SidebarContext } from "../../../auth/ProtectedRoute";
import { ProjectPendingDataType } from "../Types/DataType";

const ViewProjectBtn: FC<{ record: ProjectPendingDataType }> = ({ record }) => {

  const { userName, sidebarData } = useContext(SidebarContext);
  return (
    <div style={{ width: "100%" }}>
      <Link
        to={`view-project/${record.project_id}`}
        state={{ userName: userName, sidebar: sidebarData }}
      >
        <button className="__btn">View Project</button>
      </Link>
    </div>
  );
};
export default ViewProjectBtn;
