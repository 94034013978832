import React, { Fragment, FC } from "react";
import "../../styles/welcome.scss";
import welcome from "../../assets/welcom.png";
const WelcomePage: FC = () => {
  return (
    <Fragment>
      <div className="welcome-dashboard">
        <div>
          <div className="welcome-dashboard_admin">
            Welcome to <br />
            <h1>Kominiti Admin</h1>
            {/* <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque
              nesciunt perferendis iste cum corporis beatae recusandae numquam
              excepturi, quidem vero voluptatum ducimus sed quod nulla ex maxime
              error aut similique.
            </p> */}
          </div>
          <div>
            <img src={welcome} />
          </div>
        </div>

        <div className="welcome-dashboard_footer-wrap">
          <div className="copyright">
            <svg
              width="45"
              height="45"
              viewBox="0 0 45 45"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M22.5 10.5C15.873 10.5 10.5 15.873 10.5 22.5C10.5 29.127 15.873 34.5 22.5 34.5C29.127 34.5 34.5 29.127 34.5 22.5C34.5 15.873 29.127 10.5 22.5 10.5ZM22.697 28.75C19.147 28.75 16.961 26.367 16.961 22.496C16.961 18.625 19.139 16.25 22.697 16.25C25.68 16.25 27.94 18.239 28.031 20.926H25.13C24.974 19.636 23.996 18.765 22.697 18.765C21.045 18.765 20.034 20.179 20.034 22.488C20.034 24.806 21.045 26.236 22.705 26.236C24.003 26.236 24.973 25.398 25.146 24.124H28.039C27.941 26.835 25.738 28.75 22.697 28.75Z"
                fill="white"
              />
            </svg>
          </div>
          <div className="footer">
            <p>Kominiti 2021 All Rights Reserved</p>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default WelcomePage;
