import React, { Fragment, useContext, FC } from "react";
import user from "../../../assets/user-img.png";
import FeedsTable from "./tables/FeedsTable";
import { Link } from "react-router-dom";
import { SidebarContext } from "../../auth/ProtectedRoute";

const Feed: FC = () => {
  const { userName, sidebarData } = useContext(SidebarContext);

  return (
    <Fragment>
      <div style={{ padding: "50px" }} className="roless">
        <div className="roless-wrapper">
          <div className="roless--heading">
            {/* <div className="roless--heading-img">
              <img src={user} alt="profile" />
            </div> */}
          </div>
          <div className="roless--tables">
            <div className="roless--tables-heading">
              <div className="roless--tables-heading-title">
                <h2>Feeds</h2>
              </div>
              <div className="roless--tables-heading-box">
                {/* <div className="formy">
                  <form>
                    <div className="formy--box">
                      <input type="text" placeholder="Search.." name="search" />
                      <div className="formy--box-icon">
                        <i className="fa fa-search"></i>
                      </div>
                    </div>
                  </form>
                </div> */}
                <div className="clicks">
                  <div className="clicks--right">
                    <Link
                      to="add-feed"
                      state={{ userName: userName, sidebar: sidebarData }}
                    >
                      <button>
                        <span>
                          <i
                            className="fa fa-plus-circle"
                            aria-hidden="true"
                          ></i>
                        </span>
                        Add Feed
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="roless--tables--content">
              <FeedsTable />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default Feed;
