import React, { useState, useContext, FC, Key, useEffect } from "react";
import { Link } from "react-router-dom";
import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { UserOutlined, MoreOutlined } from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Dropdown, Space } from "antd";
import { SidebarContext, isAuth } from "../../../auth/ProtectedRoute";
import { UserMessageDataType } from "../types/DataType";
import axios from "axios";
import moment from "moment";
import SkeletonTable, {
  SkeletonTableColumnsType,
} from "../../../../components/SkeletonTable";
import parse from "html-react-parser";
// import UserReply from "../component/UserReply";

const SupportMessageTable: FC = () => {
  const { userName, sidebarData } = useContext(SidebarContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [userMessageData, setUserMessageData] = useState<UserMessageDataType[]>(
    []
  );

  const [showTotal, setShowTotal] = useState<number>();
  const [record, setRecord] = useState<UserMessageDataType>();

  const showAllUserMessageTable = async (page: number) => {
    await axios({
      method: "GET",
      url:
        process.env.REACT_APP_HOST_API +
        `manage-support-messages/all/conversations`,
      headers: {
        Authorization: `Bearer ${isAuth()}`,
      },
    })
      .then((res) => {
        setLoading(false);
        setUserMessageData(res.data?.message);
        setShowTotal(res?.data?.message?.total);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    showAllUserMessageTable(1);
  }, []);
  const items: MenuProps["items"] = [
    {
      label: (
        <div>
          <Link
            to={`user-reply?ref=${record?.id}`}
            state={{ userName: userName, sidebar: sidebarData }}
          >
            Reply
          </Link>
        </div>
      ),
      key: "1",
      icon: <UserOutlined />,
    },
    // {
    //   label: "Delete",
    //   key: "2",
    //   icon: <UserOutlined />,
    // },
  ];

  const menuProps = {
    items,
  };

  const columns: ColumnsType<UserMessageDataType> = [
    {
      title: "Title",
      dataIndex: "title",
      render: (title) => (
        <div>
          <p className="user_name">{parse(title.slice(0, 25))}</p>
        </div>
      ),
    },
    {
      title: "Message Body",
      dataIndex: "body",
      render: (body) => (
        <div>
          <p className="user_name">{parse(body.slice(0, 40))}</p>
        </div>
      ),
    },
    {
      title: "Sent By",
      dataIndex: "sent_by",
      render: (sent_by) => (
        <div>
          <p className="user_name">{sent_by}</p>
        </div>
      ),
    },
    {
      title: "Date",
      dataIndex: "date",
      render: (created_at) => (
        <div>
          <p className="user_name">{moment(created_at).format("DD/MM/YYYY")}</p>
        </div>
      ),
    },

    // {
    //   title: "Status",
    //   dataIndex: "closed",
    //   render: (closed) => (
    //     <div>
    //       <p className="user_name">{closed}</p>
    //     </div>
    //   ),
    // },
    {
      title: "Action",
      dataIndex: "action",
      render: () => (
        <div>
          <Space wrap>
            <Dropdown.Button
              menu={menuProps}
              placement="bottomCenter"
              icon={<MoreOutlined />}
              trigger={["click"]}
            ></Dropdown.Button>
          </Space>
        </div>
      ),
    },
  ];

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys: Key[], selectedRows: UserMessageDataType[]) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    // getCheckboxProps: (record: AllUserDataType) => ({
    //   disabled: record.name === "Disabled User", // Column configuration not to be checked
    //   name: record.name,
    // }),
  };
  const [selectionType, setSelectionType] = useState<"checkbox" | "radio">(
    "checkbox"
  );

  return (
    <div>
      <SkeletonTable
        loading={loading}
        columns={columns as SkeletonTableColumnsType[]}
      >
        <Table
          rowSelection={{
            type: selectionType,
            ...rowSelection,
          }}
          columns={columns}
          dataSource={userMessageData}
          pagination={{
            showSizeChanger: false,
            pageSize: 20,
            total: showTotal,
            onChange: (page) => {
              showAllUserMessageTable(page);
            },
            showTotal: (showTotal, range) =>
              `${range[0]}-${range[1]} of ${showTotal} items`,
          }}
          onRow={(record) => {
            return {
              onClick: () => {
                setRecord(record);
              },
            };
          }}
        />
      </SkeletonTable>
    </div>
  );
};

export default SupportMessageTable;
