import React, { Fragment, useEffect, useState } from "react";
import "../../../styles/group.scss";
import { useNavigate, useParams } from "react-router-dom";
import FeedPic from "../../../assets/feedPic.png";
import useModal from "../../../components/modal/useModal";
import ViewFeedImageModal from "./Components/ViewFeedImageModal";
import axios from "axios";
import { isAuth } from "../../auth/ProtectedRoute";
import { ViewFeedValues } from "./types/DataTypes";
import { convertToHTML } from "draft-convert";
import { convertFromRaw } from "draft-js";
import swal from "sweetalert";

const ViewFeed = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { isOpen, toggle } = useModal();

  const [feedData, setFeedData] = useState<ViewFeedValues>({
    id: null,
    account_name: "",
    position: "",
    content: "",
    content_raw_data: "",
    likes: null,
    shares: null,
    feed_media: [],
  });
  const [feedMedia, setFeedMedia] = useState<
    //eslint-disable-next-line
    { id: number | null; content_feed_id: number | null; filename: string }[]
  >([]);

  //api request to get feed data
  useEffect(() => {
    axios({
      method: "GET",
      url: process.env.REACT_APP_HOST_API + `manage-feeds/feed/${params.id}`,
      headers: {
        Authorization: `Bearer ${isAuth()}`,
      },
    })
      .then((res) => {
        if (res?.data?.response === true) {
          setFeedData(res.data.data);
          setFeedMedia(res.data.data.feed_media);
        }
      })
      .catch((err) => {
        console.error(err);
        swal({
          icon: "error",
          text: "Something went wrong. Please try again later!",
        });
      });
  }, []);

  //this should show the number of media left after displaying the first 3
  const leftOver: number = feedMedia.length - 3;

  //convert raw_data converted from editorState in editor back to editorState
  const editorState =
    feedData && feedData.content_raw_data
      ? convertFromRaw(JSON.parse(feedData.content_raw_data))
      : null;

  //convert editorState to html string and pass as dangerouslySetInnerHTML where needed to display as plain text with styling
  const htmlString = editorState !== null ? convertToHTML(editorState) : "";

  return (
    <Fragment>
      <div className="feed-view">
        <div className="feed-view--heading">
          <div className="feed-view--heading--title">
            <h1>Feed Preview</h1>
          </div>
          <div className="feed-view--heading-btn">
            <button onClick={() => navigate(-1)}>
              <span>
                <svg
                  width="22"
                  height="20"
                  viewBox="0 0 22 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7 2L2 7L7 12"
                    stroke="white"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M2 7H10C15.523 7 20 11.477 20 17V18"
                    stroke="white"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
              Back
            </button>
          </div>
        </div>
        {feedMedia.length > 0 ? (
          //this block renders when there is at least one media attachement
          <div className="feed-view--body">
            <div className="feed-view--body-wrapper">
              <div className="feed-view--body-wrapper--header">
                <div className="profile-area">
                  <div className="avatar">
                    <img src={FeedPic} alt="avatar" />
                  </div>
                  <div className="detail">
                    <h5>
                      {feedData.account_name ? feedData.account_name : null}
                    </h5>
                    <h6>{feedData.position ? feedData.position : null}</h6>
                    <p>
                      <span>
                        <svg
                          width="5"
                          height="6"
                          viewBox="0 0 5 6"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="2.5" cy="3" r="2.5" fill="#C4C4C4" />
                        </svg>
                      </span>
                      an hour ago
                    </p>
                  </div>
                </div>
              </div>
              <div className="feed-view--body-wrapper--area">
                {htmlString !== "" ? (
                  <div className="body">
                    <div dangerouslySetInnerHTML={{ __html: htmlString }} />
                  </div>
                ) : (
                  ""
                )}
                <div className="footer">
                  <p>
                    {feedData.likes ? feedData.likes : "0"}. <span>Likes</span>
                  </p>
                  <p>
                    0. <span>Comments</span>
                  </p>
                  <p>
                    {feedData.shares ? feedData.shares : "0"}.{" "}
                    <span>Shares</span>
                  </p>
                </div>
              </div>
            </div>
            {feedMedia && feedMedia.length !== 0 && feedMedia.length >= 3 ? (
              //first grid position of 3 media attachment and above conditional statement and component
              <div className="feed-view--body--article">
                <div className="media-area1" onClick={toggle}>
                  <ViewFeedImageModal
                    isOpen={isOpen}
                    toggle={toggle}
                    feedMedia={feedMedia}
                    feedData={feedData}
                  ></ViewFeedImageModal>
                  {feedMedia &&
                  feedMedia[0].filename.substring(
                    feedMedia[0].filename.lastIndexOf(".") + 1
                  ) === "mp4" ? (
                    //conditional statement to check for image or video for  grid position
                    <div className="media-video1">
                      <video controls>
                        <source
                          src={
                            feedMedia
                              ? process.env.REACT_APP_IMAGE_API_PREFIX +
                                `${feedMedia[0].filename}`
                              : ""
                          }
                          type="video/mp4"
                        />
                        Your browser does not support HTML5 video.
                      </video>
                    </div>
                  ) : (
                    <div className="media1">
                      <img
                        src={
                          feedMedia
                            ? process.env.REACT_APP_IMAGE_API_PREFIX +
                              `${feedMedia[0].filename}`
                            : ""
                        }
                        alt="article-media"
                      />
                    </div>
                  )}
                  {feedMedia &&
                  feedMedia[1].filename.substring(
                    feedMedia[1].filename.lastIndexOf(".") + 1
                  ) === "mp4" ? (
                    //conditional statement to check for image or video for second grid position
                    <div className="media-video2">
                      <video>
                        <source
                          src={
                            feedMedia
                              ? process.env.REACT_APP_IMAGE_API_PREFIX +
                                `${feedMedia[1].filename}`
                              : ""
                          }
                          type="video/mp4"
                        />
                        Your browser does not support HTML5 video.
                      </video>
                    </div>
                  ) : (
                    <div className="media2">
                      <img
                        src={
                          feedMedia
                            ? process.env.REACT_APP_IMAGE_API_PREFIX +
                              `${feedMedia[1].filename}`
                            : ""
                        }
                        alt="article-media"
                      />
                    </div>
                  )}
                  {feedMedia &&
                  feedMedia[2].filename.substring(
                    feedMedia[2].filename.lastIndexOf(".") + 1
                  ) === "mp4" ? (
                    //conditional statement to check for image or video for third grid position
                    <div className="media-video3">
                      <video>
                        <source
                          src={
                            feedMedia
                              ? process.env.REACT_APP_IMAGE_API_PREFIX +
                                `${feedMedia[2].filename}`
                              : ""
                          }
                          type="video/mp4"
                        />
                        Your browser does not support HTML5 video.
                      </video>
                      {leftOver > 0 ? (
                        <div className="media-text">+{leftOver}</div>
                      ) : null}
                    </div>
                  ) : (
                    <div className="media3">
                      <img
                        src={
                          feedMedia
                            ? process.env.REACT_APP_IMAGE_API_PREFIX +
                              `${feedMedia[2].filename}`
                            : ""
                        }
                        alt="article-media"
                      />
                      {leftOver > 0 ? (
                        <div className="mediaText">+{leftOver}</div>
                      ) : null}
                    </div>
                  )}
                </div>
              </div>
            ) : feedMedia && feedMedia.length > 1 && feedMedia.length < 3 ? (
              //grid of 2 only conditional statement and component
              <div className="feed-view--body--article">
                <div className="media-area2" onClick={toggle}>
                  <ViewFeedImageModal
                    isOpen={isOpen}
                    toggle={toggle}
                    feedMedia={feedMedia}
                    feedData={feedData}
                  ></ViewFeedImageModal>
                  {feedMedia &&
                  feedMedia[0].filename.substring(
                    feedMedia[0].filename.lastIndexOf(".") + 1
                  ) === "mp4" ? (
                    //conditional statement to check for image or video for first grid position
                    <div className="media-video1">
                      <video>
                        <source
                          src={
                            feedMedia
                              ? process.env.REACT_APP_IMAGE_API_PREFIX +
                                `${feedMedia[0].filename}`
                              : ""
                          }
                          type="video/mp4"
                        />
                        Your browser does not support HTML5 video.
                      </video>
                    </div>
                  ) : (
                    <div className="media1">
                      <img
                        src={
                          feedMedia
                            ? process.env.REACT_APP_IMAGE_API_PREFIX +
                              `${feedMedia[0].filename}`
                            : ""
                        }
                        alt="article-media"
                      />
                    </div>
                  )}
                  {feedMedia &&
                  feedMedia[1].filename.substring(
                    feedMedia[1].filename.lastIndexOf(".") + 1
                  ) === "mp4" ? (
                    //conditional statement to check for image or video for second grid position
                    <div className="media-video2">
                      <video>
                        <source
                          src={
                            feedMedia
                              ? process.env.REACT_APP_IMAGE_API_PREFIX +
                                `${feedMedia[1].filename}`
                              : ""
                          }
                          type="video/mp4"
                        />
                        Your browser does not support HTML5 video.
                      </video>
                    </div>
                  ) : (
                    <div className="media2">
                      <img
                        src={
                          feedMedia
                            ? process.env.REACT_APP_IMAGE_API_PREFIX +
                              `${feedMedia[1].filename}`
                            : ""
                        }
                        alt="article-media"
                      />
                    </div>
                  )}
                </div>
              </div>
            ) : feedMedia.length === 1 ? (
              //grid of 1 only conditional statement and component
              <div className="feed-view--body--article1">
                <div className="media-area3" onClick={toggle}>
                  <ViewFeedImageModal
                    isOpen={isOpen}
                    toggle={toggle}
                    feedMedia={feedMedia}
                    feedData={feedData}
                  ></ViewFeedImageModal>
                  {feedMedia &&
                  feedMedia[0].filename.substring(
                    feedMedia[0].filename.lastIndexOf(".") + 1
                  ) === "mp4" ? (
                    //conditional statement to check for image or video for the grid position
                    <div className="media-video1">
                      <video controls>
                        <source
                          src={
                            feedMedia
                              ? process.env.REACT_APP_IMAGE_API_PREFIX +
                                `${feedMedia[0].filename}`
                              : ""
                          }
                          type="video/mp4"
                        />
                        Your browser does not support HTML5 video.
                      </video>
                    </div>
                  ) : (
                    <div className="media1">
                      <img
                        src={
                          feedMedia
                            ? process.env.REACT_APP_IMAGE_API_PREFIX +
                              `${feedMedia[0].filename}`
                            : ""
                        }
                        alt="article-media"
                      />
                    </div>
                  )}
                </div>
              </div>
            ) : null}
          </div>
        ) : (
          <div className="feed-view--body">
            <div className="feed-view--body-wrapper1">
              <div className="feed-view--body-wrapper1--header">
                <div className="profile-area">
                  <div className="avatar">
                    <img src={FeedPic} alt="avatar" />
                  </div>
                  <div className="detail">
                    <h5>
                      {feedData.account_name ? feedData.account_name : ""}
                    </h5>
                    <h6>{feedData.position ? feedData.position : ""}</h6>
                    <p>
                      <span>
                        <svg
                          width="5"
                          height="6"
                          viewBox="0 0 5 6"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="2.5" cy="3" r="2.5" fill="#C4C4C4" />
                        </svg>
                      </span>
                      an hour ago
                    </p>
                  </div>
                </div>
              </div>
              <div className="feed-view--body-wrapper1--area">
                {htmlString !== "" ? (
                  <div className="body">
                    <div dangerouslySetInnerHTML={{ __html: htmlString }} />
                  </div>
                ) : (
                  ""
                )}
                <div className="footer">
                  <p>
                    {feedData.likes ? feedData.likes : "0"}. <span>Likes</span>
                  </p>
                  <p>
                    0. <span>Comments</span>
                  </p>
                  <p>
                    {feedData.shares ? feedData.shares : "0"}.{" "}
                    <span>Shares</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default ViewFeed;
