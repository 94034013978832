import React, { Fragment, useContext, useState, FC } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Modal, Space } from "antd";
import "../../../../styles/system.admin.scss";
import axios from "axios";
import { isAuth, SidebarContext } from "../../../auth/ProtectedRoute";
import swal from "sweetalert";
import { ActivityLogValues } from "../../ActivityLog/types/DataType";

const Duplicate: FC<{ record: { id: number | null; name: string } }> = ({
  record,
}) => {
  const { userName, sidebarData } = useContext(SidebarContext);

  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const showModal = () => {
    setOpen(true);
  };

  const hideModal = () => {
    setOpen(false);
  };

  const formDetails = {
    role_id: record.id,
  };

  const navigate = useNavigate();
  let roleId: number;
  const confirm = async () => {
    setLoading(true);
    await axios({
      method: "POST",
      url: process.env.REACT_APP_HOST_API + `duplicate`,
      headers: {
        Authorization: `Bearer ${isAuth()}`,
      },
      data: formDetails,
    })
      .then((res) => {
        roleId = res.data.data.role.id;
        setLoading(false);
        if (res.data.response === true) {
          let activityLogItem;
          if (typeof window !== "undefined") {
            if (sessionStorage.getItem("activityDetail")) {
              activityLogItem = JSON.parse(
                sessionStorage.getItem("activityDetail")!
              );
            }
          }
          const activityDetail: ActivityLogValues = {
            full_name: activityLogItem.full_name,
            role: activityLogItem.role,
            ip_address: activityLogItem.ip_address,
            browser: activityLogItem.browser,
            events: `Duplicated role: ${record.name} `,
            module: "Admin Roles",
            location: activityLogItem.location,
            email: activityLogItem.email,
          };
          axios({
            method: "POST",
            url: process.env.REACT_APP_HOST_API + "manage-activity/user",
            headers: {
              Authorization: `Bearer ${isAuth()}`,
            },
            data: activityDetail,
          }).then(() => {
            history.go(0);
          });

          swal({
            title: "Success!",
            text: "Role successfully duplicated!",
            icon: "success",
          });
          navigate(`edit-role/${roleId}`, {
            state: { userName: userName, sidebar: sidebarData },
          });
        } else if (res.data.response === false && res.data.status === 404) {
          swal({
            title: "Error!",
            text: res.data.message,
            icon: "error",
          });
        }
      })
      .catch((err) => {
        console.error(err);
        swal({
          title: "Error!",
          text: "Something went wrong. Please try again later!",
          icon: "error",
        });
      });
  };

  return (
    <Fragment>
      <Space>
        <Button className="__btn" onClick={showModal}>
          Duplicate Role
        </Button>
      </Space>
      <Modal closable={false} keyboard={true} open={open} footer={null}>
        <div className="duplicate-modal">
          <svg
            width="100"
            height="100"
            viewBox="0 0 100 100"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M58.8408 3.8389C53.9593 -1.04266 46.0447 -1.04265 41.1632 3.8389L3.84085 41.1612C-1.04071 46.0428 -1.0407 53.9571 3.84085 58.8391L41.1632 96.1611C46.0447 101.043 53.9593 101.043 58.8408 96.1611L96.1633 58.8391C101.045 53.9571 101.045 46.0428 96.1633 41.1612L58.8408 3.8389ZM45.0017 30.0001C45.0017 27.2386 47.2403 25.0001 50.0017 25.0001C52.7633 25.0001 55.0018 27.2386 55.0018 30.0001V50.0001C55.0018 52.7616 52.7633 55.0001 50.0017 55.0001C47.2403 55.0001 45.0017 52.7616 45.0017 50.0001V30.0001ZM55.0018 70.0001C55.0018 72.7616 52.7633 75.0001 50.0017 75.0001C47.2403 75.0001 45.0017 72.7616 45.0017 70.0001C45.0017 67.2386 47.2403 65.0001 50.0017 65.0001C52.7633 65.0001 55.0018 67.2386 55.0018 70.0001Z"
              fill="#FF7A00"
            />
          </svg>
          <h1>Warning Message</h1>
          <p>Please customize your new role</p>
          <div className="duplicate-modal--footer">
            <button
              className="duplicate-modal--footer_cancel"
              onClick={hideModal}
            >
              Cancel
            </button>
            <button className="duplicate-modal--footer_ok" onClick={confirm}>
              {loading ? "Submitting..." : "OK"}
            </button>
          </div>
        </div>
      </Modal>
    </Fragment>
  );
};

export default Duplicate;
