/* eslint-disable */
import React, { useEffect, FC, useState } from "react";
import { useParams } from "react-router-dom";
import "../../../styles/activity.scss";
import { useNavigate } from "react-router-dom";
import { isAuth } from "../../auth/ProtectedRoute";
import axios from "axios";
import swal from "sweetalert";
import Rectangle from "../../../assets/Rectangle.png";
import { ActivityLogValues } from "./types/DataType";
import { DateTime } from "luxon";

const ViewLog: FC = () => {
  const navigate = useNavigate();
  const [log, setLog] = useState<ActivityLogValues>({
    full_name: "",
    role: "",
    ip_address: "",
    browser: "",
    events: "",
    module: "",
    location: "",
    email: "",
    created_at: "",
  });

  const { id } = useParams();

  useEffect(() => {
    axios({
      method: "GET",
      url: process.env.REACT_APP_HOST_API + `manage-activity/user/${id}`,
      headers: {
        Authorization: `Bearer ${isAuth()}`,
      },
    })
      .then((res) => {
        if (res.data.response === true) {
          console.log(res.data);
          setLog(res.data.data);
        } else if (res.data.response === false && res.data.status === 404) {
          swal({
            title: "Error!",
            text: res.data.message,
            icon: "error",
          });
        }
      })
      .catch((err) => {
        swal({
          title: "Error!",
          text: "Something went wrong. Please try again later!",
          icon: "error",
        });
      });
  }, []);
  const dt = DateTime.fromISO(log.created_at!).toFormat("yyyy-MM-dd: HH:mm:ss");

  return (
    <>
      <div className="activity">
        <div className="activity-wrapper">
          <div className="activity--heading">
            <div className="activity--heading--title">
              <h1>View Log</h1>
            </div>
            <div className="activity--heading-btn">
              <button onClick={() => navigate(-1)}>
                <span>
                  <svg
                    width="22"
                    height="20"
                    viewBox="0 0 22 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7 2L2 7L7 12"
                      stroke="white"
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M2 7H10C15.523 7 20 11.477 20 17V18"
                      stroke="white"
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
                Back
              </button>
            </div>
          </div>
          <div className="activity--event">
            <div className="activity--event-header">
              <h1>Event</h1>
            </div>
            <div className="activity--event-info">
              <div className="activity--event-info_user">
                <h1>User Info</h1>
                <div className="activity--event-info_user-detail">
                  <div className="activity--event-info_user-detail-list">
                    <p>Full Name:</p>
                    <p className="user_name">{log.full_name}</p>
                  </div>
                  <div className="activity--event-info_user-detail-list">
                    <p>IpAddress:</p>
                    <p className="user_name">{log.ip_address}</p>
                  </div>
                  <div className="activity--event-info_user-detail-list">
                    <p>Browser:</p>
                    <p className="user_name">{log.browser}</p>
                  </div>
                  <div className="activity--event-info_user-detail-list">
                    <p>Location:</p>
                    <p className="user_name">{log.location}</p>
                  </div>
                </div>
              </div>
              <div className="activity--event-info_action">
                <h1>Action Information</h1>
                <div className="activity--event-info_action-detail">
                  <div className="activity--event-info_action-detail-list">
                    <p className="action_name">Action:</p>
                    <p>{log.events}</p>
                  </div>
                  <div className="activity--event-info_action-detail-list">
                    <p className="action_name">Module:</p>
                    <p className="event_module">{log.module}</p>
                  </div>
                  <div className="activity--event-info_action-detail-list">
                    <p className="action_name">Date:</p>
                    <p>{dt}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ViewLog;
