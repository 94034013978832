import React from "react";
import { Outlet } from "react-router-dom";

const AuthLayout = () => {
  return (
    < div className="flex justify-between min-h-[100vh] bg-[#FAFAFA] font-primary">
     <Outlet/>
    </div>
  );
};

export default AuthLayout;