import React, { useState, ChangeEvent } from "react"
import { useQuery } from "@tanstack/react-query"
import { Chart, AxisOptions } from "react-charts"
import { Paper, Typography, Box, TextField, Stack, CircularProgress, Alert } from "@mui/material"
import { isAuth } from "../../../../auth/ProtectedRoute"

// Updated interfaces to match the API response
interface DataPoint {
  date: string
  publishedCourses: number
  draftCourses: number
  revenue: number
  courseCompleted: number
}

interface ApiResponse {
  response: boolean
  status: string
  message: string
  code: number
  data: {
    publishedCourses: Array<{ date: string; publishedCourses: number }>
    draftCourses: Array<{ date: string; draftCourses: number }>
    revenue: Array<{ date: string; revenue: number }>
    courseCompleted: Array<{ date: string; courseCompleted: number }>
  }
}

interface FetchMetricsParams {
  startYear: number
  startMonth: number
  startDay: number
  endYear: number
  endMonth: number
  endDay: number
}

const fetchMetrics = async ({
  startYear,
  startMonth,
  startDay,
  endYear,
  endMonth,
  endDay,
}: FetchMetricsParams) => {
  const response = await fetch(
    `${process.env.REACT_APP_HOST_API}learning/analytics/graph?start_year=${startYear}&start_month=${startMonth}&start_day=${startDay}&end_year=${endYear}&end_month=${endMonth}&end_day=${endDay}`,
    {
      headers: {
        Authorization: `Bearer ${isAuth()}`,
      },
    }
  )
  if (!response.ok) {
    throw new Error("Network response was not ok")
  }
  return response.json()
}

const transformApiData = (apiData: ApiResponse): DataPoint[] => {
  const dataMap = new Map<string, DataPoint>()

  // Helper function to format date to mm/dd/yyyy
  const formatDate = (date: Date) =>
    date.toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    })

  // Collect all unique dates between start and end
  const collectDates = (data: any[]) => {
    const dates = data.map(item => new Date(item.date))
    const minDate = new Date(Math.min(...dates.map(d => d.getTime())))
    const maxDate = new Date(Math.max(...dates.map(d => d.getTime())))

    const allDates: Date[] = []
    const currentDate = new Date(minDate)
    while (currentDate <= maxDate) {
      allDates.push(new Date(currentDate))
      currentDate.setDate(currentDate.getDate() + 1)
    }

    return allDates
  }

  // Collect all dates first from all data sets
  const allDates = collectDates([
    ...apiData.data.publishedCourses,
    ...apiData.data.draftCourses,
    ...apiData.data.revenue,
    ...apiData.data.courseCompleted,
  ])

  // Populate dataMap with all dates, defaulting to 0 for metrics
  allDates.forEach(date => {
    const dateStr = formatDate(date)
    dataMap.set(dateStr, {
      date: dateStr,
      publishedCourses: 0,
      draftCourses: 0,
      revenue: 0,
      courseCompleted: 0,
    })
  })

  // Populate actual data
  apiData.data.publishedCourses.forEach(item => {
    const date = new Date(item.date)
    const dateStr = formatDate(date)
    if (dataMap.has(dateStr)) {
      dataMap.get(dateStr)!.publishedCourses = item.publishedCourses
    }
  })

  apiData.data.draftCourses.forEach(item => {
    const date = new Date(item.date)
    const dateStr = formatDate(date)
    if (dataMap.has(dateStr)) {
      dataMap.get(dateStr)!.draftCourses = item.draftCourses
    }
  })

  apiData.data.revenue.forEach(item => {
    const date = new Date(item.date)
    const dateStr = formatDate(date)
    if (dataMap.has(dateStr)) {
      dataMap.get(dateStr)!.revenue = item.revenue
    }
  })

  apiData.data.courseCompleted.forEach(item => {
    const date = new Date(item.date)
    const dateStr = formatDate(date)
    if (dataMap.has(dateStr)) {
      dataMap.get(dateStr)!.courseCompleted = item.courseCompleted
    }
  })

  return Array.from(dataMap.values()).sort(
    (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
  )
}

const LearningMetricsChart: React.FC = () => {
  const defaultStartDate = new Date()
  defaultStartDate.setDate(defaultStartDate.getDate() - 30)
  const defaultEndDate = new Date()

  const [startDate, setStartDate] = useState(defaultStartDate.toISOString().split("T")[0])
  const [endDate, setEndDate] = useState(defaultEndDate.toISOString().split("T")[0])

  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["learningMetrics", startDate, endDate],
    queryFn: () => {
      const start = new Date(startDate)
      const end = new Date(endDate)
      return fetchMetrics({
        startYear: start.getFullYear(),
        startMonth: start.getMonth() + 1,
        startDay: start.getDate(),
        endYear: end.getFullYear(),
        endMonth: end.getMonth() + 1,
        endDay: end.getDate(),
      })
    },
    select: transformApiData,
  })

  const handleStartDateChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newStartDate = event.target.value
    if (new Date(newStartDate) <= new Date(endDate)) {
      setStartDate(newStartDate)
    }
  }

  const handleEndDateChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newEndDate = event.target.value
    if (new Date(newEndDate) >= new Date(startDate)) {
      setEndDate(newEndDate)
    }
  }

  const chartData = React.useMemo(() => {
    if (!data) return []
    return [
      {
        label: "Published Courses",
        data: data.map(item => ({
          primary: new Date(item.date),
          secondary: item.publishedCourses,
        })),
      },
      {
        label: "Draft Courses",
        data: data.map(item => ({
          primary: new Date(item.date),
          secondary: item.draftCourses,
        })),
      },
      {
        label: "Revenue",
        data: data.map(item => ({
          primary: new Date(item.date),
          secondary: item.revenue,
        })),
      },
      {
        label: "Completed Courses",
        data: data.map(item => ({
          primary: new Date(item.date),
          secondary: item.courseCompleted,
        })),
      },
    ]
  }, [data])

  const primaryAxis = React.useMemo<AxisOptions<{ primary: Date; secondary: number }>>(
    () => ({
      getValue: datum => datum.primary,
      scaleType: "time",
      formatters: {
        scale: (value: Date | null) => {
          if (value === null || value === undefined) {
            return "N/A"
          }
          return value.toLocaleDateString("en-US", {
            month: "short",
            day: "numeric",
            year: "numeric",
          })
        },
      },
    }),
    []
  )

  const secondaryAxes = React.useMemo<AxisOptions<{ primary: Date; secondary: number }>[]>(
    () => [
      {
        getValue: datum => datum.secondary,
        elementType: "line",
        formatters: {
          scale: (value: number | null | undefined) => value?.toString() || "",
          tick: (value: number | null | undefined) => value?.toString() || "",
        },
        hardMin: 0,
        ticks: 10,
        stacked: false,
        tickFormat: (value: number | null | undefined) => {
          if (value !== null && value !== undefined) {
            const roundedValue = Math.round(value)
            return roundedValue.toString()
          } else {
            return ""
          }
        },
      },
    ],
    []
  )

  return (
    <Paper
      elevation={2}
      sx={{
        width: "100%",
        maxWidth: "1200px",
        p: 4,
      }}
    >
      <Box sx={{ mb: 4 }}>
        <Typography variant="h5" gutterBottom sx={{ fontWeight: 600 }}>
          Learning Summary
        </Typography>
        <Typography variant="subtitle1" color="text.secondary" gutterBottom>
          Daily tracking of courses, revenue, and completion rates
        </Typography>
        <Stack direction={{ xs: "column", sm: "row" }} spacing={3} sx={{ mt: 3 }}>
          <TextField
            type="date"
            value={startDate}
            onChange={handleStartDateChange}
            InputLabelProps={{ shrink: true }}
            sx={{ minWidth: 200 }}
          />
          <TextField
            label="End Date"
            type="date"
            value={endDate}
            onChange={handleEndDateChange}
            InputLabelProps={{ shrink: true }}
            sx={{ minWidth: 200 }}
          />
        </Stack>
      </Box>

      {isError && (
        <Alert severity="error" sx={{ mb: 3 }}>
          Error loading data: {(error as Error)?.message || "Unknown error occurred"}
        </Alert>
      )}

      <Box sx={{ height: 500, width: "100%", position: "relative" }}>
        {isLoading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Chart
            options={{
              data: chartData,
              primaryAxis,
              secondaryAxes,
            }}
          />
        )}
      </Box>
    </Paper>
  )
}

export default LearningMetricsChart
