import React, { Fragment, useEffect, useState, FC } from "react";
import { Button, Modal, Space } from "antd";
import "../../../../styles/modals.scss";
import axios from "axios";
import { isAuth } from "../../../auth/ProtectedRoute";
import { Formik, Form, Field, ErrorMessage, FormikHelpers } from "formik";
import { SubmoduleValue } from "../types/DataType";
import * as Yup from "yup";
import swal from "sweetalert";
import { ActivityLogValues } from "../../ActivityLog/types/DataType";

const EditSubmodule: FC<{
  record: { id: number | null; name: string };
}> = ({ record }) => {
  const [name, setName] = useState<string>("");
  const [route, setRoute] = useState<string>("");
  const [module, setModule] = useState<number | null>(null);
  const [modules, setModules] = useState([]);
  const [open, setOpen] = useState<boolean>(false);
  const showModal = () => {
    setOpen(true);
  };

  const hideModal = () => {
    setOpen(false);
  };

  useEffect(() => {
    axios({
      method: "GET",
      url: process.env.REACT_APP_HOST_API + `sub-module/${record.id}`,
      headers: {
        Authorization: `Bearer ${isAuth()}`,
      },
    })
      .then((res) => {
        if (res.data[0].response === true) {
          setName(res.data[0].data.name);
          setRoute(res.data[0].data.route);
          setModule(res.data[0].data.module_id);
        }
      })
      .catch((err) => {
        console.error(err);
        swal({
          title: "Error!",
          text: "Something went wrong. Please try again later!",
          icon: "error",
        });
      });
  }, []);

  useEffect(() => {
    axios({
      method: "GET",
      url: process.env.REACT_APP_HOST_API + `module`,
      headers: {
        Authorization: `Bearer ${isAuth()}`,
      },
    })
      .then((res) => {
        setModules(res.data.data.data);
      })
      .catch((err) => {
        console.error(err);
        swal({
          title: "Error!",
          text: "Something went wrong. Please try again later!",
          icon: "error",
        });
      });
  }, []);

  const initialValues: SubmoduleValue = {
    submoduleName: name,
    route: route,
    moduleId: module,
  };

  return (
    <Fragment>
      <Space>
        <Button className="__btn" onClick={showModal}>
          Edit Sub-module
        </Button>
      </Space>
      <Modal closable={false} keyboard={true} open={open} footer={null}>
        <div className="clicks-mod">
          <div className="clicks-mod--right">
            <div className="modal-overlay-mod">
              <div className="modal-box-mod">
                <div className="modal-box-mod--wrapper">
                  <div className="heading">
                    <h1>Edit Sub-module</h1>
                    <div onClick={hideModal} style={{ cursor: "pointer" }}>
                      <svg
                        width="35"
                        height="32"
                        viewBox="0 0 35 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M18.5995 16.0001L23.2722 11.3273C23.5763 11.0233 23.5763 10.5319 23.2722 10.228C22.9682 9.92401 22.4768 9.92401 22.1729 10.228L17.5001 14.9008L12.8274 10.228C12.5233 9.92401 12.032 9.92401 11.7281 10.228C11.424 10.5319 11.424 11.0233 11.7281 11.3273L16.4007 16.0001L11.7281 20.6729C11.424 20.9768 11.424 21.4683 11.7281 21.7722C11.8795 21.9238 12.0787 22 12.2777 22C12.4768 22 12.6758 21.9238 12.8274 21.7722L17.5001 17.0994L22.1729 21.7722C22.3245 21.9238 22.5235 22 22.7225 22C22.9216 22 23.1206 21.9238 23.2722 21.7722C23.5763 21.4683 23.5763 20.9768 23.2722 20.6729L18.5995 16.0001Z"
                          fill="black"
                          stroke="black"
                        />
                      </svg>
                    </div>
                  </div>
                  <div className="form-wrap">
                    <Formik
                      initialValues={initialValues}
                      enableReinitialize={true}
                      validationSchema={Yup.object().shape({
                        submoduleName: Yup.string().required(
                          "This field is required"
                        ),
                        route: Yup.string().required("This field is required"),
                        moduleId: Yup.number().required(
                          "This field is required"
                        ),
                      })}
                      onSubmit={(
                        values: SubmoduleValue,
                        { setSubmitting }: FormikHelpers<SubmoduleValue>
                      ) => {
                        const FormDetails = {
                          name: values.submoduleName,
                          route: values.route,
                          status: "1",
                          module_id: values.moduleId,
                        };
                        axios({
                          method: "PATCH",
                          url:
                            process.env.REACT_APP_HOST_API +
                            `sub-module/${record.id}`,
                          headers: {
                            Authorization: `Bearer ${isAuth()}`,
                          },
                          data: FormDetails,
                        })
                          .then((res) => {
                            setSubmitting(false);
                            if (res.data[0].response === true) {
                              let activityLogItem;
                              if (typeof window !== "undefined") {
                                if (sessionStorage.getItem("activityDetail")) {
                                  activityLogItem = JSON.parse(
                                    sessionStorage.getItem("activityDetail")!
                                  );
                                }
                              }
                              const activityDetail: ActivityLogValues = {
                                full_name: activityLogItem.full_name,
                                role: activityLogItem.role,
                                ip_address: activityLogItem.ip_address,
                                browser: activityLogItem.browser,
                                events: `Edited sub-module: ${values.submoduleName}`,
                                module: "Sub-modules",
                                location: activityLogItem.location,
                                email: activityLogItem.email,
                              };
                              axios({
                                method: "POST",
                                url:
                                  process.env.REACT_APP_HOST_API +
                                  "manage-activity/user",
                                headers: {
                                  Authorization: `Bearer ${isAuth()}`,
                                },
                                data: activityDetail,
                              }).then(() => {
                                history.go(0);
                              });
                              swal({
                                title: "Success!",
                                text: "Sub-module edited successfully!",
                                icon: "success",
                              });
                            } else if (
                              res.data[0].response === false &&
                              res.data[0].status === 409
                            ) {
                              swal({
                                title: "Error!",
                                text: res.data.message,
                                icon: "error",
                              });
                            }
                          })
                          .catch((err) => {
                            console.log(err);
                            swal({
                              title: "Error!",
                              text: "Something went wrong. Please try again later!",
                              icon: "error",
                            });
                          });
                      }}
                    >
                      {({ isSubmitting }) => (
                        <>
                          <Form noValidate autoComplete="off">
                            <div className="form-wrap--submodule">
                              <div className="form-wrap--submodule_cell">
                                <label htmlFor="submoduleName">
                                  Sub-Module Name
                                </label>
                                <Field
                                  id="submoduleName"
                                  name="submoduleName"
                                  type="text"
                                  placeholder="e.g Users"
                                />
                                <ErrorMessage name="submoduleName">
                                  {(msg) => (
                                    <div style={{ color: "red" }}>{msg}</div>
                                  )}
                                </ErrorMessage>
                              </div>
                              <div className="form-wrap--submodule_cell">
                                <label htmlFor="route">Route</label>
                                <Field
                                  id="route"
                                  name="route"
                                  type="text"
                                  placeholder="input route"
                                />
                                <ErrorMessage name="route">
                                  {(msg) => (
                                    <div style={{ color: "red" }}>{msg}</div>
                                  )}
                                </ErrorMessage>
                              </div>
                            </div>
                            <div className="form-wrap--submodule">
                              <div className="form-wrap--submodule_cell">
                                <label htmlFor="moduleId">Module</label>
                                <Field
                                  as="select"
                                  id="module"
                                  name="moduleId"
                                  type="text"
                                  placeholder="e.g User Management"
                                >
                                  {modules ? (
                                    modules.map((module: any) => (
                                      <>
                                        <option
                                          value=""
                                          disabled
                                          selected
                                          hidden
                                        >
                                          --Please choose an option--
                                        </option>
                                        <option value={module.id}>
                                          {module.name}
                                        </option>
                                      </>
                                    ))
                                  ) : (
                                    <option value="" disabled>
                                      Options not available
                                    </option>
                                  )}
                                </Field>
                                <ErrorMessage name="moduleId">
                                  {(msg) => (
                                    <div style={{ color: "red" }}>{msg}</div>
                                  )}
                                </ErrorMessage>
                              </div>
                            </div>
                            <div className="form-wrap--bottom">
                              <div className="btns">
                                <div className="btns--wrap">
                                  <button className="reset" type="reset">
                                    Reset
                                  </button>
                                  <button
                                    disabled={isSubmitting}
                                    type="submit"
                                    className="save"
                                  >
                                    {isSubmitting ? "Submitting" : "Save"}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </Form>
                        </>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </Fragment>
  );
};

export default EditSubmodule;
