import React, { Fragment, useEffect, FC, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "../../../styles/system.admin.scss";
import { Formik, Form, Field, FormikHelpers, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { isAuth } from "../../auth/ProtectedRoute";
import swal from "sweetalert";
import { EditGroupValue } from "./types/DataTypes";
import { ActivityLogValues } from "../ActivityLog/types/DataType";

const EditGroup: FC = () => {
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [groupData, setGroupData] = useState<EditGroupValue>({
    name: "",
    privacy: "",
    email: "",
    category: "",
    image: "",
    cover_image: "",
    type: "",
    description: "",
  });
  const [groupImage, setGroupImage] = useState("");
  const [groupCoverImage, setGroupCoverImage] = useState("");

  const params = useParams();
  const FILE_SIZE = 1024 * 1024 * 2;
  const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

  //request to get group data from the server
  useEffect(() => {
    axios({
      method: "GET",
      url: process.env.REACT_APP_HOST_API + `manage-groups/group/${params.id}`,
      headers: {
        Authorization: `Bearer ${isAuth()}`,
      },
    })
      .then((res) => {
        setGroupData(res.data.data);
        setGroupImage(res.data.data.image);
        setGroupCoverImage(res.data.data.cover_image);
      })
      .catch((err) => {
        console.error(err);
        swal({
          title: "Error!",
          text: "Something went wrong. Please try again later!",
          icon: "error",
        });
      });
  }, []);

  //request to get category data from the server
  useEffect(() => {
    axios({
      method: "GET",
      url: process.env.REACT_APP_HOST_API + `category`,
      headers: {
        Authorization: `Bearer ${isAuth()}`,
      },
    })
      .then((res) => {
        setCategories(res.data.data.data);
      })
      .catch((err) => {
        console.error(err);
        swal({
          title: "Error!",
          text: "Something went wrong. Please try again later!",
          icon: "error",
        });
      });
  }, []);

  //formik form initial value already pre-populated with group data from the server
  const initialValues: EditGroupValue = {
    name: groupData!.name,
    privacy: groupData!.privacy,
    email: groupData!.email,
    category: groupData!.category,
    image: groupData!.image,
    cover_image: groupData!.cover_image,
    type: groupData!.type,
    description: groupData!.description,
  };

  return (
    <Fragment>
      <div className="admin-edit">
        <div className="admin-edit-wrapper">
          <div className="admin-edit--heading">
            <div className="admin-edit--heading--title">
              <h1>Edit Group</h1>
            </div>
            <div className="admin-edit--heading-btn">
              <button onClick={() => navigate(-1)}>
                <span>
                  <svg
                    width="22"
                    height="20"
                    viewBox="0 0 22 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7 2L2 7L7 12"
                      stroke="white"
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M2 7H10C15.523 7 20 11.477 20 17V18"
                      stroke="white"
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </span>
                Back
              </button>
            </div>
          </div>
        </div>
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={Yup.object().shape({
            name: Yup.string().required("This field is required"),
            privacy: Yup.string().required("This field is required"),
            email: Yup.string()
              .email("Invalid email address")
              .required("This field is required"),
            category: Yup.string().required("This field is required"),
            image: Yup.mixed()
              .required("You need to upload a file")
              .test(
                "image-validation",
                "File size/type is not supported",
                (value) => {
                  if (!value) return true;
                  if (typeof value === "string") return true;
                  if (!(value instanceof File)) return false;

                  const isCorrectSize = value.size <= FILE_SIZE;
                  const isCorrectType = SUPPORTED_FORMATS.includes(value.type);

                  return isCorrectSize && isCorrectType;
                }
              ),
            cover_image: Yup.mixed()
              .required("You need to upload a file")
              .test(
                "image-validation",
                "File size/type is not supported",
                (value) => {
                  if (!value) return true;
                  if (typeof value === "string") return true;
                  if (!(value instanceof File)) return false;

                  const isCorrectSize = value.size <= FILE_SIZE;
                  const isCorrectType = SUPPORTED_FORMATS.includes(value.type);

                  return isCorrectSize && isCorrectType;
                }
              ),
            type: Yup.string().required("This field is required"),
            description: Yup.string().required("This field is required"),
          })}
          onSubmit={(
            values: EditGroupValue,
            { setSubmitting }: FormikHelpers<EditGroupValue>
          ) => {
            //payload for group data to be submitted to the server
            const fromData: any = new FormData();
            fromData.append("name", values.name);
            fromData.append("privacy", values.privacy);
            fromData.append("admin", values.email);
            fromData.append("category", values.category);
            fromData.append("image", groupImage);
            fromData.append("cover_image", groupCoverImage);
            fromData.append("type", values.type);
            fromData.append("description", values.description);
            fromData.append("_method", "PATCH");

            //request to submit values to server
            axios({
              method: "POST",
              url:
                process.env.REACT_APP_HOST_API +
                `manage-groups/group/${params.id}`,
              headers: {
                Authorization: `Bearer ${isAuth()}`,
              },
              data: fromData,
            })
              .then((res) => {
                setSubmitting(false);
                if (res.data.response === true) {
                  //activity log request submitted to server
                  let activityLogItem;
                  if (typeof window !== "undefined") {
                    if (sessionStorage.getItem("activityDetail")) {
                      activityLogItem = JSON.parse(
                        sessionStorage.getItem("activityDetail")!
                      );
                    }
                  }
                  const activityDetail: ActivityLogValues = {
                    full_name: activityLogItem.full_name,
                    role: activityLogItem.role,
                    ip_address: activityLogItem.ip_address,
                    browser: activityLogItem.browser,
                    events: `Edited Group: ${values.name}`,
                    module: "Content Management",
                    location: activityLogItem.location,
                    email: activityLogItem.email,
                  };
                  axios({
                    method: "POST",
                    url:
                      process.env.REACT_APP_HOST_API + "manage-activity/user",
                    headers: {
                      Authorization: `Bearer ${isAuth()}`,
                    },
                    data: activityDetail,
                  });
                  swal({
                    title: "Success!",
                    text: "Group edited successfully!",
                    icon: "success",
                  });
                  navigate(-1);
                } else if (
                  // server error 401 handling
                  res.data.response === false &&
                  res.data.status === 401
                ) {
                  swal({
                    title: "Error!",
                    text: res.data.message,
                    icon: "error",
                  });
                } else if (
                  // server error 406 handling
                  res.data.response === false &&
                  res.data.status === 406
                ) {
                  swal({
                    title: "Error!",
                    text: res.data.message,
                    icon: "error",
                  });
                } else if (
                  // server error 409 handling
                  res.data.response === false &&
                  res.data.status === 409
                ) {
                  swal({
                    title: "Error!",
                    text: res.data.message,
                    icon: "error",
                  });
                } else if (
                  // server error 500 handling
                  res.data.response === false &&
                  res.data.status === 500
                ) {
                  swal({
                    title: "Error!",
                    text: "Something went wrong. Please try again later!",
                    icon: "error",
                  });
                }
              })
              .catch((err) => {
                console.error(err);
                swal({
                  title: "Error!",
                  text: "Something went wrong. Please try again later!",
                  icon: "error",
                });
              });
          }}
        >
          {({ setFieldValue, isSubmitting }) => (
            <>
              {/* <pre>{JSON.stringify({ values, errors }, null, 2)}</pre> */}
              <Form noValidate autoComplete="off">
                <div className="admin-edit--group">
                  <div className="admin-edit--group-form">
                    <div className="right">
                      <label htmlFor="name">Group Name</label>
                      <Field
                        id="name"
                        name="name"
                        type="text"
                        placeholder="Enter group name"
                      />
                      <ErrorMessage name="name">
                        {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="left">
                      <label htmlFor="role">Select Privacy</label>
                      <Field
                        as="select"
                        id="privacy"
                        name="privacy"
                        type="text"
                      >
                        <>
                          <option value="" disabled selected hidden>
                            --Please choose an option--
                          </option>
                          <option value="public">Public</option>
                          <option value="private">Private</option>
                        </>
                      </Field>
                      <ErrorMessage name="privacy">
                        {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                      </ErrorMessage>
                    </div>
                  </div>
                  <div className="admin-edit--group-form">
                    <div className="right">
                      <label htmlFor="email">Select Admin(Email only)</label>
                      <Field
                        id="email"
                        name="email"
                        type="text"
                        placeholder="example@email.com"
                      />
                      <ErrorMessage name="email">
                        {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="left">
                      <label htmlFor="category">Select Category</label>
                      <Field
                        as="select"
                        id="category"
                        name="category"
                        type="text"
                      >
                        {categories ? (
                          categories.map((cat: any) => (
                            //map categories from server to select element
                            <>
                              <option value="" disabled selected hidden>
                                --Please choose an option--
                              </option>
                              <option value={cat.name}>{cat.name}</option>
                            </>
                          ))
                        ) : (
                          <option value="" disabled>
                            Options not available
                          </option>
                        )}
                      </Field>
                      <ErrorMessage name="category">
                        {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                      </ErrorMessage>
                    </div>
                  </div>
                  <div className="admin-edit--group-form">
                    <div className="left">
                      <label htmlFor="image">
                        Image(JPEG, JPG, PNG)
                        <Field
                          id="image"
                          name="image"
                          type="file"
                          accept="image/png, image/jpg, image/jpeg"
                          value={""}
                          style={{ display: "none" }}
                          onChange={(e: any) => {
                            setFieldValue("image", e.currentTarget.files[0]);
                            //error handling & validation for media uploads request to server
                            const file = e.currentTarget.files[0];
                            const fileSize = Math.round(file.size / 1024);
                            if (fileSize > 5120) {
                              swal({
                                title: "Error!",
                                icon: "error",
                                text: "Selected image must be less than 5MB",
                              });
                              return;
                            } else {
                              //media upload request to server
                              const payload = new FormData();
                              payload.append(
                                "media[]",
                                e.currentTarget.files[0]
                              );
                              axios({
                                method: "POST",
                                url:
                                  process.env.REACT_APP_HOST_API +
                                  `uploads/file`,
                                headers: {
                                  Authorization: `Bearer ${isAuth()}`,
                                },
                                data: payload,
                              })
                                .then((res) => {
                                  setGroupImage(res.data[0].filepath);
                                })
                                .catch((error) => {
                                  console.error(error);
                                });
                            }
                          }}
                        />
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <input
                            type="text"
                            id="imageInput"
                            value={groupImage}
                            style={{ width: "84%" }}
                          />
                          <span id="imageButton">Choose file</span>
                        </div>
                      </label>
                      <ErrorMessage name="image">
                        {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="left">
                      <label htmlFor="cover_image">
                        Cover Image(JPEG, JPG, PNG)
                        <Field
                          id="cover_image"
                          name="cover_image"
                          type="file"
                          accept="image/png, image/jpg, image/jpeg"
                          value={""}
                          style={{ display: "none" }}
                          onChange={(e: any) => {
                            setFieldValue(
                              "cover_image",
                              e.currentTarget.files[0]
                            );
                            //error handling & validation for media uploads request to server
                            const file = e.currentTarget.files[0];
                            const fileSize = Math.round(file.size / 1024);
                            if (fileSize > 5120) {
                              swal({
                                title: "Error!",
                                icon: "error",
                                text: "Selected image must be less than 5MB",
                              });
                              // setFieldError("image", errors.image);
                              return;
                            } else {
                              //media upload request to server
                              const payload = new FormData();
                              payload.append(
                                "media[]",
                                e.currentTarget.files[0]
                              );
                              axios({
                                method: "POST",
                                url:
                                  process.env.REACT_APP_HOST_API +
                                  `uploads/file`,
                                headers: {
                                  Authorization: `Bearer ${isAuth()}`,
                                },
                                data: payload,
                              })
                                .then((res) => {
                                  setGroupCoverImage(res.data[0].filepath);
                                })
                                .catch((error) => {
                                  console.error(error);
                                });
                            }
                          }}
                        />
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <input
                            type="text"
                            id="imageInput"
                            value={groupCoverImage}
                            style={{ width: "84%" }}
                          />
                          <span id="imageButton">Choose file</span>
                        </div>
                      </label>
                      <ErrorMessage name="cover_image">
                        {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                      </ErrorMessage>
                    </div>
                  </div>
                  <div className="admin-edit--group-form">
                    <div className="right">
                      <label htmlFor="type">Select Type</label>
                      <Field as="select" id="type" name="type" type="text">
                        <>
                          <option value="" disabled selected hidden>
                            --Please choose an option--
                          </option>
                          <option value="social">Social</option>
                          <option value="professional">Professional</option>
                        </>
                      </Field>
                      <ErrorMessage name="type">
                        {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                      </ErrorMessage>
                    </div>
                    <div className="left"></div>
                  </div>
                  <div className="admin-edit--desc">
                    <label htmlFor="description">Description</label>
                    <Field
                      as="textarea"
                      name="description"
                      placeholder="Tell us about the group..."
                    />
                    <ErrorMessage name="description">
                      {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                    </ErrorMessage>
                  </div>
                </div>
                <div className="admin-edit--btn">
                  <button type="submit">
                    {isSubmitting ? "Submitting..." : "Submit"}
                  </button>
                </div>
              </Form>
            </>
          )}
        </Formik>
      </div>
    </Fragment>
  );
};

export default EditGroup;
