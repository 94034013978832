import React, { FC, useContext } from "react";
import { SidebarContext } from "../../../auth/ProtectedRoute";
import { Link } from "react-router-dom";
import { OngoingProjectDataType } from "../type/DataType";

const ProjectViewButton: FC<{ record: OngoingProjectDataType }> = ({
  record,
}) => {
  const { userName, sidebarData } = useContext(SidebarContext);

  return (
    <div>
      <Link
        to={`view-order/${record.order_id}`}
        state={{ userName: userName, sidebar: sidebarData }}
      >
        <button className="user__btn">View Order</button>
      </Link>
    </div>
  );
};
export default ProjectViewButton;
