import React, { FC } from "react";
import { Link } from "react-router-dom";
import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import SupportMessageTable from "../UserManagement/tables/SupportMessageTable";

const UserPurposeMessage: FC = () => {
  return (
    <div className="user">
      <div className="user__edit">
        <div className="user__edit--heading">
          <h1>Support Message</h1>
        </div>
      </div>
      <div className="user__middle">
        <div className="user__middle-search">
          <Input
            size="large"
            placeholder="Search List"
            prefix={<SearchOutlined />}
            className="input"
          />
        </div>
      </div>
      <div>
        <SupportMessageTable />
      </div>
    </div>
  );
};

export default UserPurposeMessage;
