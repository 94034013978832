import React from "react"
import { useQuery } from "@tanstack/react-query"
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Typography,
  CircularProgress,
  Alert,
} from "@mui/material"
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward"
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward"
import { format } from "date-fns"
import { isAuth } from "../../../../auth/ProtectedRoute"

interface Category {
  id: number
  other_category_id: number
  name: string
  image: string
}

interface Course {
  id: number
  instructor: string
  title: string
  category: Category | string
  date_posted: string
}

interface ApiResponse {
  response: boolean
  status: string
  message: string
  code: number
  data: Course[]
}

interface InstructorTableProps {
  onSort?: (column: keyof Course) => void
  sortColumn?: keyof Course
  sortDirection?: "asc" | "desc"
}

// Helper function to safely render category
const renderCategory = (category: Category | string): string => {
  if (typeof category === "string") {
    return category
  }
  return category?.name || "Uncategorized"
}

// API fetch function with proper error handling
const fetchCourses = async (): Promise<ApiResponse> => {
  const response = await fetch(`${process.env.REACT_APP_HOST_API}learning/analytics/courses`, {
    headers: {
      Authorization: `Bearer ${isAuth()}`,
    },
  })
  if (!response.ok) {
    throw new Error("Failed to fetch courses")
  }
  const data = await response.json()
  // Ensure the data has the expected structure
  if (!data.data || !Array.isArray(data.data)) {
    throw new Error("Invalid data format received from server")
  }
  return data
}

const InstructorTable: React.FC<InstructorTableProps> = ({ onSort, sortColumn, sortDirection }) => {
  const { data, isLoading, isError, error } = useQuery<ApiResponse, Error>({
    queryKey: ["courses"],
    queryFn: fetchCourses,
    retry: 1,
  })

  const renderSortIcon = (column: keyof Course) => {
    if (sortColumn !== column) return null
    return sortDirection === "asc" ? (
      <ArrowUpwardIcon fontSize="small" />
    ) : (
      <ArrowDownwardIcon fontSize="small" />
    )
  }

  const formatDate = (dateString: string) => {
    try {
      return format(new Date(dateString), "dd MMM, yyyy")
    } catch (e) {
      return dateString // Fallback if date is invalid
    }
  }

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    )
  }

  if (isError) {
    return (
      <Alert severity="error">
        Error loading courses: {error?.message || "Unknown error occurred"}
      </Alert>
    )
  }

  // Ensure we have data before rendering
  if (!data?.data || data.data.length === 0) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <Typography>No courses available</Typography>
      </Box>
    )
  }

  return (
    <Box>
      <TableContainer component={Paper} elevation={0}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell onClick={() => onSort?.("instructor")} style={{ cursor: "pointer" }}>
                <Box
                  display="flex"
                  alignItems="center"
                  style={{
                    fontFamily: "Poppins",
                    fontStyle: "normal",
                    fontWeight: 600,
                    fontSize: "18px",
                    lineHeight: "27px",
                    color: "#000000",
                  }}
                >
                  Instructor
                  {renderSortIcon("instructor")}
                </Box>
              </TableCell>
              <TableCell onClick={() => onSort?.("title")} style={{ cursor: "pointer" }}>
                <Box
                  display="flex"
                  alignItems="center"
                  style={{
                    fontFamily: "Poppins",
                    fontStyle: "normal",
                    fontWeight: 600,
                    fontSize: "18px",
                    lineHeight: "27px",
                    color: "#000000",
                  }}
                >
                  Course
                  {renderSortIcon("title")}
                </Box>
              </TableCell>
              <TableCell onClick={() => onSort?.("category")} style={{ cursor: "pointer" }}>
                <Box
                  display="flex"
                  alignItems="center"
                  style={{
                    fontFamily: "Poppins",
                    fontStyle: "normal",
                    fontWeight: 600,
                    fontSize: "18px",
                    lineHeight: "27px",
                    color: "#000000",
                  }}
                >
                  Category
                  {renderSortIcon("category")}
                </Box>
              </TableCell>
              <TableCell onClick={() => onSort?.("date_posted")} style={{ cursor: "pointer" }}>
                <Box
                  display="flex"
                  alignItems="center"
                  style={{
                    fontFamily: "Poppins",
                    fontStyle: "normal",
                    fontWeight: 600,
                    fontSize: "18px",
                    lineHeight: "27px",
                    color: "#000000",
                  }}
                >
                  Date Posted
                  {renderSortIcon("date_posted")}
                </Box>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.data.map((course: Course) => (
              <TableRow
                key={course.id}
                sx={{ "&:nth-of-type(odd)": { backgroundColor: "#f8f9fa" } }}
              >
                <TableCell
                  style={{
                    fontFamily: "Poppins",
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: "16px",
                    lineHeight: "27px",
                    color: "#000000",
                  }}
                >
                  {course.instructor}
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: "Poppins",
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: "16px",
                    lineHeight: "27px",
                    color: "#000000",
                  }}
                >
                  {course.title}
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: "Poppins",
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: "16px",
                    lineHeight: "27px",
                    color: "#000000",
                  }}
                >
                  {renderCategory(course.category)}
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: "Poppins",
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: "16px",
                    lineHeight: "27px",
                    color: "#000000",
                  }}
                >
                  {formatDate(course.date_posted)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default InstructorTable
