import React, { Fragment, useEffect, useState, FC } from "react";
import { Button, Modal, Space } from "antd";
import "../../../../styles/modals.scss";
import axios from "axios";
import { isAuth } from "../../../auth/ProtectedRoute";
import swal from "sweetalert";
import { CategoryViewValues } from "../types/DataType";
import { Chip } from "@mui/material";

const ViewCategoryModal: FC<{
  record: { id: number | null; name: string };
}> = ({ record }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [category, setCategory] = useState<CategoryViewValues>({
    id: null,
    name: "",
    cat_image: "",
    sub_categories: [
      {
        id: null,
        other_category_id: null,
        name: "",
        image: "",
      },
    ],
  });

  const showModal = () => {
    setOpen(true);
  };

  const hideModal = () => {
    setOpen(false);
  };

  useEffect(() => {
    axios({
      method: "GET",
      url: process.env.REACT_APP_HOST_API + `category/${record.id}`,
      headers: {
        Authorization: `Bearer ${isAuth()}`,
      },
    })
      .then((res) => {
        console.log(res.data);
        if (res.data.response === true) {
          setCategory(res.data.data);
        } else if (res.data.response === false && res.data.status === 401) {
          swal({
            title: "Error!",
            text: res.data.message,
            icon: "error",
          });
        } else if (res.data.response === false && res.data.status === 403) {
          swal({
            title: "Error!",
            text: res.data.message,
            icon: "error",
          });
        } else if (res.data.response === false && res.data.status === 405) {
          swal({
            title: "Error!",
            text: res.data.message,
            icon: "error",
          });
        } else if (res.data.response === false && res.data.status === 406) {
          swal({
            title: "Error!",
            text: res.data.message,
            icon: "error",
          });
        } else if (res.data.response === false && res.data.status === 409) {
          swal({
            title: "Error!",
            text: res.data.message,
            icon: "error",
          });
        } else if (res.data.response === false && res.data.status === 500) {
          swal({
            title: "Error!",
            text: "Something went wrong. Please try again later!",
            icon: "error",
          });
        }
      })
      .catch((err) => {
        console.error(err);
        swal({
          title: "Error!",
          text: "Something went wrong. Please try again later!",
          icon: "error",
        });
      });
  }, []);

  return (
    <Fragment>
      <Space>
        <Button className="__btn" onClick={showModal}>
          View Category
        </Button>
      </Space>
      <Modal closable={false} keyboard={true} open={open} footer={null}>
        <div className="clicks-mod">
          <div className="clicks-mod--right">
            <div className="modal-overlay-mod">
              <div className="modal-box-mod-int">
                <div className="modal-box-mod--wrapper">
                  <div className="heading">
                    <h1>View Category</h1>
                    <div onClick={hideModal} style={{ cursor: "pointer" }}>
                      <svg
                        width="35"
                        height="32"
                        viewBox="0 0 35 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M18.5995 16.0001L23.2722 11.3273C23.5763 11.0233 23.5763 10.5319 23.2722 10.228C22.9682 9.92401 22.4768 9.92401 22.1729 10.228L17.5001 14.9008L12.8274 10.228C12.5233 9.92401 12.032 9.92401 11.7281 10.228C11.424 10.5319 11.424 11.0233 11.7281 11.3273L16.4007 16.0001L11.7281 20.6729C11.424 20.9768 11.424 21.4683 11.7281 21.7722C11.8795 21.9238 12.0787 22 12.2777 22C12.4768 22 12.6758 21.9238 12.8274 21.7722L17.5001 17.0994L22.1729 21.7722C22.3245 21.9238 22.5235 22 22.7225 22C22.9216 22 23.1206 21.9238 23.2722 21.7722C23.5763 21.4683 23.5763 20.9768 23.2722 20.6729L18.5995 16.0001Z"
                          fill="black"
                          stroke="black"
                        />
                      </svg>
                    </div>
                  </div>
                  <div className="form-wrap">
                    <div className="form-wrap--category">
                      <div className="form-wrap--category_cat">
                        <h2>Category</h2>
                        <div className="form-wrap--category_cat-area">
                          <div className="cat-image">
                            <img
                              src={
                                process.env.REACT_APP_IMAGE_API_PREFIX +
                                `${category?.cat_image}`
                              }
                              alt="cat_image"
                            />
                          </div>
                          <div className="cat-text">{category?.name}</div>
                        </div>
                      </div>
                      <div className="form-wrap--category_sub">
                        <h2>Sub-Category</h2>
                        <div className="form-wrap--category_sub-area">
                          {category?.sub_categories!.length > 0 ? (
                            category?.sub_categories.map((sub, i) => {
                              return (
                                <Chip
                                  className="sub-text"
                                  key={i}
                                  label={sub.name}
                                />
                              );
                            })
                          ) : (
                            <p>No Sub-category added yet!</p>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="form-wrap--bottom">
                      <div className="btns">
                        <div className="btns--wrap">
                          <button className="save" onClick={hideModal}>
                            Ok
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </Fragment>
  );
};

export default ViewCategoryModal;
