import React, { useContext, FC } from "react";
import { Link } from "react-router-dom";
import "../../../../styles/system.admin.scss";
import { SidebarContext } from "../../../auth/ProtectedRoute";
import { GroupDataType } from "../types/DataTypes";
const ViewGroupBtn: FC<{ record: GroupDataType }> = ({ record }) => {
  const { userName, sidebarData } = useContext(SidebarContext);

  return (
    <div style={{ width: "100%" }}>
      <Link
        to={`view-group/${record.id}`}
        state={{ userName: userName, sidebar: sidebarData }}
      >
        <button className="__btn">View Group</button>
      </Link>
    </div>
  );
};
export default ViewGroupBtn;
