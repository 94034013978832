import React, { FC } from "react";

const ContactTab: FC = () => {
  return (
    <div>
      <div className="user__edit--tab">
        <div className="tab-one">
          <div className="right">
            <h1>Phone</h1>
            <p>081367879091</p>
          </div>
        </div>
        <div className="tab-two">
          <div className="right">
            <h1>Address</h1>
            <p>No 5, ogunsigi close, allen avenue</p>
          </div>
        </div>
        <div className="tab-three">
          <div className="right">
            <h1>Website</h1>
            <p>www.kominiti.com</p>
          </div>
        </div>
        <div className="tab-four">
          <div className="right">
            <h1>Email</h1>
            <p>john.doe@gmail.com</p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ContactTab;
