import React, { FC } from "react";
import { Tabs } from "antd";
import ExperienceTab from "../TabContent/ExperienceTab";
import EducationTab from "../TabContent/EducationTab";
import ContactTab from "../TabContent/ContactTab";
import LanguageTab from "../TabContent/LanguageTab";
import KonnectionTab from "../TabContent/KonnectionTab";
import SkillTab from "../TabContent/SkillTab";
import InterestTab from "../TabContent/InterestTab";

const UserProfileTab: FC = () => (
  <Tabs defaultActiveKey="1">
    <Tabs.TabPane tab="Experience" key="1">
      <ExperienceTab />
    </Tabs.TabPane>
    <Tabs.TabPane tab="Education" key="2">
      <EducationTab />
    </Tabs.TabPane>
    <Tabs.TabPane tab="Contact" key="3">
      <ContactTab />
    </Tabs.TabPane>
    <Tabs.TabPane tab="Language" key="4">
      <LanguageTab />
    </Tabs.TabPane>
    <Tabs.TabPane tab="Connections" key="5">
      <KonnectionTab />
    </Tabs.TabPane>
    <Tabs.TabPane tab="Skills" key="6">
      <SkillTab />
    </Tabs.TabPane>
    <Tabs.TabPane tab="Interests" key="7">
      <InterestTab />
    </Tabs.TabPane>
  </Tabs>
);

export default UserProfileTab;
