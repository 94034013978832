import useModal from "../../../components/modal/useModal";
import useModalCSV from "../../../components/modal/useModalCSV";
import React, { Fragment, FC } from "react";
import user from "../../../assets/user-img.png";
import "../../../styles/modals.scss";
import AddSkillModal from "./components/AddSkillModal";
import AddSkillCSVModal from "./components/AddSkillCSVModal";
import SkillTable from "./tables/SkillTable";

const Skills: FC = () => {
  const { isOpen, toggle } = useModal();
  const { isOpenCSV, toggleCSV } = useModalCSV();
  return (
    <Fragment>
      <div className="roless">
        <div className="roless-wrapper">
          <div className="roless--heading">
            <div className="roless--heading-img">
              <img src={user} alt="profile" />
            </div>
          </div>
          <div className="roless--tables">
            <div className="roless--tables-heading">
              <div className="roless--tables-heading-title">
                <h2>Skills</h2>
              </div>
              <div className="roless--tables-heading-box">
                <div className="formy">
                  <form>
                    <div className="formy--box">
                      <input type="text" placeholder="Search.." name="search" />
                      <div className="formy--box-icon">
                        <i className="fa fa-search"></i>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="clicks">
                  <div className="clicks--right">
                    <button onClick={toggle}>
                      <span>
                        <i className="fa fa-plus-circle" aria-hidden="true"></i>
                      </span>
                      Add Skills
                    </button>
                    <AddSkillModal
                      isOpen={isOpen}
                      toggle={toggle}
                    ></AddSkillModal>
                  </div>
                  <div className="clicks--left">
                    <button onClick={toggleCSV}>
                      <span>
                        <svg
                          width="45"
                          height="45"
                          viewBox="0 0 45 45"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M22.5 9.5C21.948 9.5 21.5 9.948 21.5 10.5V14.5H23.5V10.5C23.5 9.948 23.052 9.5 22.5 9.5ZM23.5 14.5V27.0859L25.793 24.793C26.184 24.402 26.816 24.402 27.207 24.793C27.598 25.184 27.598 25.816 27.207 26.207L23.207 30.207C23.012 30.402 22.756 30.5 22.5 30.5C22.244 30.5 21.988 30.402 21.793 30.207L17.793 26.207C17.402 25.816 17.402 25.184 17.793 24.793C18.184 24.402 18.816 24.402 19.207 24.793L21.5 27.0859V14.5H13.5C12.395 14.5 11.5 15.395 11.5 16.5V33.5C11.5 34.605 12.395 35.5 13.5 35.5H31.5C32.605 35.5 33.5 34.605 33.5 33.5V16.5C33.5 15.395 32.605 14.5 31.5 14.5H23.5Z"
                            fill="#666666"
                          />
                        </svg>
                      </span>
                      Import CSV
                    </button>
                    <AddSkillCSVModal
                      isOpenCSV={isOpenCSV}
                      toggleCSV={toggleCSV}
                    ></AddSkillCSVModal>
                  </div>
                </div>
              </div>
            </div>
            <div className="roless--tables--content">
              <SkillTable />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default Skills;
