import React, { useState, Key, useEffect, FC } from "react";
import "antd/dist/antd.css";
import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import type { TableRowSelection } from "antd/es/table/interface";
import { MoreOutlined } from "@ant-design/icons";
import { Dropdown, Menu, Space } from "antd";
import { ModuleDataType } from "../types/DataType";
import axios from "axios";
import { isAuth } from "../../../auth/ProtectedRoute";
import SkeletonTable, {
  SkeletonTableColumnsType,
} from "../../../../components/SkeletonTable";
import "../../../../styles/modules.scss";
import EditModule from "../components/EditModule";
import DeleteModuleModal from "../components/DeleteModuleModal";
import swal from "sweetalert";

const AdminModuleTable: FC = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [moduleTableData, setModuleTableData] = useState<ModuleDataType[]>([]);
  const [showTotalModules, setShowTotalModules] = useState<number>();
  // eslint-disable-next-line
  const [record, setRecord] = useState<{ id: number | null; name: string }>({
    id: null,
    name: "",
  });

  const menu = (
    <Menu
      items={[
        {
          label: <EditModule record={record} />,
          key: "1",
          icon: <i className="fa fa-pencil" aria-hidden="true"></i>,
        },
        {
          label: <DeleteModuleModal record={record} />,
          key: "5",
          icon: <i className="fa fa-trash" aria-hidden="true"></i>,
        },
      ]}
    />
  );

  const columns: ColumnsType<ModuleDataType> = [
    {
      title: "Id",
      dataIndex: "id",
    },
    {
      title: "Modules",
      dataIndex: "name",
      render: (text) => (
        <div className="status">
          <p>{text}</p>
        </div>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      render: (text) => (
        <div className="status">
          <p>{text}</p>
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      render: () => (
        <div>
          <Space wrap>
            <Dropdown.Button
              overlay={menu}
              placement="bottom"
              icon={<MoreOutlined />}
              trigger={["click"]}
            ></Dropdown.Button>
          </Space>
        </div>
      ),
    },
  ];

  const showModuleTable = (page: number) => {
    axios({
      method: "GET",
      url: process.env.REACT_APP_HOST_API + `module?page=${page}`,
      headers: {
        Authorization: `Bearer ${isAuth()}`,
      },
    })
      .then((res) => {
        setLoading(false);
        setModuleTableData(
          res.data.data.data.map((moduleData: ModuleDataType) => ({
            key: moduleData.id,
            id: moduleData.id,
            name: moduleData.name,
            description: moduleData.description,
            action: "",
          }))
        );
        setShowTotalModules(res.data.data.total);
      })
      .catch((err) => {
        console.error(err);
        swal({
          title: "Error!",
          text: "Something went wrong. Please try again later!",
          icon: "error",
        });
      });
  };

  useEffect(() => {
    showModuleTable(1);
  }, []);

  const onSelectChange = (newSelectedRowKeys: Key[]) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection: TableRowSelection<ModuleDataType> = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
      {
        key: "odd",
        text: "Select Odd Row",
        onSelect: (changableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys.filter((_, index) => {
            if (index % 2 !== 0) {
              return false;
            }
            return true;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
      {
        key: "even",
        text: "Select Even Row",
        onSelect: (changableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys.filter((_, index) => {
            if (index % 2 !== 0) {
              return true;
            }
            return false;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
    ],
  };

  return (
    <SkeletonTable
      loading={loading}
      columns={columns as SkeletonTableColumnsType[]}
    >
      <Table
        rowSelection={rowSelection}
        columns={columns}
        dataSource={moduleTableData}
        pagination={{
          showSizeChanger: false,
          pageSize: 20,
          total: showTotalModules,
          onChange: (page) => {
            showModuleTable(page);
          },
          showTotal: (showTotalModules, range) =>
            `${range[0]}-${range[1]} of ${showTotalModules} items`,
        }}
        onRow={(record) => {
          return {
            onClick: () => {
              setRecord(record);
            },
          };
        }}
      />
    </SkeletonTable>
  );
};

export default AdminModuleTable;
