import React, { FC } from "react";
import { Link } from "react-router-dom";
// import UserDocumentValidation from "./UserDocumentValidation";

const DocumentDetails: FC = () => {
  return (
    <div className="user">
      <div className="user__edit">
        <div className="user__edit--heading">
          <h1>W-9 Validation</h1>
          <Link to="/user-identity-validation">
            <button>
              <svg
                width="22"
                height="20"
                viewBox="0 0 22 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7 2L2 7L7 12"
                  stroke="white"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M2 7H10C15.523 7 20 11.477 20 17V18"
                  stroke="white"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              Back
            </button>
          </Link>
        </div>
      </div>
      <div className="user__edit-child">
        <div className="user__edit-child--heading">
          <h1>Legal Name of Taxpayer</h1>
          <h1>Country</h1>
          <h1>Federal Tax Classification</h1>
          <h1>Tax Payer ID Number</h1>
        </div>
      </div>
      <div className="user__edit-confirm">
        <div className="user__edit-confirm--heading">
          <h1 style={{ color: "#4895EF" }}>Eke Chibueze Precious</h1>
          <h1 style={{ color: "#4895EF" }}>USA</h1>
          <h1 style={{ color: "#4895EF" }}>Sole Proprietorship</h1>
          <h1 style={{ color: "#4895EF" }}>############</h1>
        </div>
      </div>
      <div className="user__edit-attach">
        <div className="user__edit-attach--heading">
          <h1>Attachment</h1>
        </div>
        <div className="upload">
          <div className="upload-wrap">
            <svg
              width="164"
              height="204"
              viewBox="0 0 164 204"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2 22V182C2 187.304 4.10714 192.391 7.85786 196.142C11.6086 199.893 16.6957 202 22 202H142C147.304 202 152.391 199.893 156.142 196.142C159.893 192.391 162 187.304 162 182V65.42C162 62.7556 161.467 60.1181 160.433 57.6624C159.399 55.2068 157.885 52.9824 155.98 51.12L111.58 7.7C107.844 4.04658 102.826 2.00065 97.6 2H22C16.6957 2 11.6086 4.10714 7.85786 7.85786C4.10714 11.6086 2 16.6957 2 22V22Z"
                stroke="#666666"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M52.0015 112H112.001"
                stroke="#666666"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M52.0015 152H82.0015"
                stroke="#666666"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M101.999 2V42C101.999 47.3043 104.106 52.3914 107.856 56.1421C111.607 59.8929 116.694 62 121.999 62H161.999"
                stroke="#666666"
                strokeWidth="3"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
        <div className="user__edit---btn">
          <div className="btn-wrap">
            <button className="reject">Reject</button>
            <button className="approve">Approve</button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DocumentDetails;
