import React, { Fragment, useEffect, useState } from "react";
import "../../../../styles/modals.scss";
import FeedPic from "../../../../assets/feedPic.png";
import { convertFromRaw } from "draft-js";
import { convertToHTML } from "draft-convert";

const ViewFeedImageModal = (props: any) => {
  const [imageProp, setImageProp] = useState<
    //eslint-disable-next-line
    { id: number | null; content_feed_id: number | null; filename: string }[]
  >(props.feedMedia);
  const [counter, setCounter] = useState<number>(0);

  //reset props to state to refresh modal
  useEffect(() => {
    setImageProp(props.feedMedia);
    setCounter(0);
  }, [props.feedMedia]);

  //convert raw_data converted from editorState in editor back to editorState
  const editorState =
    props.feedData && props.feedData.content_raw_data
      ? convertFromRaw(JSON.parse(props.feedData.content_raw_data))
      : null;

  //convert editorState to html string and pass as dangerouslySetInnerHTML where needed to display as plain text with styling
  const htmlString = editorState !== null ? convertToHTML(editorState) : "";

  return (
    <>
      {props.isOpen && (
        <div className="feed-modal-overlay">
          <div className="feed-modal-box" onClick={(e) => e.stopPropagation()}>
            <div className="feed-modal-box--wrapper">
              <div className="heading">
                <div onClick={props.toggle} style={{ cursor: "pointer" }}>
                  <svg
                    width="35"
                    height="32"
                    viewBox="0 0 35 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18.5995 16.0001L23.2722 11.3273C23.5763 11.0233 23.5763 10.5319 23.2722 10.228C22.9682 9.92401 22.4768 9.92401 22.1729 10.228L17.5001 14.9008L12.8274 10.228C12.5233 9.92401 12.032 9.92401 11.7281 10.228C11.424 10.5319 11.424 11.0233 11.7281 11.3273L16.4007 16.0001L11.7281 20.6729C11.424 20.9768 11.424 21.4683 11.7281 21.7722C11.8795 21.9238 12.0787 22 12.2777 22C12.4768 22 12.6758 21.9238 12.8274 21.7722L17.5001 17.0994L22.1729 21.7722C22.3245 21.9238 22.5235 22 22.7225 22C22.9216 22 23.1206 21.9238 23.2722 21.7722C23.5763 21.4683 23.5763 20.9768 23.2722 20.6729L18.5995 16.0001Z"
                      fill="#666666"
                      stroke="#666666"
                    />
                  </svg>
                </div>
              </div>
              <div className="content-wrapper">
                <div className="image-wrap">
                  {imageProp[counter].filename &&
                  imageProp[counter].filename.substring(
                    imageProp[counter].filename.lastIndexOf(".") + 1
                  ) === "mp4" ? ( //conditional statement to check for image or video for each array index
                    <div className="video-wrapper">
                      <video controls>
                        <source
                          src={
                            imageProp[counter].filename
                              ? process.env.REACT_APP_IMAGE_API_PREFIX +
                                `${imageProp[counter].filename}`
                              : ""
                          }
                          type="video/mp4"
                        />
                        Your browser does not support HTML5 video.
                      </video>
                    </div>
                  ) : (
                    <div className="image-wrapper">
                      <img
                        src={
                          imageProp[counter].filename
                            ? process.env.REACT_APP_IMAGE_API_PREFIX +
                              `${imageProp[counter].filename}`
                            : ""
                        }
                        alt="feed-attachments"
                      />
                    </div>
                  )}
                  <div className="action-button">
                    {imageProp && imageProp.length > 1 ? ( //conditional statement to check if media array has more than one item, if not, buttons will not be displayed!!
                      <Fragment>
                        <button
                          disabled={counter === 0 ? true : false} //disabled when media array is at first item
                          type="button"
                          onClick={(e) => {
                            e.stopPropagation();
                            if (counter > 0) {
                              setCounter(counter - 1);
                            } else {
                              return;
                            }
                          }}
                        >
                          Previous
                        </button>
                        <button
                          type="button"
                          disabled={
                            counter === imageProp.length - 1 ? true : false //disabled when media array is at the last item
                          }
                          onClick={(e) => {
                            e.stopPropagation();
                            if (counter < 5) {
                              setCounter(counter + 1);
                            } else {
                              return;
                            }
                          }}
                        >
                          Next
                        </button>
                      </Fragment>
                    ) : null}
                  </div>
                </div>
                <div className="body-wrap">
                  <div className="body-wrap--header">
                    <div className="profile-area">
                      <div className="avatar">
                        <img src={FeedPic} alt="avatar" />
                      </div>
                      <div className="detail">
                        <h5>
                          {props.feedData.account_name
                            ? props.feedData.account_name
                            : null}
                        </h5>
                        <h6>
                          {props.feedData.position
                            ? props.feedData.position
                            : null}
                        </h6>
                        <p>
                          <span>
                            <svg
                              width="5"
                              height="6"
                              viewBox="0 0 5 6"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <circle cx="2.5" cy="3" r="2.5" fill="#C4C4C4" />
                            </svg>
                          </span>
                          an hour ago
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="body-wrap--area">
                    {htmlString !== "" ? (
                      <div className="body">
                        <div dangerouslySetInnerHTML={{ __html: htmlString }} />
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="footer">
                      <p>
                        {props.feedData.likes ? props.feedData.likes : "0"}.{" "}
                        <span>Likes</span>
                      </p>
                      <p>
                        0. <span>Comments</span>
                      </p>
                      <p>
                        {props.feedData.shares ? props.feedData.shares : "0"}.{" "}
                        <span>Shares</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ViewFeedImageModal;
