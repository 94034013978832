import React, { useState, useContext, FC, Key } from "react";
import { Link } from "react-router-dom";
import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { UserValidationType } from "../types/UserDatatype";
import user from "../../../../assets/user-img.png";
import { SidebarContext } from "../../../auth/ProtectedRoute";

const UserValidationTable: FC = () => {
  const { userName, sidebarData } = useContext(SidebarContext);
  console.log(sidebarData);

  // const handleMenuClick: MenuProps["onClick"] = (e) => {
  //   message.info("Click on menu item.");
  //   console.log("click", e);
  // };

  // const items: MenuProps["items"] = [
  //   {
  //     label: "Edit Profile",
  //     key: "1",
  //     icon: <UserOutlined />,
  //   },
  //   {
  //     label: (
  //       <div>
  //         <Link
  //           to="view-profile"
  //           state={{ userName: userName, sidebar: sidebarData }}
  //         >
  //           View Profile
  //         </Link>
  //       </div>
  //     ),
  //     key: "2",
  //     icon: <UserOutlined />,
  //   },
  //   {
  //     label: "Delete",
  //     key: "3",
  //     icon: <UserOutlined />,
  //   },
  // ];

  // const menuProps = {
  //   items,
  // };

  const columns: ColumnsType<UserValidationType> = [
    {
      title: "Id",
      dataIndex: "id",
      render: (num) => (
        <div>
          <p className="user_name">{num}</p>
        </div>
      ),
    },
    {
      title: "Photo",
      dataIndex: "photo",
      render: () => (
        <div
          style={{
            width: "75px",
            height: "80px",
          }}
        >
          <img
            src={user}
            alt="logo"
            style={{ width: "100%", height: "100%", borderRadius: "50%" }}
          />
        </div>
      ),
    },
    {
      title: "User",
      dataIndex: "name",
      render: (text) => (
        <div>
          <p className="user_name">{text}</p>
        </div>
      ),
    },
    {
      title: "Validation Date",
      dataIndex: "date",
      render: (Date) => (
        <div>
          <p className="user_name">{Date}</p>
        </div>
      ),
    },

    {
      title: "Status",
      dataIndex: "status",
      render: (text) => (
        <div>
          <p className="user_name">{text}</p>
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      render: () => (
        <div>
          <Link
            to="user-details"
            state={{ userName: userName, sidebar: sidebarData }}
          >
            Details
          </Link>
        </div>
      ),
    },
  ];

  const data: UserValidationType[] = [
    {
      key: "1",
      id: 1,
      photo: "",
      name: "John Brown",
      date: "2-12-2022",
      status: "confirm",
      action: "",
    },
  ];

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys: Key[], selectedRows: UserValidationType[]) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record: UserValidationType) => ({
      disabled: record.name === "Disabled User", // Column configuration not to be checked
      name: record.name,
    }),
  };
  const [selectionType, setSelectionType] = useState<"checkbox" | "radio">(
    "checkbox"
  );

  return (
    <div>
      <Table
        rowSelection={{
          type: selectionType,
          ...rowSelection,
        }}
        columns={columns}
        dataSource={data}
      />
    </div>
  );
};

export default UserValidationTable;
