import React from "react"
import { useQuery } from "@tanstack/react-query"
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  CircularProgress,
  Alert,
} from "@mui/material"
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward"
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward"
import { format } from "date-fns"
import { isAuth } from "../../../../auth/ProtectedRoute"

interface JobType {
  id: number
  name: string
}

interface Job {
  id: number
  recruiter: string
  job_title: string
  job_type: JobType[]
  date_posted: string
}

interface ApiResponse {
  response: boolean
  status: string
  message: string
  code: number
  data: Job[]
}

interface JobsTableProps {
  onSort?: (column: keyof Job) => void
  sortColumn?: keyof Job
  sortDirection?: "asc" | "desc"
}

// API fetch function
const fetchJobs = async (): Promise<ApiResponse> => {
  const response = await fetch(`${process.env.REACT_APP_HOST_API}jobs/analytics/jobs`, {
    headers: {
      Authorization: `Bearer ${isAuth()}`,
    },
  })
  if (!response.ok) {
    throw new Error("Failed to fetch jobs")
  }
  return response.json()
}

const JobsTable: React.FC<JobsTableProps> = ({ onSort, sortColumn, sortDirection }) => {
  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["jobs"],
    queryFn: fetchJobs,
  })

  const renderSortIcon = (column: keyof Job) => {
    if (sortColumn !== column) return null
    return sortDirection === "asc" ? (
      <ArrowUpwardIcon fontSize="small" />
    ) : (
      <ArrowDownwardIcon fontSize="small" />
    )
  }

  // Function to format date
  const formatDate = (dateString: string) => {
    return format(new Date(dateString), "dd MMM, yyyy")
  }

  // Function to get job type label
  const getJobTypeLabel = (jobTypes: JobType[]) => {
    if (!jobTypes || jobTypes.length === 0) return "Not specified"
    return jobTypes.map(type => type.name).join(", ")
  }

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <CircularProgress />
      </Box>
    )
  }

  if (isError) {
    return (
      <Alert severity="error">
        Error loading jobs: {(error as Error)?.message || "Unknown error occurred"}
      </Alert>
    )
  }

  return (
    <Box>
      <TableContainer component={Paper} elevation={0}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell onClick={() => onSort?.("job_title")} style={{ cursor: "pointer" }}>
                <Box
                  display="flex"
                  alignItems="center"
                  style={{
                    fontFamily: "Poppins",
                    fontStyle: "normal",
                    fontWeight: 600,
                    fontSize: "18px",
                    lineHeight: "27px",
                    color: "#000000",
                  }}
                >
                  Job Title
                  {renderSortIcon("job_title")}
                </Box>
              </TableCell>
              <TableCell onClick={() => onSort?.("recruiter")} style={{ cursor: "pointer" }}>
                <Box
                  display="flex"
                  alignItems="center"
                  style={{
                    fontFamily: "Poppins",
                    fontStyle: "normal",
                    fontWeight: 600,
                    fontSize: "18px",
                    lineHeight: "27px",
                    color: "#000000",
                  }}
                >
                  Recruiter
                  {renderSortIcon("recruiter")}
                </Box>
              </TableCell>
              <TableCell onClick={() => onSort?.("job_type")} style={{ cursor: "pointer" }}>
                <Box
                  display="flex"
                  alignItems="center"
                  style={{
                    fontFamily: "Poppins",
                    fontStyle: "normal",
                    fontWeight: 600,
                    fontSize: "18px",
                    lineHeight: "27px",
                    color: "#000000",
                  }}
                >
                  Job Type
                  {renderSortIcon("job_type")}
                </Box>
              </TableCell>
              <TableCell onClick={() => onSort?.("date_posted")} style={{ cursor: "pointer" }}>
                <Box
                  display="flex"
                  alignItems="center"
                  style={{
                    fontFamily: "Poppins",
                    fontStyle: "normal",
                    fontWeight: 600,
                    fontSize: "18px",
                    lineHeight: "27px",
                    color: "#000000",
                  }}
                >
                  Date Posted
                  {renderSortIcon("date_posted")}
                </Box>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.data.map(job => (
              <TableRow key={job.id} sx={{ "&:nth-of-type(odd)": { backgroundColor: "#f8f9fa" } }}>
                <TableCell
                  style={{
                    fontFamily: "Poppins",
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: "16px",
                    lineHeight: "27px",
                    color: "#000000",
                  }}
                >
                  {job.job_title}
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: "Poppins",
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: "16px",
                    lineHeight: "27px",
                    color: "#000000",
                  }}
                >
                  {job.recruiter}
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: "Poppins",
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: "16px",
                    lineHeight: "27px",
                    color: "#000000",
                  }}
                >
                  {getJobTypeLabel(job.job_type)}
                </TableCell>
                <TableCell
                  style={{
                    fontFamily: "Poppins",
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: "16px",
                    lineHeight: "27px",
                    color: "#000000",
                  }}
                >
                  {formatDate(job.date_posted)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default JobsTable
