import React, { FC, Fragment, useContext, useState, useEffect } from "react"
import "../../../styles/user.admin.scss"
import { Input, message, Tag, Typography } from "antd"
import { Link, useParams } from "react-router-dom"
import { isAuth, SidebarContext } from "../../auth/ProtectedRoute"
import UserMetricsChart from "./component/Line"
import axios from "axios"

// Types
interface IAnalyticsResponse {
  response: boolean
  status: string
  message: string
  code: number
  data: {
    total_users: number
    total_onboarded_users: number
    total_not_onboarded_users: number
    total_online_users: number
  }
}

interface IAnalyticsState {
  totalUsers: number
  totalOnboardedUsers: number
  totalNotOnboardedUsers: number
  totalOnlineUsers: number
  isLoading: boolean
}

const UserAnalytic: FC = () => {
  const { userName, sidebarData } = useContext(SidebarContext)
  const params = useParams()

  const [analytics, setAnalytics] = useState<IAnalyticsState>({
    totalUsers: 0,
    totalOnboardedUsers: 0,
    totalNotOnboardedUsers: 0,
    totalOnlineUsers: 0,
    isLoading: true,
  })

  const fetchAnalytics = async () => {
    try {
      const response = await axios.get<IAnalyticsResponse>(
        `${process.env.REACT_APP_HOST_API}manage-user/analytics/top`,
        {
          headers: {
            Authorization: `Bearer ${isAuth()}`,
          },
        }
      )

      if (response.data.response && response.data.status === "SUCCESS") {
        const {
          total_users,
          total_onboarded_users,
          total_not_onboarded_users,
          total_online_users,
        } = response.data.data

        setAnalytics({
          totalUsers: total_users,
          totalOnboardedUsers: total_onboarded_users,
          totalNotOnboardedUsers: total_not_onboarded_users,
          totalOnlineUsers: total_online_users,
          isLoading: false,
        })
      } else {
        message.error("Failed to fetch analytics data")
      }
    } catch (error) {
      console.error("Error fetching analytics:", error)
      message.error("Error fetching analytics data")
      setAnalytics(prev => ({ ...prev, isLoading: false }))
    }
  }

  useEffect(() => {
    fetchAnalytics()
  }, [])

  return (
    <Fragment>
      <div className="user">
        <div className="user-title">
          <h1>Analytics</h1>
        </div>

        <div className="analytic">
          <div className="analytic_wrapper">
            <div className="analytic_wrapper_heading">
              <div className="earning">
                <h1>Total Users</h1>
                <div className="earning_wrap">
                  <h1>{analytics.totalUsers}</h1>
                </div>
              </div>
              <div className="instructor">
                <h1>Onboarded Users</h1>
                <div className="instructor_wrap">
                  <h1>{analytics.totalOnboardedUsers}</h1>
                </div>
              </div>
              <div className="course">
                <h1>Online Users</h1>
                <div className="course_wrap">
                  <h1>{analytics.totalOnlineUsers}</h1>
                </div>
              </div>
              <div className="course">
                <h1>Not Onboarded</h1>
                <div className="course_wrap">
                  <h1>{analytics.totalNotOnboardedUsers}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div style={{ marginTop: "28px" }}>
            <div>
              <UserMetricsChart />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default UserAnalytic
