import React, { useContext, FC } from "react";
import { Link } from "react-router-dom";
import { Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import "../../../styles/user.scss";
import { SidebarContext } from "../../auth/ProtectedRoute";
import SupportPurposeTable from "../UserManagement/tables/SupportPurposeTable";

const UserSupportPurpose: FC = () => {
  const { userName, sidebarData } = useContext(SidebarContext);
  return (
    <div className="user">
      <div className="user__edit">
        <div className="user__edit--heading">
          <h1>Support Purposes</h1>
          <Link
            to="add-purpose"
            state={{ userName: userName, sidebar: sidebarData }}
          >
            <button>+ Add Support Message</button>
          </Link>
        </div>
      </div>
      <div className="user__middle">
        <div className="user__middle-search">
          <Input
            size="large"
            placeholder="Search List"
            prefix={<SearchOutlined />}
            className="input"
          />
        </div>
      </div>
      <div>
        <SupportPurposeTable />
      </div>
    </div>
  );
};

export default UserSupportPurpose;
