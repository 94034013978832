import React, { useContext, FC } from "react"
import { Link, useParams } from "react-router-dom"
import "../../../../../styles/system.admin.scss"
import { SidebarContext } from "../../../../auth/ProtectedRoute"
import { JobPostDataType } from "../types/DataType"

const View: FC<{ record: JobPostDataType }> = ({ record }) => {
  const { userName, sidebarData } = useContext(SidebarContext)
  return (
    <div style={{ width: "100%" }}>
      <Link to={`view-job/${record.id}`} state={{ userName: userName, sidebar: sidebarData }}>
        <button className="__btn"> View </button>
      </Link>
    </div>
  )
}
export default View
