import React, { useState, Key, useEffect } from "react"
import styled from "styled-components"
import { Menu, Table } from "antd"
import type { ColumnsType } from "antd/es/table"
import { MoreOutlined } from "@ant-design/icons"
import { Dropdown, Space } from "antd"
import { ApprovedDataType } from "../type/DataType"
import { isAuth } from "../../../../auth/ProtectedRoute"
import SkeletonTable, { SkeletonTableColumnsType } from "../../../../../components/SkeletonTable"
import axios from "axios"
// import ViewProjectBtn from "../components/ViewProjectBtn"
//import DeleteProjectModal from "../components/DeleteProjectModal"

const ApprovalTable = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [projectTableData, setProjectTableData] = useState<ApprovedDataType[]>([])
  const [showTotal, setShowTotal] = useState<number>()
  //   const [record, setRecord] = useState<PendingAprrovalDataType>()

  const menu = (
    <Menu
      items={[
        {
          //label: <ViewProjectBtn record={record!} />,
          key: "1",
          icon: <i className="fa fa-eye" aria-hidden="true"></i>,
        },
        {
          //label: <DeleteProjectModal record={record!} />,
          key: "2",
          icon: <i className="fa fa-trash" aria-hidden="true"></i>,
        },
      ]}
    />
  )

  //api request for table data
  //   const showProjectTable = (page: number) => {
  //     axios({
  //       method: "GET",
  //       url: process.env.REACT_APP_HOST_API + `projects/approved?page=${page}`,
  //       headers: {
  //         Authorization: `Bearer ${isAuth()}`,
  //       },
  //     })
  //       .then(res => {
  //         setLoading(false)
  //         if (res.data.response === true && res.data.status === 200) {
  //           // console.log(res.data.data.total);

  //           setProjectTableData(
  //             res.data.data.data.map((projectData: ProjectApprovedDataType) => ({
  //               key: projectData.project_id,
  //               project_id: projectData.project_id,
  //               buyer: {
  //                 fullname: projectData.fullname,
  //                 image: process.env.REACT_APP_IMAGE_API_PREFIX + projectData.buyer_profile_image,
  //               },
  //               title:
  //                 projectData.title.length > 20
  //                   ? `${projectData.title.slice(0, 20)}...`
  //                   : projectData.title,
  //               type: projectData.type,
  //               status: "Approved",
  //               action: "",
  //             }))
  //           )
  //           setShowTotal(res?.data?.data?.total)
  //         } else {
  //           // console.log(res.data);
  //         }
  //       })
  //       .catch(err => {
  //         console.log(err)
  //       })
  //   }

  //   useEffect(() => {
  //     showProjectTable(1)
  //   }, [])

  const columns: ColumnsType<ApprovedDataType> = [
    {
      title: "Course Title",
      dataIndex: "title",

      render: text => (
        <div>
          <p className="user_name">{text}</p>
        </div>
      ),
    },
    {
      title: "Instructor",
      dataIndex: "instructor",
      render: text => (
        <div>
          <p className="user_name">{text}</p>
        </div>
      ),
    },
    {
      title: "Entrollment Deadline",
      dataIndex: "enrollment_deadline",
      render: text => (
        <div className="status-area">
          <p
            style={{
              background: "#4895EF",
              textAlign: "center",
            }}
          >
            {text}
          </p>
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      render: () => (
        <div>
          <Space wrap>
            <Dropdown.Button
              overlay={menu}
              placement="bottom"
              icon={<MoreOutlined />}
              trigger={["click"]}
            ></Dropdown.Button>
          </Space>
        </div>
      ),
    },
  ]

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys: Key[], selectedRows: ApprovedDataType[]) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, "selectedRows: ", selectedRows)
    },
    // getCheckboxProps: (record: ProjectApprovedDataType) => ({
    //   disabled: record === "Disabled User", // Column configuration not to be checked
    //   name: record.buyer,
    // }),
  }
  const [selectionType, setSelectionType] = useState<"checkbox" | "radio">("checkbox")

  return (
    <SkeletonTable loading={loading} columns={columns as SkeletonTableColumnsType[]}>
      <Table
        rowSelection={{
          type: selectionType,
          ...rowSelection,
        }}
        columns={columns}
        dataSource={projectTableData}
        pagination={{
          showSizeChanger: false,
          pageSize: 20,
          total: showTotal,
          onChange: page => {
            // showProjectTable(page)
          },
          showTotal: (showTotal, range) => `${range[0]}-${range[1]} of ${showTotal} items`,
        }}
        onRow={record => {
          return {
            onClick: () => {
              //   setRecord(record)
            },
          }
        }}
      />
    </SkeletonTable>
  )
}

export default ApprovalTable
