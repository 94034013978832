import React, { useState, FC } from "react";
import AuthCode from "react-auth-code-input";
import "../../styles/verify.scss";
import Logo from "../../assets/logo.png";
import { Formik, Form, Field, FormikHelpers, ErrorMessage } from "formik";
import { VerificationCode } from "./types/AuthValues";
import { useSearchParams, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import axios from "axios";
import swal from "sweetalert";

const Verification: FC = () => {
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");
  const adminFlow = searchParams.get("is_admin");
  const encodedEmail = encodeURIComponent(email!);
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const ResendEmail = () => {
    if (email) {
      const emailDetail = {
        email: email,
      };
      setLoading(true);
      axios({
        method: "POST",
        url: process.env.REACT_APP_HOST_API + `auth/password/forgot`,
        data: emailDetail,
      })
        .then((res) => {
          setLoading(false);
          if (res.data.response === true) {
            swal({
              title: "Success!",
              text: "Code sent Successfully!",
              icon: "success",
            });
          } else if (res.data.response === false && res.data.status === 404) {
            swal({
              title: "Error!",
              text: res.data.message,
              icon: "error",
            });
          } else if (res.data.response === false && res.data.status === 401) {
            swal({
              title: "Error!",
              text: res.data.message,
              icon: "error",
            });
          } else if (res.data.response === false && res.data.status === 503) {
            swal({
              title: "Error!",
              text: res.data.message,
              icon: "error",
            });
          }
        })
        .catch((err) => {
          console.error(err);
          swal({
            title: "Error!",
            text: "Something went wrong. Please try again later!",
            icon: "error",
          });
        });
    }
  };
  const initialValues: VerificationCode = {
    code: null,
  };

  return (
    <div className="login">
      <div className="login__wrapper">
        <div className="login__wrapper-box" style={{ top: "25%" }}>
          <Formik
            initialValues={initialValues}
            validationSchema={Yup.object().shape({
              code: Yup.number()
                .required("This field is required")
                .min(4, "input your 4-digits code only")
                .nullable(),
            })}
            onSubmit={(
              values: VerificationCode,
              { setSubmitting }: FormikHelpers<VerificationCode>
            ) => {
              const formDetails = {
                code: values.code,
              };
              axios({
                method: "POST",
                url:
                  process.env.REACT_APP_HOST_API +
                  `auth/password/confirm_reset`,
                data: formDetails,
              })
                .then((res) => {
                  setSubmitting(false);
                  if (res.data.response === true) {
                    swal({
                      title: "Success!",
                      text: "Verification Successful",
                      icon: "success",
                    });
                    navigate(`/password-reset?email=${encodedEmail}`);
                  }
                })
                .catch((err) => {
                  console.error(err);
                  swal({
                    title: "Error!",
                    text: "Something went wrong. Please try again later!",
                    icon: "error",
                  });
                });
            }}
          >
            {({ values, setFieldValue, isSubmitting }) => (
              <Form>
                <div className="login__wrapper-imgcontainer">
                  <img src={Logo} alt="logo" className="logo" />
                </div>
                <div className="login__wrapper--heading">
                  <h1>Enter Verification Code</h1>
                </div>
                <div className="login__wrapper-container">
                  <Field
                    as={AuthCode}
                    length={4}
                    allowedCharacters="numeric"
                    containerClassName="auth-container"
                    inputClassName="auth-input"
                    id="code"
                    name="code"
                    value={values.code}
                    onChange={(newValue: number) =>
                      setFieldValue("code", newValue)
                    }
                  />
                  <ErrorMessage name="code">
                    {(msg) => <div style={{ color: "red" }}>{msg}</div>}
                  </ErrorMessage>
                  <div className="login__wrapper-container--checkbox">
                    <span className="men">{`I didn't receive a code. `}</span>
                    {adminFlow === null ? (
                      <span className="menL" onClick={ResendEmail}>
                        {loading ? "Sending" : "Resend"}
                      </span>
                    ) : null}
                  </div>
                  <button type="submit">
                    {isSubmitting ? "Verifying" : "Verify"}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
          <div className="login__wrapper--bottom">
            <div className="login__wrapper--bottom-footer">
              <p>
                <span>&copy;</span> Kominiti 2022 All Rights
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Verification;
