import React, { useState, useContext, FC, Key } from "react"
import { Link } from "react-router-dom"
import { Table } from "antd"
import type { ColumnsType } from "antd/es/table"
import { UserOutlined } from "@ant-design/icons"
import type { MenuProps } from "antd"
import { Dropdown, message, Space } from "antd"
import { StudentDataType } from "../types/DataType"
// import UserContact from "../component/UserContact";
import user from "../../../../assets/user-img.png"
import { SidebarContext } from "../../../../auth/ProtectedRoute"

const StudentTable: FC = () => {
  const { userName, sidebarData } = useContext(SidebarContext)

  const handleMenuClick: MenuProps["onClick"] = e => {
    message.info("Click on menu item.")
    console.log("click", e)
  }

  const items: MenuProps["items"] = [
    {
      label: "Edit Profile",
      key: "1",
      icon: <UserOutlined />,
    },
    {
      label: (
        <div>
          <Link to="view-profile" state={{ userName: userName, sidebar: sidebarData }}>
            View Profile
          </Link>
        </div>
      ),
      key: "2",
      icon: <UserOutlined />,
    },
    {
      label: "Delete",
      key: "3",
      icon: <UserOutlined />,
    },
  ]

  const menuProps = {
    items,
  }

  const columns: ColumnsType<StudentDataType> = [
    {
      title: "Id",
      dataIndex: "id",
      render: text => (
        <div>
          <p className="user_name">{text}</p>
        </div>
      ),
    },

    {
      title: "Student Name",
      dataIndex: "student_name",
      render: text => (
        <div>
          <p className="user_name">{text}</p>
        </div>
      ),
    },

    {
      title: "Email",
      dataIndex: "email",
      render: text => (
        <div>
          <p className="user_name">{text}</p>
        </div>
      ),
    },
    {
      title: "Phone",
      dataIndex: "phone",
      render: text => (
        <div>
          <p className="user_name">{text}</p>
        </div>
      ),
    },
    {
      title: "Join Date",
      dataIndex: "joined_date",
      render: text => (
        <div>
          <p className="user_name">{text}</p>
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      render: text => (
        <div>
          <Space wrap>
            <Dropdown.Button
              menu={menuProps}
              placement="bottomCenter"
              icon={<UserOutlined />}
            ></Dropdown.Button>
          </Space>
        </div>
      ),
    },
  ]

  const data: StudentDataType[] = [
    {
      key: "1",
      id: "",
      student_name: "Nigeria breweries ",
      email: "opdot@gmail.com",
      phone: "Abuja",
      joined_date: "12/03/2023",
      action: "",
    },
  ]

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys: Key[], selectedRows: StudentDataType[]) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, "selectedRows: ", selectedRows)
    },
    getCheckboxProps: (record: StudentDataType) => ({
      disabled: record.id === "Disabled User", // Column configuration not to be checked
      name: record.id,
    }),
  }
  const [selectionType, setSelectionType] = useState<"checkbox" | "radio">("checkbox")

  return (
    <div>
      <Table
        rowSelection={{
          type: selectionType,
          ...rowSelection,
        }}
        columns={columns}
        dataSource={data}
      />
    </div>
  )
}

export default StudentTable
