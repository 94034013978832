import React, { useState, useEffect, Key, FC } from "react";
import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import { MoreOutlined } from "@ant-design/icons";
import { Dropdown, Space, Menu } from "antd";
import { FeedsDataType } from "../types/DataTypes";
import { isAuth } from "../../../auth/ProtectedRoute";
import axios from "axios";
import SkeletonTable, {
  SkeletonTableColumnsType,
} from "../../../../components/SkeletonTable";
import EditFeedBtn from "../Components/EditFeedBtn";
import FeedDeleteModal from "../Components/FeedDeleteModal";
import ViewFeedBtn from "../Components/ViewFeedBtn";

const FeedsTable: FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [feedsTableData, setFeedsTableData] = useState<FeedsDataType[]>([]);
  const [showTotal, setShowTotal] = useState<number>();

  const [record, setRecord] = useState<FeedsDataType>();

  //antd feed table menu dropdown
  const menu = (
    <Menu
      items={[
        {
          label: <ViewFeedBtn record={record!} />,
          key: "1",
          icon: <i className="fa fa-eye" aria-hidden="true"></i>,
        },
        {
          label: <EditFeedBtn record={record!} />,
          key: "2",
          icon: <i className="fa fa-pencil" aria-hidden="true"></i>,
        },
        {
          label: <FeedDeleteModal record={record!} />,
          key: "3",
          icon: <i className="fa fa-trash" aria-hidden="true"></i>,
        },
      ]}
    />
  );

  //column definition for table
  const columns: ColumnsType<FeedsDataType> = [
    {
      title: "ID",
      dataIndex: "id",
    },

    {
      title: "Full Name",
      dataIndex: "account_name",
      render: (text) => (
        <div>
          <p className="user_name">{text}</p>
        </div>
      ),
    },

    {
      title: "Content",
      dataIndex: "content",
      render: (text) => (
        <div>
          <p className="user_name1">{text}</p>
        </div>
      ),
    },
    {
      title: "Likes",
      dataIndex: "like",
      render: (num) => (
        <div>
          <p className="user_name">{num}</p>
        </div>
      ),
    },
    {
      title: "Comments",
      dataIndex: "comment",
      render: (num) => (
        <div>
          <p className="user_name">{num}</p>
        </div>
      ),
    },
    {
      title: "Shares",
      dataIndex: "share",
      render: (num) => (
        <div>
          <p className="user_name">{num}</p>
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      render: () => (
        <div>
          <Space wrap>
            <Dropdown.Button
              overlay={menu}
              placement="bottom"
              icon={<MoreOutlined />}
              trigger={["click"]}
            ></Dropdown.Button>
          </Space>
        </div>
      ),
    },
  ];

  //api request for table data
  const showFeedsTable = (page: number) => {
    axios({
      method: "GET",
      url: process.env.REACT_APP_HOST_API + `manage-feeds/feed?page=${page}`,
      headers: {
        Authorization: `Bearer ${isAuth()}`,
      },
    })
      .then((res) => {
        setLoading(false);
        if (res.data.response === true && res.data.status === 200) {
          // console.log(res.data.data.total);

          setFeedsTableData(
            res.data.data.data.map((feedData: FeedsDataType) => ({
              key: feedData.id,
              id: feedData.id,
              account_name: feedData.account_name,
              content: feedData.content,
              like: feedData.likes,
              comment: feedData.comments,
              share: feedData.shares,
              action: "",
            }))
          );
          setShowTotal(res.data.data.total);
        } else {
          // console.log(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    showFeedsTable(1);
  }, []);

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys: Key[], selectedRows: FeedsDataType[]) => {
      console.log(
        `selectedRowKeys: ${selectedRowKeys}`,
        "selectedRows: ",
        selectedRows
      );
    },
    getCheckboxProps: (record: FeedsDataType) => ({
      // disabled: record.accounidt_name === "Disabled User", // Column configuration not to be checked
      // account_name: record.account_name,
    }),
  };
  const [selectionType, setSelectionType] = useState<"checkbox" | "radio">(
    "checkbox"
  );

  return (
    <SkeletonTable
      loading={loading}
      columns={columns as SkeletonTableColumnsType[]}
    >
      <Table
        rowSelection={{
          type: selectionType,
          ...rowSelection,
        }}
        columns={columns}
        dataSource={feedsTableData}
        pagination={{
          showSizeChanger: false,
          pageSize: 20,
          total: showTotal,
          onChange: (page) => {
            showFeedsTable(page);
          },
          showTotal: (showTotal, range) =>
            `${range[0]}-${range[1]} of ${showTotal} items`,
        }}
        onRow={(record) => {
          return {
            onClick: () => {
              setRecord(record);
            },
          };
        }}
      />
    </SkeletonTable>
  );
};

export default FeedsTable;
