import React, { FC } from "react";

const SkillTab: FC = () => {
  return (
    <>
      <div>
        <div className="user__edit--tab">
          <div className="skill">
            <div className="skill_in">
              <p>Industry Knowledge</p>
              <ul>
                <li>Javascript</li>
              </ul>
              <ul>
                <li>Lingo</li>
              </ul>
            </div>
          </div>

          {/* <div className="skill-one">
              <div className="skill_in">
                <p>Tools</p>
                <ul>
                  <li>a</li>
                  <li>User Experience Design</li>
              <li>Web Design</li>
              <li>Mobile App Design</li>
                </ul>
              </div>
            </div> */}
        </div>
      </div>
    </>
  );
};
export default SkillTab;
