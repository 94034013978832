import React, { useEffect, useState } from "react";
import { Tabs, Tab, TabPanel, TabList } from "react-tabs";
import CancelledProjectOrderTable from "./tables/CancelledProjectOrderTable";
import CompletedProjectOrderTable from "./tables/CompletedProjectOrderTable";
import OngoingProjectOrderTable from "./tables/OngoingProjectOrderTable";
import { isAuth } from "../../auth/ProtectedRoute";
import axios from "axios";

const MarketplaceOrderManagementTab = () => {
  const [ongoingTotal, setOngoingTotal] = useState<number>(0);
  const [completedTotal, setCompletedTotal] = useState<number>(0);
  const [cancelledTotal, setCancelledTotal] = useState<number>(0);
  const getOngoingTotal = (newValue: number) => {
    setOngoingTotal(newValue);
  };

  useEffect(() => {
    axios({
      method: "GET",
      url:
        process.env.REACT_APP_HOST_API +
        `order-management/list?status=completed`, //change status to ongoing to see data, completed order data is currently empty
      headers: {
        Authorization: `Bearer ${isAuth()}`,
      },
    })
      .then((res) => {
        if (res.data.response === true && res.data.status === 200) {
          setCompletedTotal(res?.data?.data?.length);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    axios({
      method: "GET",
      url:
        process.env.REACT_APP_HOST_API +
        `order-management/list?status=cancelled`, //change status to ongoing to see data, cancelled order data is currently empty
      headers: {
        Authorization: `Bearer ${isAuth()}`,
      },
    })
      .then((res) => {
        if (res.data.response === true && res.data.status === 200) {
          setCancelledTotal(res?.data?.data?.length);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <Tabs>
      <TabList>
        <Tab
          style={{
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: "16px",
            lineHeight: "24px",
            color: "rgb(140, 140, 140)",
          }}
        >
          Ongoing{" "}
          <span
            style={{
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "14px",
              textAlign: "center",
              color: "rgb(255, 255, 255)",
              padding: "5px 8px",
              width: " 34px",
              height: "0px;",
              background: "rgb(72, 149, 239)",
              borderRadius: "100px",
              display: "inline-table",
            }}
          >
            {ongoingTotal}
          </span>
        </Tab>
        <Tab
          style={{
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: "16px",
            lineHeight: "24px",
            color: "rgb(140, 140, 140)",
          }}
        >
          Completed{" "}
          <span
            style={{
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "14px",
              textAlign: "center",
              color: "rgb(255, 255, 255)",
              padding: "5px 8px",
              width: " 34px",
              height: "0px;",
              background: "rgb(72, 149, 239)",
              borderRadius: "100px",
              display: "inline-table",
            }}
          >
            {completedTotal}
          </span>
        </Tab>
        <Tab
          style={{
            fontFamily: "Poppins",
            fontStyle: "normal",
            fontWeight: 500,
            fontSize: "16px",
            lineHeight: "24px",
            color: "rgb(140, 140, 140)",
          }}
        >
          Cancelled{" "}
          <span
            style={{
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "14px",
              textAlign: "center",
              color: "rgb(255, 255, 255)",
              padding: "5px 8px",
              width: " 34px",
              height: "0px;",
              background: "rgb(72, 149, 239)",
              borderRadius: "100px",
              display: "inline-table",
            }}
          >
            {cancelledTotal}
          </span>
        </Tab>
      </TabList>
      <TabPanel className="pending-button">
        <OngoingProjectOrderTable getOngoingTotal={getOngoingTotal} />
      </TabPanel>
      <TabPanel className="approval-button">
        <CompletedProjectOrderTable />
      </TabPanel>
      <TabPanel className="approval-button">
        <CancelledProjectOrderTable />
      </TabPanel>
      <TabPanel className="approval-button">
        {/* <MediationProjectOrderTable /> */}
      </TabPanel>
    </Tabs>
  );
};
export default MarketplaceOrderManagementTab;
