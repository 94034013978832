import React, { FC } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import AdminLayout from "./layouts/AdminLayout";
import AuthLayout from "./layouts/AuthLayout";
import Admin from "./pages/admin";
import Auth from "./pages/auth";
import Login from "./pages/auth/Login";
// import { TokenProvider, TokenContext } from "./pages/auth/ProtectedRoute";
import "./App.scss";
import { isAuth } from "./pages/auth/ProtectedRoute";
import Registration from "./pages/auth/Registration";
import ForgetPassword from "./pages/auth/forgetPassword";
import Reset from "./pages/auth/passwordReset";
import Verification from "./pages/auth/verification";
// import Vendor from "./pages/vendor";

export const PrivateRoute = ({ children }: any) => {
  const auth = isAuth();
  // console.log(auth);
  return auth ? children : <Navigate to="/" />;
};

const App: FC = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route element={<AuthLayout />}>
            <Route path="/" element={<Login />} />
            <Route path="verify_email" element={<Registration />} />
            <Route path="forgot-password" element={<ForgetPassword />} />
            <Route path="password-reset" element={<Reset />} />
            <Route path="verification-code" element={<Verification />} />
            <Route
              path="/auth/*"
              element={
                <PrivateRoute>
                  <Auth />
                </PrivateRoute>
              }
            />
          </Route>
          <Route
            element={
              <PrivateRoute>
                <AdminLayout />
              </PrivateRoute>
            }
          >
            <Route
              path="/admin/*"
              element={
                <PrivateRoute>
                  <Admin />
                </PrivateRoute>
              }
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
