import React, { useState, Key, useEffect, FC } from "react";
import "antd/dist/antd.css";
import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import type { TableRowSelection } from "antd/es/table/interface";
import { MoreOutlined } from "@ant-design/icons";
import { Dropdown, Menu, Space } from "antd";
import { RoleDataType } from "../types/DataType";
import EditRoleButton from "../components/EditRoleButton";
import ViewBtn from "../components/ViewBtn";
import AssignBtn from "../components/AssignBtn";
import Duplicate from "../components/DuplicateModal";
import "../../../../styles/system.admin.scss";
import axios from "axios";
import { isAuth } from "../../../auth/ProtectedRoute";
import SkeletonTable, {
  SkeletonTableColumnsType,
} from "../../../../components/SkeletonTable";
import DeleteRoleModal from "../components/DeleteRoleModal";
import swal from "sweetalert";

const AdminRoleTable: FC = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [roleTableData, setroleTableData] = useState<RoleDataType[]>([]);
  const [showTotal, setShowTotal] = useState<number>();
  // eslint-disable-next-line
  const [record, setRecord] = useState<{ id: number | null; name: string }>({
    id: null,
    name: "",
  });

  const menu = (
    <Menu
      items={[
        {
          label: <EditRoleButton record={record} />,
          key: "1",
          icon: <i className="fa fa-pencil" aria-hidden="true"></i>,
        },
        {
          label: <AssignBtn record={record} />,
          key: "2",
          icon: <i className="fa fa-building" aria-hidden="true"></i>,
        },
        {
          label: <Duplicate record={record} />,
          key: "3",
          icon: <i className="fa fa-window-restore" aria-hidden="true"></i>,
        },
        {
          label: <ViewBtn record={record} />,
          key: "4",
          icon: <i className="fa fa-eye" aria-hidden="true"></i>,
        },
        record.id !== 1
          ? {
              label: <DeleteRoleModal record={record} />,
              key: "5",
              icon: <i className="fa fa-trash" aria-hidden="true"></i>,
            }
          : null,
      ]}
    />
  );

  const columns: ColumnsType<RoleDataType> = [
    {
      title: "Id",
      dataIndex: "id",
    },
    {
      title: "Roles",
      dataIndex: "name",
      render: (text) => (
        <div className="status">
          <p>{text}</p>
        </div>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      render: (text) => (
        <div className="status">
          <p>{text}</p>
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      render: () => (
        <div>
          <Space wrap>
            <Dropdown.Button
              overlay={menu}
              placement="bottomCenter"
              icon={<MoreOutlined />}
              trigger={["click"]}
            ></Dropdown.Button>
          </Space>
        </div>
      ),
    },
  ];

  const showRoleTable = (page: number) => {
    axios({
      method: "GET",
      url: process.env.REACT_APP_HOST_API + `role?page=${page}`,
      headers: {
        Authorization: `Bearer ${isAuth()}`,
      },
    })
      .then((res) => {
        setLoading(false);
        if (res.data.response === true) {
          setroleTableData(
            res.data.data.data.map((roleData: RoleDataType) => ({
              key: roleData.id,
              id: roleData.id,
              name: roleData.name,
              description: roleData.description,
              action: "",
            }))
          );
          setShowTotal(res.data.data.total);
        }
      })
      .catch((err) => {
        console.error(err);
        swal({
          title: "Error!",
          text: "Something went wrong. Please try again later!",
          icon: "error",
        });
      });
  };

  useEffect(() => {
    showRoleTable(1);
  }, []);

  const onSelectChange = (newSelectedRowKeys: Key[]) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection: TableRowSelection<RoleDataType> = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
      {
        key: "odd",
        text: "Select Odd Row",
        onSelect: (changableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys.filter((_, index) => {
            if (index % 2 !== 0) {
              return false;
            }
            return true;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
      {
        key: "even",
        text: "Select Even Row",
        onSelect: (changableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys.filter((_, index) => {
            if (index % 2 !== 0) {
              return true;
            }
            return false;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
    ],
  };

  return (
    <SkeletonTable
      loading={loading}
      columns={columns as SkeletonTableColumnsType[]}
    >
      <Table
        rowSelection={rowSelection}
        columns={columns}
        dataSource={roleTableData}
        pagination={{
          showSizeChanger: false,
          pageSize: 20,
          total: showTotal,
          onChange: (page) => {
            showRoleTable(page);
          },
          showTotal: (showTotal, range) =>
            `${range[0]}-${range[1]} of ${showTotal} items`,
        }}
        onRow={(record) => {
          return {
            onClick: () => {
              setRecord(record);
            },
          };
        }}
      />
    </SkeletonTable>
  );
};

export default AdminRoleTable;
