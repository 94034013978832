import React, { useEffect, useState, FC } from "react"
import { Tabs, Tab, TabPanel, TabList } from "react-tabs"
import "react-tabs/style/react-tabs.css"
import PendingApprovalTable from "./table/PendingApproval"
import ApprovalTable from "./table/Approval"
import RejectedTable from "./table/Rejected"
import axios from "axios"
import { isAuth } from "../../../auth/ProtectedRoute"

const CourseTab: FC = () => {
  const [pendingTotal, setPendingTotal] = useState<number>(0)
  const [approvedTotal, setApprovedTotal] = useState<number>(0)
  const [rejectedTotal, setRejectedTotal] = useState<number>(0)

  //   const getPendingTotal = (newValue: number) => {
  //     setPendingTotal(newValue)
  //   }
  //   useEffect(() => {
  //     axios({
  //       method: "GET",
  //       url: process.env.REACT_APP_HOST_API + `projects/approved`,
  //       headers: {
  //         Authorization: `Bearer ${isAuth()}`,
  //       },
  //     })
  //       .then(res => {
  //         if (res.data.response === true && res.data.status === 200) {
  //           setApprovedTotal(res?.data?.data?.total)
  //         }
  //       })
  //       .catch(err => {
  //         console.log(err)
  //       })
  //   }, [])

  //   useEffect(() => {
  //     axios({
  //       method: "GET",
  //       url: process.env.REACT_APP_HOST_API + `projects/rejected`,
  //       headers: {
  //         Authorization: `Bearer ${isAuth()}`,
  //       },
  //     })
  //       .then(res => {
  //         if (res.data.response === true && res.data.status === 200) {
  //           setRejectedTotal(res?.data?.data?.total)
  //         }
  //       })
  //       .catch(err => {
  //         console.log(err)
  //       })
  //   }, [])

  return (
    <Tabs>
      <TabList
        style={{
          fontFamily: "Poppins",
          fontStyle: "normal",
          fontWeight: "500",
          fontSize: "16px",
          lineHeight: "24px",
          color: "#8C8C8C",
        }}
      >
        <Tab>
          Pending Approval{" "}
          <span
            style={{
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: "600",
              fontSize: "14px",
              textAlign: "center",
              color: "#FFFFFF",
              padding: "5px 8px",
              width: "34px",
              height: "0px",
              background: "#4895EF",
              borderRadius: "100px",
              display: "inline-table",
            }}
          >
            {pendingTotal}
          </span>
        </Tab>
        <Tab>
          Approved{" "}
          <span
            style={{
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: "600",
              fontSize: "14px",
              textAlign: "center",
              color: "#FFFFFF",
              padding: "5px 8px",
              width: "34px",
              height: "0px",
              background: "#4895EF",
              borderRadius: "100px",
              display: "inline-table",
            }}
          >
            {approvedTotal}
          </span>
        </Tab>
        <Tab>
          Rejected{" "}
          <span
            style={{
              fontFamily: "Poppins",
              fontStyle: "normal",
              fontWeight: "600",
              fontSize: "14px",
              textAlign: "center",
              color: "#FFFFFF",
              padding: "5px 8px",
              width: "34px",
              height: "0px",
              background: "#4895EF",
              borderRadius: "100px",
              display: "inline-table",
            }}
          >
            {rejectedTotal}
          </span>
        </Tab>
      </TabList>
      <TabPanel className="pending-button">
        {/* <PendingApprovalTable getPendingTotal={getPendingTotal} /> */}
        <PendingApprovalTable />
      </TabPanel>
      <TabPanel className="approval-button">
        <ApprovalTable />
      </TabPanel>
      <TabPanel className="approval-button">
        <RejectedTable />
      </TabPanel>
    </Tabs>
  )
}
export default CourseTab
