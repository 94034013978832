import React, { useContext } from "react";
import { Link } from "react-router-dom";
import "../../../styles/task.scss";
import user from "../../../assets/user-img.png";
import ProjectManagementTab from "./ProjectManagementTab";
import { SidebarContext } from "../../auth/ProtectedRoute";

const Project = () => {
  const { userName, sidebarData } = useContext(SidebarContext);
  return (
    <div style={{ padding: "40px" }} className="roless">
      <div className="roless-wrapper">
        <div className="roless--heading">
          <div className="roless--heading-img">
            {/* <img src={user} alt="profile" /> */}
          </div>
        </div>
        <div className="roless--tables">
          <div
            className="roless--tables-heading"
            style={{
              marginBottom: "10px",
            }}
          >
            <div
              className="roless--tables-heading-title"
              style={{
                padding: "5px 0px",
              }}
            >
              <h2>Projects</h2>
            </div>
            <div className="roless--tables-heading-box">
              <div className="formy">
                <form>
                  <div className="formy--box">
                    <input type="text" placeholder="Search.." name="search" />
                    <div className="formy--box-icon">
                      <i className="fa fa-search"></i>
                    </div>
                  </div>
                </form>
              </div>
              <div className="clicks">
                <div className="clicks--right">
                  <Link
                    to="add-project"
                    state={{ userName: userName, sidebar: sidebarData }}
                  >
                    <button>Add Project</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="roless--tables--content">
            <ProjectManagementTab />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Project;
