import React, { useState, useContext, FC, Key, useEffect } from "react"
import { Link } from "react-router-dom"
import { Menu, Table } from "antd"
import type { ColumnsType } from "antd/es/table"
import { UserOutlined } from "@ant-design/icons"
import type { MenuProps } from "antd"
import { Dropdown, message, Space } from "antd"
import { JobPostDataType } from "../types/DataType"
// import UserContact from "../component/UserContact";
import user from "../../../../assets/user-img.png"
import { isAuth, SidebarContext } from "../../../../auth/ProtectedRoute"
import axios from "axios"
import SkeletonTable, { SkeletonTableColumnsType } from "../../../../../components/SkeletonTable"
import moment from "moment"
import { MoreOutlined } from "@ant-design/icons"
import View from "../component/ViewProjectBtn"

const JobPostTable = () => {
  const { userName, sidebarData } = useContext(SidebarContext)
  const [loading, setLoading] = useState<boolean>(false)
  const [projectTableData, setProjectTableData] = useState<JobPostDataType[]>([])
  const [total, setTotal] = useState<number>()
  const [record, setRecord] = useState<JobPostDataType>()

  const jobTypeMap: { [key: string]: string } = {
    "1": "Full-time",
    "2": "Part-time",
    "3": "Contract",
    "4": "Freelance",
  }

  const statusMap: { [key: number]: string } = {
    0: "Not Closed",
    1: "Active",
    2: "Closed",
  }

  const showProjectTable = (page: number) => {
    axios({
      method: "GET",
      url: process.env.REACT_APP_HOST_API + `jobs/all`,
      headers: {
        Authorization: `Bearer ${isAuth()}`,
      },
    })
      .then(res => {
        console.log(res)
        setLoading(false)
        if (res.data.response === true && res.status === 200) {
          console.log("result", res.data.data)
          setProjectTableData(
            res.data.data.data.map((projectData: JobPostDataType) => ({
              key: projectData.id,
              id: projectData.id,
              job_title: projectData.job_title,
              company_name: projectData.company_name,
              field: {
                name: projectData.field.name,
                id: projectData.field.id,
                other_category_id: projectData.field.other_category_id,
                image: process.env.REACT_APP_IMAGE_API_PREFIX + projectData.field.image,
                created_at: projectData.field.created_at,
                updated_at: projectData.field.updated_at,
              },
              job_type: jobTypeMap[projectData.job_type] || projectData.job_type,
              status: statusMap[projectData.status] || projectData.status,
              date_posted: projectData.date_posted,
            }))
          )
          setTotal(res.data.data.data.total)
        } else {
          //console.log(res.data)
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  useEffect(() => {
    showProjectTable(1)
  }, [])
  // const handleMenuClick: MenuProps["onClick"] = e => {
  //   message.info("Click on menu item.")
  //   console.log("click", e)
  // }
  const menu = (
    <Menu
      items={[
        {
          label: <View record={record!} />,
          key: "1",
          icon: <i className="fa fa-eye" aria-hidden="true"></i>,
        },
        // {
        //   //label: <DeleteProjectModal record={record!} />,
        //   key: "2",
        //   icon: <CloseCircleOutlined />,
        // },
        // {
        //   //label: <DeleteProjectModal record={record!} />,
        //   key: "2",
        //   icon: <i className="fa fa-trash" aria-hidden="true"></i>,
        // },
      ]}
    />
  )

  const columns: ColumnsType<JobPostDataType> = [
    {
      title: "Id",
      dataIndex: "id",
      render: text => (
        <div>
          <p className="user_name">{text}</p>
        </div>
      ),
    },

    {
      title: "Job Title",
      dataIndex: "job_title",
      render: text => (
        <div>
          <p className="user_name">{text}</p>
        </div>
      ),
    },

    // {
    //   title: "Company Name",
    //   dataIndex: "company_name",
    //   render: text => (
    //     <div>
    //       <p className="user_name">{text}</p>
    //     </div>
    //   ),
    // },
    // {
    //   title: "Field",
    //   dataIndex: "field",
    //   render: field => (
    //     <div>
    //       <p className="user_name">{field.name}</p>
    //     </div>
    //   ),
    // },
    // {
    //   title: "Job Type",
    //   dataIndex: "job_type",
    //   render: text => (
    //     <div>
    //       <p className="user_name">{text}</p>
    //     </div>
    //   ),
    // },
    {
      title: "Status",
      dataIndex: "status",
      render: text => (
        <div>
          <p className="user_name">{text}</p>
        </div>
      ),
    },
    {
      title: "Date Posted",
      dataIndex: "date_posted",
      render: text => (
        <div>
          <p className="user_name">{moment(text).format("DD/MM/YYYY")}</p>
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      render: ()=> (
        <div>
          <Space wrap>
            <Dropdown.Button
              overlay={menu}
              placement="bottom"
              icon={<MoreOutlined />}
              trigger={["click"]}
            ></Dropdown.Button>
          </Space>
        </div>
      ),
    },
  ]

  const data: JobPostDataType[] = [
    {
      key: "1",
      id: "",
      job_title: "Food Engineer",
      company_name: "Nigeria breweries company",
      job_type: "",
      field: {
        id: 1,
        other_category_id: 2,
        name: "Engineering",
        image: "https://example.com/engineering.jpg",
        created_at: "2023-04-01T00:00:00.000Z",
        updated_at: "2023-04-01T00:00:00.000Z",
      },
      status: 0,
      date_posted: "23/08/2023",
      action: "",
    },
  ]

  // rowSelection object indicates the need for row selection
  const rowSelection = {
    onChange: (selectedRowKeys: Key[], selectedRows: JobPostDataType[]) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, "selectedRows: ", selectedRows)
    },
    // getCheckboxProps: (record: JobPostDataType) => ({
    //   disabled: record.id === "Disabled User", // Column configuration not to be checked
    //   name: record.id,
    // }),
  }
  const [selectionType, setSelectionType] = useState<"checkbox" | "radio">("checkbox")

  return (
    <SkeletonTable loading={loading} columns={columns as SkeletonTableColumnsType[]}>
      <Table
        rowSelection={{
          type: selectionType,
          ...rowSelection,
        }}
        columns={columns}
        dataSource={projectTableData}
        pagination={{
          showSizeChanger: false,
          pageSize: 20,
          total: total,
          onChange: page => {
            showProjectTable
          },
          showTotal: (showTotal, range) => `${range[0]}-${range[1]} of ${showTotal} items`,
        }}
        onRow={record => {
          return {
            onClick: () => {
              setRecord(record)
            },
          }
        }}
      />
    </SkeletonTable>
  )
}

export default JobPostTable
