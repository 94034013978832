import React, { useState, Key, useEffect, FC } from "react";
import "antd/dist/antd.css";
import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import type { TableRowSelection } from "antd/es/table/interface";
import { LocationDataType } from "../types/DataType";
import axios from "axios";
import { isAuth } from "../../../auth/ProtectedRoute";
import SkeletonTable, {
  SkeletonTableColumnsType,
} from "../../../../components/SkeletonTable";
import "../../../../styles/modules.scss";
import swal from "sweetalert";

const LocationTable: FC = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [locationTableData, setLocationTableData] = useState<
    LocationDataType[]
  >([]);
  const [showTotal, setShowTotal] = useState<number>();
  const [record, setRecord] = useState<{
    // eslint-disable-next-line
    id: number | null;
    name: string;
    // eslint-disable-next-line
    code: string;
  }>({
    id: null,
    name: "",
    code: "",
  });

  const columns: ColumnsType<LocationDataType> = [
    {
      title: "Id",
      dataIndex: "id",
    },
    {
      title: "Code",
      dataIndex: "code",
      render: (text: string) => (
        <div className="status">
          <p>{text}</p>
        </div>
      ),
    },
    {
      title: "Country Name",
      dataIndex: "name",
      render: (text: string) => (
        <div className="status">
          <p>{text}</p>
        </div>
      ),
    },
    {
      title: "Dial Code",
      dataIndex: "phonecode",
      render: (text: string) => (
        <div className="status">
          <p>{text}</p>
        </div>
      ),
    },
    {
      title: "Flag",
      dataIndex: "flag",
      render: (text: any) => (
        <div className="status">
          <img
            src={process.env.REACT_APP_IMAGE_API_PREFIX + text}
            alt="flag"
            width={40}
            height={30}
          />
        </div>
      ),
    },
  ];

  const showLocationTable = (page: number) => {
    axios({
      method: "GET",
      url:
        process.env.REACT_APP_HOST_API +
        `manage-locations/location?page=${page}`,
      headers: {
        Authorization: `Bearer ${isAuth()}`,
      },
    })
      .then((res) => {
        setLoading(false);
        if (res.data.response) {
          setLocationTableData(
            res.data.data.map((location: LocationDataType) => ({
              key: location.id,
              id: location.id,
              code: location.code,
              name: location.name,
              phonecode: location.phonecode,
              flag: location.flag.svg,
            }))
          );
          setShowTotal(res.data.data.total);
        }
      })
      .catch((err) => {
        console.error(err);
        swal({
          title: "Error!",
          text: "Something went wrong. Please try again later!",
          icon: "error",
        });
      });
  };

  useEffect(() => {
    showLocationTable(1);
  }, []);

  const onSelectChange = (newSelectedRowKeys: Key[]) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection: TableRowSelection<LocationDataType> = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
      {
        key: "odd",
        text: "Select Odd Row",
        onSelect: (changableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys.filter((_, index) => {
            if (index % 2 !== 0) {
              return false;
            }
            return true;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
      {
        key: "even",
        text: "Select Even Row",
        onSelect: (changableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys.filter((_, index) => {
            if (index % 2 !== 0) {
              return true;
            }
            return false;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
    ],
  };

  return (
    <SkeletonTable
      loading={loading}
      columns={columns as SkeletonTableColumnsType[]}
    >
      <Table
        rowSelection={rowSelection}
        columns={columns}
        dataSource={locationTableData}
        pagination={{
          showSizeChanger: false,
          pageSize: 20,
          total: showTotal,
          onChange: (page) => {
            showLocationTable(page);
          },
          showTotal: (showTotal, range) =>
            `${range[0]}-${range[1]} of ${showTotal} items`,
        }}
        onRow={(record) => {
          return {
            onClick: () => {
              setRecord(record);
            },
          };
        }}
      />
    </SkeletonTable>
  );
};

export default LocationTable;
