import React, { FC } from "react";
const InterestTab: FC = () => {
  return (
    <div>
      <div className="user__edit--tab">
        <div className="skill">
          <div className="skill_in">
            <p>Interests</p>
            <div className="company">
              <div className="company__details">
                <h1>Javascript</h1>
              </div>
            </div>
            <div className="company">
              <div className="company__details">
                <h1>Hausa</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default InterestTab;
