/* eslint-disable */
import React, { useState, Key, Fragment, useEffect, FC } from "react";
import "antd/dist/antd.css";
import { Table } from "antd";
import type { ColumnsType } from "antd/es/table";
import type { TableRowSelection } from "antd/es/table/interface";
import { MoreOutlined } from "@ant-design/icons";
import { Dropdown, Menu, Space } from "antd";
import { UserDataType } from "../types/DataType";
import EditBtnSystemUser from "../components/EditBtnSystemUser";
import "../../../../styles/system.admin.scss";
import SkeletonTable, {
  SkeletonTableColumnsType,
} from "../../../../components/SkeletonTable";
import axios from "axios";
import { isAuth } from "../../../auth/ProtectedRoute";
import DeleteUserModal from "../components/DeleteUserModal";
import ResetUserPasswordModal from "../components/ResetUserPasswordModal";
import swal from "sweetalert";
import CancelUserInvite from "../components/CancelUserInvite";
import ResendUserInvite from "../components/ResendUserInvite";

const SystemUserTable: FC = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [adminUserTableData, setAdminUserTableData] = useState<UserDataType[]>(
    []
  );
  const [showTotal, setShowTotal] = useState<number>();

  const [record, setRecord] = useState<{
    id: number | null;
    status: number | null;
    name: string;
  }>({ id: null, status: null, name: "" });
  const statusText = (status: number) => {
    switch (status) {
      case 1:
        return (
          <Fragment>
            <div className="user-active-status">
              <span>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="8.25" cy="8" r="7.5" fill="#32C769" />
                  <path
                    d="M6.53125 8L7.86458 9.5L10.5313 6.5"
                    stroke="white"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
              Active
            </div>
          </Fragment>
        );
      case 2:
        return (
          <Fragment>
            <div className="user-inactive-status">
              <span>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="8.25" cy="8" r="7.5" fill="#666666" />
                  <path
                    d="M6.53125 8L7.86458 9.5L10.5313 6.5"
                    stroke="white"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
              Deleted
            </div>
          </Fragment>
        );
      case 3:
        return (
          <Fragment>
            <div className="user-cancelled-status">
              <span>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="8.25" cy="8" r="7.5" fill="#F47F20" />
                  <path
                    d="M6.53125 8L7.86458 9.5L10.5313 6.5"
                    stroke="white"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
              Cancelled
            </div>
          </Fragment>
        );
      case 0:
        return (
          <Fragment>
            <div className="user-pending-status">
              <span>
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="8.25" cy="8" r="7.5" fill="#4895ef" />
                  <path
                    d="M6.53125 8L7.86458 9.5L10.5313 6.5"
                    stroke="white"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
              Invited
            </div>
          </Fragment>
        );
    }
  };

  const menu = (
    <Menu
      items={[
        {
          label: <EditBtnSystemUser record={record} />,
          key: "1",
          icon: <i className="fa fa-pencil" aria-hidden="true"></i>,
        },
        {
          label: <ResetUserPasswordModal record={record} />,
          key: "2",
          icon: <i className="fa fa-undo" aria-hidden="true"></i>,
        },
        record.status !== 1
          ? {
              label: <ResendUserInvite record={record} />,
              key: "3",
              icon: (
                <svg
                  width="15"
                  height="16"
                  viewBox="0 0 15 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.706 1.78932L12.4686 1.61503C12.7119 1.55953 12.864 1.31727 12.8084 1.07391C12.7529 0.830729 12.5107 0.678455 12.2675 0.734136L10.4259 1.15481C10.1827 1.21049 10.0304 1.45276 10.0861 1.69594L10.5068 3.53753C10.5546 3.74695 10.7408 3.88867 10.9468 3.88867C10.9801 3.88867 11.014 3.885 11.0478 3.8773C11.291 3.82171 11.4433 3.57945 11.3876 3.33618L11.2062 2.54206C13.2196 3.90922 14.3263 6.27836 14.053 8.72904C13.8579 10.48 12.9926 12.05 11.6165 13.15C10.436 14.0937 9.00076 14.5947 7.51104 14.5946C7.26447 14.5946 7.01625 14.5808 6.76757 14.5531C6.51934 14.5254 6.29616 14.7042 6.26846 14.9521C6.24075 15.2001 6.41945 15.4235 6.66739 15.4512C6.95029 15.4827 7.23227 15.4983 7.51279 15.4983C9.20651 15.4981 10.8384 14.9288 12.1807 13.8557C13.7452 12.605 14.7292 10.8199 14.9511 8.82921C15.2621 6.0394 14.0004 3.3426 11.706 1.78932Z"
                    fill="#666666"
                  />
                  <path
                    d="M4.41328 12.3716C4.35778 12.1284 4.11542 11.9762 3.87215 12.0318C3.62898 12.0875 3.47689 12.3295 3.53238 12.5729L3.72355 13.4096C1.75317 12.0357 0.674503 9.69228 0.944651 7.2693C1.13976 5.51834 2.00506 3.94836 3.38121 2.84833C4.75727 1.74838 6.47933 1.25019 8.2302 1.44521C8.47824 1.47328 8.70152 1.29422 8.72922 1.04627C8.75683 0.798229 8.57832 0.574864 8.33028 0.547162C6.33953 0.325173 4.38154 0.891795 2.81688 2.14264C1.25241 3.3933 0.268594 5.17848 0.0466963 7.16913C-0.259135 9.91134 0.954741 12.5637 3.17537 14.1287L2.45142 14.2941C2.20825 14.3496 2.05597 14.5918 2.11165 14.8352C2.15944 15.0444 2.34557 15.1863 2.55169 15.1863C2.58498 15.1863 2.61883 15.1827 2.65268 15.175L4.49418 14.7543C4.73745 14.6986 4.88954 14.4563 4.83395 14.2132L4.41328 12.3716Z"
                    fill="#666666"
                  />
                  <path
                    d="M3.60793 11.0572H11.4414C11.6909 11.0572 11.8932 10.855 11.8932 10.6055V5.59375L7.82884 9.29344C7.74271 9.37178 7.63373 9.41104 7.52466 9.41104C7.41569 9.41104 7.3068 9.37178 7.22058 9.29344L3.15625 5.59375V10.6055C3.15625 10.855 3.35852 11.0572 3.60793 11.0572Z"
                    fill="#666666"
                  />
                  <path
                    d="M11.265 4.94141H3.78125L7.52305 8.34738L11.265 4.94141Z"
                    fill="#666666"
                  />
                </svg>
              ),
            }
          : null,
        record.status === 0
          ? {
              label: <CancelUserInvite record={record} />,
              key: "4",
              icon: (
                <svg
                  width="15"
                  height="16"
                  viewBox="0 0 15 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.49758 15.5C6.51259 15.5018 5.53697 15.309 4.62676 14.9325C3.71655 14.5561 2.88972 14.0035 2.19373 13.3065C-0.731244 10.3816 -0.731244 5.62262 2.19373 2.69766C2.88866 1.99882 3.71532 1.44475 4.62584 1.06753C5.53633 0.690301 6.51262 0.497412 7.49819 0.500026C9.50197 0.500026 11.3858 1.28018 12.802 2.69766C14.2189 4.11454 14.9996 5.99829 14.9996 8.0021C14.9996 10.0059 14.2195 11.8897 12.802 13.3065C12.1059 14.0035 11.279 14.5561 10.3687 14.9325C9.45835 15.309 8.48264 15.5018 7.49758 15.5V15.5ZM7.49819 1.70026C6.67027 1.69791 5.85014 1.85986 5.08527 2.17673C4.3204 2.4936 3.626 2.95908 3.0423 3.54621C1.85226 4.73627 1.19693 6.31876 1.19693 8.0021C1.19693 9.68544 1.85226 11.2674 3.0423 12.458C5.49918 14.9149 9.49717 14.9155 11.9535 12.458C13.1435 11.268 13.7994 9.68544 13.7994 8.0021C13.7994 6.31876 13.1441 4.73685 11.9535 3.54621C11.3697 2.95925 10.6754 2.49389 9.91071 2.17703C9.14594 1.86017 8.32598 1.69812 7.49819 1.70026V1.70026Z"
                    fill="#666666"
                  />
                  <path
                    d="M4.95241 11.1476C4.83361 11.1479 4.71739 11.1128 4.61855 11.0469C4.5197 10.981 4.44264 10.8873 4.3972 10.7775C4.35172 10.6677 4.33992 10.5469 4.36321 10.4304C4.38652 10.314 4.44393 10.207 4.52813 10.1232L9.62013 5.0312C9.67584 4.97549 9.74199 4.9313 9.81479 4.90112C9.88757 4.87097 9.96561 4.85547 10.0444 4.85547C10.1232 4.85547 10.2012 4.87097 10.274 4.90112C10.3468 4.9313 10.413 4.97549 10.4687 5.0312C10.5244 5.08691 10.5686 5.15306 10.5988 5.22587C10.6289 5.29868 10.6444 5.37669 10.6444 5.45548C10.6444 5.53427 10.6289 5.61231 10.5988 5.68512C10.5686 5.7579 10.5244 5.82404 10.4687 5.87976L5.37668 10.9718C5.32111 11.0277 5.25499 11.072 5.18215 11.1022C5.10934 11.1324 5.03123 11.1478 4.95241 11.1476V11.1476Z"
                    fill="#666666"
                  />
                  <path
                    d="M10.0436 11.1476C9.96478 11.1477 9.88674 11.1322 9.81393 11.102C9.74109 11.0719 9.67497 11.0276 9.61933 10.9718L4.5273 5.87976C4.47158 5.82404 4.4274 5.7579 4.39725 5.68512C4.3671 5.61231 4.35156 5.53427 4.35156 5.45548C4.35156 5.37669 4.3671 5.29868 4.39725 5.22587C4.4274 5.15306 4.47158 5.08691 4.5273 5.0312C4.58304 4.97549 4.64919 4.9313 4.72196 4.90112C4.79477 4.87097 4.87282 4.85547 4.95161 4.85547C5.0304 4.85547 5.10841 4.87097 5.18122 4.90112C5.25403 4.9313 5.32017 4.97549 5.37588 5.0312L10.4679 10.1232C10.5521 10.207 10.6095 10.314 10.6328 10.4304C10.6561 10.5469 10.6443 10.6677 10.5988 10.7775C10.5533 10.8873 10.4763 10.981 10.3775 11.0469C10.2786 11.1128 10.1624 11.1479 10.0436 11.1476V11.1476Z"
                    fill="#666666"
                  />
                </svg>
              ),
            }
          : null,
        {
          label: <DeleteUserModal record={record} />,
          key: "5",
          icon: <i className="fa fa-trash" aria-hidden="true"></i>,
        },
      ]}
    />
  );

  const columns: ColumnsType<UserDataType> = [
    {
      title: "ID",
      dataIndex: "id",
    },
    {
      title: "Name",
      dataIndex: "name",
      render: (text) => (
        <div className="status">
          <p>{text}</p>
        </div>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      render: (text) => (
        <div className="status">
          <p>{text}</p>
        </div>
      ),
    },
    {
      title: "Mobile No",
      dataIndex: "mobileNo",
      render: (text) => (
        <div className="status">
          <p>{text}</p>
        </div>
      ),
    },
    {
      title: "Date Created",
      dataIndex: "dateCreated",
      render: (text) => (
        <div className="status">
          <p>{text}</p>
        </div>
      ),
    },
    {
      title: "Last Login",
      dataIndex: "last_logged_in",
      render: (text) => (
        <div className="status">
          <p>{text}</p>
        </div>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (status: number) => <Fragment>{statusText(status)}</Fragment>,
    },
    {
      title: "User Permission Type",
      dataIndex: "userPermissionType",
      render: (text) => (
        <div className="status">
          <p>{text}</p>
        </div>
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (text) => (
        <div>
          <Space wrap>
            <Dropdown.Button
              overlay={menu}
              placement="bottom"
              trigger={["click"]}
              icon={<MoreOutlined />}
            ></Dropdown.Button>
          </Space>
        </div>
      ),
    },
  ];

  const showAdminUserTable = (page: number) => {
    axios({
      method: "GET",
      url: process.env.REACT_APP_HOST_API + `manage-user?page=${page}`,
      headers: {
        Authorization: `Bearer ${isAuth()}`,
      },
    })
      .then((res) => {
        setLoading(false);
        setAdminUserTableData(
          res.data[0].data.data.map((userData: UserDataType) => ({
            key: userData.id,
            id: userData.id,
            name: userData.name,
            email: userData.email,
            mobileNo: userData.phone1,
            dateCreated: userData.created_at,
            last_logged_in: userData.last_logged_in,
            status: userData.active,
            userPermissionType: userData.role_name,
            action: "",
          }))
        );
        setShowTotal(res.data[0].data.total);
      })
      .catch((err) => {
        swal({
          title: "Error!",
          text: "Something went wrong. Please try again later!",
          icon: "error",
        });
      });
  };

  useEffect(() => {
    showAdminUserTable(1);
  }, []);

  const onSelectChange = (newSelectedRowKeys: Key[]) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection: TableRowSelection<UserDataType> = {
    selectedRowKeys,
    onChange: onSelectChange,
    selections: [
      Table.SELECTION_ALL,
      Table.SELECTION_INVERT,
      Table.SELECTION_NONE,
      {
        key: "odd",
        text: "Select Odd Row",
        onSelect: (changableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys.filter((_, index) => {
            if (index % 2 !== 0) {
              return false;
            }
            return true;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
      {
        key: "even",
        text: "Select Even Row",
        onSelect: (changableRowKeys) => {
          let newSelectedRowKeys = [];
          newSelectedRowKeys = changableRowKeys.filter((_, index) => {
            if (index % 2 !== 0) {
              return true;
            }
            return false;
          });
          setSelectedRowKeys(newSelectedRowKeys);
        },
      },
    ],
  };

  return (
    <SkeletonTable
      loading={loading}
      columns={columns as SkeletonTableColumnsType[]}
    >
      <Table
        rowSelection={rowSelection}
        columns={columns}
        dataSource={adminUserTableData}
        pagination={{
          showSizeChanger: false,
          pageSize: 20,
          total: showTotal,
          onChange: (page) => {
            showAdminUserTable(page);
          },
          showTotal: (showTotal, range) =>
            `${range[0]}-${range[1]} of ${showTotal} items`,
        }}
        onRow={(record) => {
          return {
            onClick: () => {
              setRecord(record);
            },
          };
        }}
      />
    </SkeletonTable>
  );
};

export default SystemUserTable;
