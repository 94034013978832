import React, { useContext, FC } from "react";
import { Link } from "react-router-dom";
import "../../../../styles/system.admin.scss";
import { SidebarContext } from "../../../auth/ProtectedRoute";
import { ActivityLogType } from "../types/DataType";

const ViewLogButton: FC<{
  record: ActivityLogType | undefined;
}> = ({ record }) => {
  const { userName, sidebarData } = useContext(SidebarContext);
  return (
    <Link
      to={`view-log/${record!.id}`}
      state={{ userName: userName, sidebar: sidebarData }}
    >
      <button className="__btn" style={{ fontSize: "12px" }}>
        View Log
      </button>
    </Link>
  );
};
export default ViewLogButton;
