import React, { FC } from "react";

const EducationTab: FC = () => {
  return (
    <div>
      <div className="user__edit--tab">
        <div className="tab-one">
          <div className="right">
            <h1>Jacksonville University</h1>
            <p>Project Management</p>
            <span>M.Sc</span>
          </div>
          <div className="left">
            <span>August 2021 - Date</span>
          </div>
        </div>
        <div className="tab-two">
          <div className="right">
            <h1>Jacksonville University</h1>
            <p>Project Management</p>
            <span>M.Sc</span>
          </div>
          <div className="left">
            <span>August 2021 - Date</span>
          </div>
        </div>
        <div className="tab-three">
          <div className="right">
            <h1>Jacksonville University</h1>
            <p>Project Management</p>
            <span>M.Sc</span>
          </div>
          <div className="left">
            <span>August 2021 - Date</span>
          </div>
        </div>
        <div className="tab-four">
          <div className="right">
            <h1>Jacksonville University</h1>
            <p>Project Management</p>
            <span>M.Sc</span>
          </div>
          <div className="left">
            <span>August 2021 - Date</span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default EducationTab;
