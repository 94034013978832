// skeleton-table.tsx

/*eslint-disable*/
import { Skeleton, SkeletonProps, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { SkeletonTitleProps } from "antd/lib/skeleton/Title";

export type SkeletonTableColumnsType = {
  key: string;
};

type SkeletonTableProps = SkeletonProps &
  SkeletonTitleProps & {
    columns: ColumnsType<SkeletonTableColumnsType>;
    rowCount?: number;
  };

function SkeletonTable({
  loading = false,
  active = false,
  rowCount = 10,
  columns,
  children,
  className,
}: SkeletonTableProps): JSX.Element {
  return loading ? (
    <Table
      rowKey="key"
      pagination={false}
      dataSource={[...Array(rowCount)].map((_, index) => ({
        key: `key${index}`,
      }))}
      columns={columns.map((column) => {
        return {
          ...column,
          render: function renderPlaceholder() {
            return (
              <Skeleton
                key={column.key}
                title
                active={true}
                round={true}
                paragraph={false}
                className={className}
              />
            );
          },
        };
      })}
    />
  ) : (
    <>{children}</>
  );
}

export default SkeletonTable;
