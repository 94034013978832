import useModal from "../../../components/modal/useModal";
import React, { Fragment, FC } from "react";
import user from "../../../assets/user-img.png";
import AddModuleModal from "./components/AddModuleModal";
import AdminModuleTable from "./tables/AdminModuleTable";
import "../../../styles/modals.scss";

const AdminModule: FC = () => {
  const { isOpen, toggle } = useModal();
  return (
    <Fragment>
      <div className="roless">
        <div className="roless-wrapper">
          <div className="roless--heading">
            <div className="roless--heading-img">
              <img src={user} alt="profile" />
            </div>
          </div>
          <div className="roless--tables">
            <div className="roless--tables-heading">
              <div className="roless--tables-heading-title">
                <h2>Modules</h2>
              </div>
              <div className="roless--tables-heading-box">
                <div className="formy">
                  <form>
                    <div className="formy--box">
                      <input type="text" placeholder="Search.." name="search" />
                      <div className="formy--box-icon">
                        <i className="fa fa-search"></i>
                      </div>
                    </div>
                  </form>
                </div>
                <div className="clicks">
                  <div className="clicks--right">
                    <button onClick={toggle}>
                      <span>
                        <i className="fa fa-plus-circle" aria-hidden="true"></i>
                      </span>
                      Add Module
                    </button>
                    <AddModuleModal
                      isOpen={isOpen}
                      toggle={toggle}
                    ></AddModuleModal>
                  </div>
                  <div className="clicks--left">
                    <button>
                      <span>
                        <svg
                          width="20"
                          height="19"
                          viewBox="0 0 20 19"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M8.89922 0.00571511C11.0592 -0.0678078 13.9216 0.536999 17.9459 3.63717L17.9472 1.92816C17.9476 1.39137 18.4079 0.956517 18.9752 0.956895C19.5426 0.957273 20.0022 1.39274 20.0018 1.92953L19.9995 4.96628C19.9987 6.0388 19.08 6.90807 17.9464 6.90881L14.7487 6.91091C14.1813 6.91129 13.7211 6.47643 13.7207 5.93964C13.7203 5.40284 14.1799 4.96738 14.7472 4.96701L16.3884 4.96593C12.8494 2.32441 10.5278 1.95144 8.96726 2.00456C7.63908 2.04977 6.55053 2.20472 5.47297 2.66153C4.39434 3.11878 3.2545 3.90981 1.88825 5.33153C1.50557 5.72975 0.872529 5.74234 0.474314 5.35966C0.0760979 4.97698 0.0635023 4.34395 0.446181 3.94573C1.94099 2.39022 3.29689 1.41172 4.69237 0.820151C6.08892 0.228122 7.45284 0.054947 8.89922 0.00571511ZM11.1026 17.998C8.94254 18.0716 6.08019 17.4668 2.05584 14.3666L2.05456 16.0756C2.05416 16.6124 1.59391 17.0472 1.02656 17.0469C0.459206 17.0465 -0.0003984 16.611 2.59151e-07 16.0742L0.00226044 13.0375C0.00305962 11.965 0.921816 11.0957 2.0554 11.095L5.25312 11.0928C5.82048 11.0925 6.28072 11.5273 6.28112 12.0641C6.28151 12.6009 5.8219 13.0364 5.25455 13.0368L3.61339 13.0378C7.15241 15.6794 9.47396 16.0523 11.0345 15.9992C12.3627 15.954 13.4513 15.799 14.5288 15.3422C15.6074 14.885 16.7473 14.094 18.1135 12.6722C18.4962 12.274 19.1293 12.2614 19.5275 12.6441C19.9257 13.0268 19.9383 13.6598 19.5556 14.058C18.0608 15.6135 16.7049 16.592 15.3094 17.1836C13.9129 17.7756 12.5489 17.9488 11.1026 17.998Z"
                            fill="#8C8C8C"
                          />
                        </svg>
                      </span>
                      swap
                    </button>
                  </div>
                  <div className="clicks--left">
                    <button>
                      <span>
                        <i className="fa fa-trash" aria-hidden="true"></i>
                      </span>
                      Delete
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="roless--tables--content">
              <AdminModuleTable />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default AdminModule;
