import React, { FC } from "react";
import { Link } from "react-router-dom";
const UserIdentityDetails: FC = () => {
  return (
    <div className="user">
      <div className="user__edit">
        <div className="user__edit--heading">
          <h1>User Details</h1>
          <Link to="/user-identity-validation">
            <button>
              <svg
                width="22"
                height="20"
                viewBox="0 0 22 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7 2L2 7L7 12"
                  stroke="white"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M2 7H10C15.523 7 20 11.477 20 17V18"
                  stroke="white"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
              Back
            </button>
          </Link>
        </div>
      </div>
      <div className="user__edit-child">
        <div className="user__edit-child--heading">
          <h1>User Details</h1>
          <h1>Status</h1>
        </div>
      </div>
      <div className="user__edit-confirm">
        <div className="user__edit-confirm--heading">
          <h1>EKe Precious</h1>
          <h1>Confirmed</h1>
        </div>
      </div>
      <div className="user__edit-attach">
        <div className="user__edit-attach--heading">
          <h1>Attachment</h1>
        </div>
        <div className="upload">
          <div className="upload-wrap">
            \
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <svg
                width="250"
                height="250"
                viewBox="0 0 250 250"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M62.5 0C53.9062 0 46.875 7.03125 46.875 15.625V234.375C46.875 242.969 53.9062 250 62.5 250H218.75C227.344 250 234.375 242.969 234.375 234.375V62.5L171.875 0H62.5Z"
                  fill="#E2E5E7"
                />
                <path
                  d="M187.5 62.5H234.375L171.875 0V46.875C171.875 55.4688 178.906 62.5 187.5 62.5Z"
                  fill="#B0B7BD"
                />
                <path
                  d="M234.375 109.375L187.5 62.5H234.375V109.375Z"
                  fill="#CAD1D8"
                />
                <path
                  d="M203.125 203.125C203.125 207.422 199.609 210.938 195.312 210.938H23.4375C19.1406 210.938 15.625 207.422 15.625 203.125V125C15.625 120.703 19.1406 117.188 23.4375 117.188H195.312C199.609 117.188 203.125 120.703 203.125 125V203.125Z"
                  fill="#F15642"
                />
                <path
                  d="M49.6797 148.023C49.6797 145.961 51.3047 143.711 53.9219 143.711H68.3516C76.4766 143.711 83.7891 149.148 83.7891 159.57C83.7891 169.445 76.4766 174.945 68.3516 174.945H57.9219V183.195C57.9219 185.945 56.1719 187.5 53.9219 187.5C51.8594 187.5 49.6797 185.945 49.6797 183.195V148.023ZM57.9219 151.578V167.141H68.3516C72.5391 167.141 75.8516 163.445 75.8516 159.57C75.8516 155.203 72.5391 151.578 68.3516 151.578H57.9219Z"
                  fill="white"
                />
                <path
                  d="M96.0234 187.5C93.9609 187.5 91.7109 186.375 91.7109 183.633V148.148C91.7109 145.906 93.9609 144.273 96.0234 144.273H110.328C138.875 144.273 138.25 187.5 110.891 187.5H96.0234ZM99.9609 151.898V179.883H110.328C127.195 179.883 127.945 151.898 110.328 151.898H99.9609Z"
                  fill="white"
                />
                <path
                  d="M148.375 152.398V162.328H164.305C166.555 162.328 168.805 164.578 168.805 166.758C168.805 168.82 166.555 170.508 164.305 170.508H148.375V183.625C148.375 185.812 146.82 187.492 144.633 187.492C141.883 187.492 140.203 185.812 140.203 183.625V148.141C140.203 145.898 141.891 144.266 144.633 144.266H166.563C169.313 144.266 170.938 145.898 170.938 148.141C170.938 150.141 169.313 152.391 166.563 152.391H148.375V152.398Z"
                  fill="white"
                />
                <path
                  d="M195.312 210.938H46.875V218.75H195.312C199.609 218.75 203.125 215.234 203.125 210.938V203.125C203.125 207.422 199.609 210.938 195.312 210.938Z"
                  fill="#CAD1D8"
                />
              </svg>
              <a
                href=""
                style={{
                  marginTop: "10px",
                  fontSize: "16px",
                  fontWeight: "500",
                }}
              ></a>
            </div>
          </div>
        </div>
        <div className="user__edit---btn">
          <div className="btn-wrap">
            <button className="reject">Reject</button>
            <button className="approve">Approve</button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default UserIdentityDetails;
